import {Helmet} from "react-helmet";
import React, {useEffect} from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import {Box} from "@mui/material";
import BasicFooter from "../../components/AppFooter/BasicFooter";
import {Outlet} from "react-router";
import "./Setup.scss"
import userStore from "../../stores/userStore";
import {Navigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import ScrollToTop from "../../utils/ScrollToTop";
import {useFetchUser} from "../../hooks/userHooks";

const Setup = observer(() => {
    const fetchUser = useFetchUser();

    useEffect(() => {
        fetchUser(true)
    }, [fetchUser])

    if (userStore.user?.has_onboarded) {
        return <Navigate to={"/dashboard"}/>
    }

    return (
        <>
            <ScrollToTop/>
            <Helmet >
                <meta name={"theme-color"} content={"#001A69"}/>
                <title>Account Setup</title>
            </Helmet>
            <AppHeader variant={"dark"}/>
            <Box component={"main"} className={"SetupSection"}>
                <Outlet context={[]}/>
            </Box>
            <BasicFooter showHelp={true}/>
        </>
    )
})

export default Setup
