import React from "react"
import {Box, Button} from "@mui/material";
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import PageIntro from "../../components/PageIntro/PageIntro";

const CheckEmail = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location?.state?.email) {
            navigate("/")
        }
    })

    let introHeading = "Check your email"
    let introCopy1 = "log in link"
    let introCopy2 = "continue to the app"
    if (location?.state?.useCase === "verification") {
        introHeading = "Please verify your email"
        introCopy1 = "verification email"
        introCopy2 = "get started"
    } else if (location?.state?.useCase === "password-reset") {
        introCopy1 = "password reset link"
        introCopy2 = "reset your password"
    }

    return (
        <Box className={"LoginContainer"}>
            <PageIntro className={"LoginIntro"}>
                <Box component={"h1"} className={"IntroHeading"}>{introHeading}</Box>
                <Box className={"IntroCopy"}>
                    <Box component={"p"}>
                        We’ve sent a {introCopy1} to <b>{location?.state?.email}</b>
                    </Box>
                    <Box component={"p"}>
                        Please check your email and click on the link to {introCopy2}. If you haven&apos;t received it, remember to check your spam or junk folder.
                    </Box>
                </Box>
            </PageIntro>
            <Box sx={{width: "100%"}}>
                <Button onClick={() => navigate(-1)} variant={"outlined"} sx={{width: "100%"}}>Back</Button>
            </Box>
        </Box>
    )
}

export default CheckEmail
