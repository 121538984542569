import React, {forwardRef} from "react";
import {CSVLink} from "react-csv";
import dataStore from "../stores/dataStore";
import citationsStore from "../stores/citationsStore";
import mentionsStore from "../stores/mentionsStore";

// headers construct how the csv export table is shown

const policyExportHeaders = [
    {label: "Type", key: "type"},
    {label: "Policy Document ID", key: "policy_document_id"},
    {label: "Title", key: "title"},
    {label: "Translated title", key: "translated_title"},
    {label: "Source title", key: "source.title"},
    {label: "Source country", key: "source.country"},
    {label: "Source type", key: "source.type"},
    {label: "Source sub-type", key: "source.sub_type"},
    {label: "Published on", key: "published_on"},
    {label: "Cited research titles", key: "cited_titles"},
    {label: "Cited research DOIs", key: "cited_dois"},
    {label: "Topics", key: "topics"},
    {label: "Document URL", key: "document_url"},
    {label: "Text", key: "text"}
]

const researchExportHeaders = [
    {label: "Title", key: "title"},
    {label: "DOI", key: "doi"},
    {label: "Document URL", key: "document_url"},
    {label: "Journal", key: "journal"},
    {label: "Published on", key: "published_on"},
    {label: "Publisher", key: "publisher"},
    {label: "Authors", key: "authors"},
    {label: "Abstract", key: "abstract"},
    {label: "Policy citation count", key: "policy_citation_count"},
    {label: "Citing policy titles", key: "citing_document_titles"},
    {label: "Citing policy URLs", key: "citing_document_urls"},
    {label: "Last cited", key: "last_cited"},
]

const getPolicyCSVData = (policyDocuments) => {
    const policyToExport = []

    for (let document of policyDocuments) {
        const citations = citationsStore.citationsByPolicyId.get(document.policy_document_id);
        const mentions = mentionsStore.mentionsByPolicyId.get(document.policy_document_id);
        const cited_dois = [];
        const cited_titles = [];
        let type;
        let text = "";
        let filterType = dataStore.getFilterValue("type")
        if (!filterType || filterType === "citation") {
            citations?.forEach(c => {
                const citation = dataStore.getResearchDoc(c.doi)
                cited_dois.push(citation.doi);
                cited_titles.push(citation.title);
                type = "Policy citation";
            })
        }
        if (!filterType || filterType === "mention") {
            mentions?.forEach(m => {
                if (citations && filterType !== "mention") {
                    policyToExport.push({...document, type: "Name mention", text: m.text})
                } else {
                    type = "Name mention"
                    text = m.text
                }
            })
        }
        policyToExport.push({...document, cited_dois, cited_titles, type, text})
    }
    return policyToExport
}

const CSVDownloadLink = forwardRef(({headers, onClick, data}, ref) => {
    const date = new Date().toISOString().slice(0, 10);
    const exportData = data.map(d => Object.fromEntries(Object.entries(d).map(([key, value]) => {
        if (Array.isArray(value)) {
            return [key, value.join(",").replaceAll(',', '  ，')]
        }
        if (typeof value === 'string') {
            return [key, value.replaceAll(',', '，')]
        }
        return [key, value]
    })))
    return <CSVLink enclosingCharacter={`"`} ref={ref} onClick={onClick} filename={"policy-impact-export-" + date}
                    target={"_blank"}
                    headers={headers && headers} data={exportData}>
        Download as CSV (.csv)
    </CSVLink>
})

CSVDownloadLink.displayName = "CSVDownloadLink"


export {CSVDownloadLink, policyExportHeaders, getPolicyCSVData, researchExportHeaders}
