import React from "react";
import {Box} from "@mui/material";
import "./StackBox.scss";

const StackBox = ({sx, children}) => (
    <Box className={"StackBox"} sx={sx}>
        {children}
    </Box>
)

export default StackBox
