import React from "react";

import {Helmet} from "react-helmet";
import {Outlet} from "react-router";
import AppHeader from "../../components/AppHeader/AppHeader";
import {Box} from "@mui/material";
import BasicFooter from "../../components/AppFooter/BasicFooter";
import "./Login.scss"
import ScrollToTop from "../../utils/ScrollToTop";
import {useNavigate} from "react-router-dom";
const Login = () => {
    const navigate = useNavigate();

    return (
        <>
            <Helmet defaultTitle={"Login - Sage Policy Profiles"} titleTemplate={"%s - Sage Policy Profiles"}>
                <meta name={"theme-color"} content={"#001A69"} />
                <meta charSet={"utf-8"}/>
            </Helmet>
            <ScrollToTop/>
            <AppHeader onCTAClick={() => navigate("/login")} variant={"dark"}/>
            <Box component={"main"} className={"LoginSection"}>
                <Outlet />
            </Box>
            <footer role="contentinfo">
                <BasicFooter showHelp={true}/>
            </footer>
        </>
    )
}

export default Login
