import React, {useState} from "react";
import {Box, FormGroup, FormControlLabel, Checkbox, Button, Divider} from "@mui/material";
import PageIntro from "../../components/PageIntro/PageIntro";
import {useNavigate, useLocation, useOutletContext} from "react-router";
import ButtonContent from "../../components/ButtonContent";
import userStore from "../../stores/userStore";
import {useUpdateUser} from "../../hooks/userHooks";

const ConfirmDois = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [view, setView] = useOutletContext();
    const [authors, setAuthors] = useState(location.state.nameMatches.map(n => {
        return {...n, checked: false}
    }))
    const updateUser = useUpdateUser();

    const handleCheckBox = (i) => {
        authors[i].checked = !authors[i].checked
        setAuthors([...authors])
    }

    const handleConfirmAuthors = async () => {
        setLoading(true)
        const nameVariants = new Set(authors.filter(a => a.checked === true).map(a => a.name)) || []
        const confirmedAffiliations = new Set(authors.filter(a => a.checked === true).map(a => a.institution)) || []
        const affiliations = [...confirmedAffiliations, location.state.affiliation || ""]
        const currentDois = userStore.user.manual_dois || []
        const dois = [...currentDois, ...location.state.validDois]
        await updateUser({dois, name_variants: [...nameVariants], affiliations})

        return (view || setView) ? setView() : navigate("/dashboard-setup")
    }

    const handleToggleAll = () => {
        const checked = authors.some(a => a.checked === false)
        authors.forEach(a => a.checked = checked)
        setAuthors([...authors])
    }

    if (!authors) {
        return
    }

    return (
        <Box className={"SetupContent"}>
            <Box className={"SetUpFrame"}>
                <PageIntro>
                    <h1 className={"IntroHeading"}>Can you confirm this is you?</h1>
                    <br/>
                    <p className={"IntroCopy"}>
                        We’ve identified some authors who may be a match based on the DOIs you provided. Please
                        review them and confirm which ones are you.
                    </p>
                    <br/>
                    <p className={"IntroCopy"}>
                        We’ll use this information to check any other mentions or citations
                    </p>
                </PageIntro>
            </Box>
            <Box className={"SetUpFrame"}>
                <FormGroup>
                    {authors.map((a, i) => {
                        return <FormControlLabel
                            control={
                                <Checkbox checked={a.checked}
                                          className={"ConfirmAuthorCheckBox"}/>
                            }
                            name={a.name}
                            label={`${a.name}, ${a.institution}`}
                            color={"common.onBackground"}
                            key={i}
                            onChange={(event) => handleCheckBox(i, event)}
                        />
                    })}
                    <br/>
                    <Divider/>
                    <br/>
                    <FormControlLabel
                        control={<Checkbox className={"ConfirmAuthorCheckBox"}/>}
                        label={"Confirm all"}
                        checked={authors.every(a => a.checked === true)}
                        color={"common.onBackground"}
                        value={""}
                        onChange={handleToggleAll}
                    />
                </FormGroup>
                <Box className={"SetUpButtonContainer"}>
                    <Button onClick={handleConfirmAuthors} variant={"contained"}>
                        <ButtonContent isLoading={loading} title={"Continue"}/>
                    </Button>
                    <Button variant={"outlined"}
                            onClick={() => navigate(-1)}>Back</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ConfirmDois
