import {Box, Button, IconButton, TextField} from "@mui/material";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import React from "react";

const VariantsInput = ({variants, setVariants, errors, setErrors, variantLabel, variantPlaceholder}) => {

    const handleVariants = (event, i) => {
        setErrors("")
        let newVariants = [...variants]
        newVariants[i] = event.target.value
        setVariants(newVariants)
    }

    const deleteVariant = (i) => {
        const newVariants = [...variants]
        newVariants.splice(i, 1)
        setVariants(newVariants)
    }

    const addAnotherVariant = () => {
        if (variants[0].length === 0) {
            setErrors("Please enter a name variant first")
            return
        }
        setVariants([...variants, ''])
    }

    return <>
        {variants.map((variant, i) =>
            <Box className={"extraNameVariantsContainer"} key={i}>
                <TextField
                    style={{width:"100%"}}
                    autoFocus={true}
                    autoComplete={"off"}
                    className={"StackStretch extraNameVariantInput"}
                    label={variantLabel}
                    value={variant}
                    helperText={errors}
                    error={!!errors}
                    placeholder={variantPlaceholder}
                    onChange={(event) => handleVariants(event, i)}
                />
                {i > 0 && <IconButton className={"deleteVariantButton"}
                                      onClick={() => deleteVariant(i)}><DeleteOutline/></IconButton>}
            </Box>
        )}
        <Button sx={{display: "flex", alignSelf: "flex-start"}} onClick={addAnotherVariant}>+ Add another
            variant</Button>
    </>
}

export default VariantsInput
