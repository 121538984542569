import Fuse from 'fuse.js'

const MatchAuthorNames = (dois, authorName) => {
    if (!authorName){
        return []
    }

    const authors = []
    for (let doi of Object.values(dois)) {
        for (let authorship of Object.values(doi.authorships)) {
            if (authorship.author && authorship.institutions){
                for (let institution of Object.values(authorship.institutions)){
                    authors.push({
                        name: authorship.author.display_name,
                        institution: institution.display_name
                    })
                }
            }
        }
    }

    // Check for matches with user's name
    const fuse = new Fuse(authors, {
        threshold: 0.4,
        keys: [
            "name"
        ]
    })
    const matches = fuse.search(authorName).map(match => match.item)

    return matches.filter((obj, index) => {
        return matches.findIndex(item => item.institution === obj.institution) === index
    })
}

export default MatchAuthorNames
