import dataStore from "../stores/dataStore";
import SummaryCard from "./SummaryCard/SummaryCard";
import DataDisplay from "../templates/DataDisplay";
import {getCitationsCount, getYearDataFromResearchAndCitations} from "../utils/utils";
import React, {useRef} from "react";
import {Box, Button, Stack} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import InfoToolTip from "./InfoToolTip/InfoToolTip";
import {Launch} from "@mui/icons-material";
import {overtonHelpArticle, overtonLink} from "../utils/staticLinks";
import userStore from "../stores/userStore";
import {observer} from "mobx-react-lite";
import citationsStore from "../stores/citationsStore";
import mentionsStore from "../stores/mentionsStore";

const Summaries = observer(({user}) => {
    const navigate = useNavigate();
    const secondOrderCitationCount = getCitationsCount(citationsStore.secondOrderCitations)
    const citationCount = getCitationsCount(citationsStore.citations)
    const tipRef = useRef();
    const policyDocCount = citationsStore.citationsByPolicyId.size.toLocaleString()
    const mentionPolicyDocCount = mentionsStore.mentionsByPolicyId.size.toLocaleString()
    const isPublic = userStore.isPublic
    const initialised = mentionsStore.initialised && citationsStore.initialised

    const policyToolTip = <InfoToolTip label={"What’s the definition of a policy document?"} ref={tipRef}>
        <Box className={"InfoPopOver"}>
            <Box className={"InfoPopOverCopy"}>
                <Box className={"InfoPopoverTitle"}>
                    What’s the definition of a policy document?
                </Box>
                <Box component={"p"} className={"text-bodyMd"}>
                    There isn’t a broadly accepted, pragmatic definition of what a “policy document” is. The
                    data used in Sage Policy Profiles comes from
                    <Link to={overtonLink} target={"_blank"}> Overton <Launch fontSize={"inherit"}/></Link>, who
                    define policy documents very broadly as documents written primarily for or by
                    policymakers that are published by a policy focused source.
                </Box>
                <br/>
                <Box component={"p"} className={"text-bodyMd"}>
                    We collect policy documents from governments and official bodies but also from IGOs,
                    some NGOs and think tanks.
                </Box>
                <Box className={"InfoPopOverActions"}>
                    <Button component={"a"}
                            href={overtonHelpArticle + "which-publications-does-overton-collect"}
                            target={"blank"}
                            endIcon={<Launch fontSize={"inherit"}/>}
                    >Find out more
                    </Button>
                </Box>
            </Box>
        </Box>
    </InfoToolTip>

    const mentionsToolTip = <InfoToolTip ref={tipRef}>
        <Box className={"InfoPopOver"}>
            <Box className={"InfoPopOverCopy"}>
                <Box component={"p"} className={"text-bodyMd"}>
                    We search policy documents in our database for instances where both your name and an affiliation
                    appear close to each other. If you&apos;ve published under other names or at different institutions,
                    you can add name variants and affiliations in your account settings.
                </Box>
                <Box className={"InfoPopOverActions"}>
                    <Button onClick={() => tipRef.current.closePopOver()}>Ok, got it</Button>
                </Box>
            </Box>
        </Box>
    </InfoToolTip>

    const mentionsSummary = () => {
        const mentionsCount = mentionsStore.mentionsByPolicyId.size
        if (!isPublic && !user.affiliations && !user.orcid) {
            return <Stack gap={1}>
                <p className={"text-titleMd"}>Name mentions{mentionsToolTip}</p>
                <p className={"text-bodyMd"}>In order to look for mentions of you in policy,
                    we&apos;ll need some of your affiliations. You can add affiliations in your <Link
                        to={"/account-settings"}>account settings</Link>

                </p>
            </Stack>
        } else if (mentionsCount > 0) {
            return <SummaryCard title={mentionsCount}
                                content={`name mention${mentionsCount > 1 ? 's' : ''} in ${mentionPolicyDocCount} policy documents`}
                                tip={"We search policy documents in our database for instances where both your name and an affiliation appear close to each other. If you've published under other names or at different institutions, you can add name variants and affiliations in your account settings."}/>
        } else if (!isPublic) {
            return <Stack gap={1}>
                <p className={"text-titleMd"}>We&apos;ve not found any mentions of you in policy. </p>
                <p className={"text-bodyMd"}>
                    If you&apos;ve published under other names or at different institutions, you can add name variants
                    and affiliations in your <Link to={"/account-settings"}>account settings</Link>.
                </p>
            </Stack>
        }
    }

    if (user && dataStore.policyDocuments.size > 5 && initialised) {
        return <section className={"DataOverview"}>
            <h1 className={"DashboardSummaries"}>
                <SummaryCard title={citationCount.toLocaleString()}
                             content={`citation${citationCount > 1 ? 's' : ''} across ${policyDocCount} policy documents`}/>
                {citationsStore.secondOrderCitations?.size > 1 &&
                    <SummaryCard title={citationsStore.secondOrderCitations.size}
                                 content={`policy documents which cite ${isPublic ? "this researcher's" : "your"} work have been cited a further  ${secondOrderCitationCount.toLocaleString()} times in ${citationsStore.secondOrderCitationsByPolicyId.size} other policy documents`}
                                 tip={dataStore.metaData?.total_second_order_document_results > 500 &&
                                     `We've found a total of ${dataStore.metaData?.total_second_order_document_results.toLocaleString()} policy documents
                                     citing policy which cites ${isPublic ? "this" : "your"} work! We're showing you the most recent 500.`
                                 }
                    />
                }
                {mentionsSummary()}
                {policyToolTip}
                {dataStore.getCitingDocuments().size > 100 && !isPublic &&
                    <Button onClick={() => navigate('/policy-citations')} sx={{alignSelf: "flex-start"}}
                            variant={"contained"}>View all policy citations</Button>}
            </h1>
            <DataDisplay chartData={getYearDataFromResearchAndCitations(dataStore.research, citationsStore.citations)}
                         combined={dataStore.getCitingDocuments().size <= 100 || isPublic}
            />
        </section>
    }
})

export default Summaries
