import PptxGenJS from "pptxgenjs";
import {formattedDate} from "./utils";
import userStore from "../stores/userStore";
import dataStore from "../stores/dataStore";
import defaultThumbnail from "../assets/default_thumbnail.png"
import {isArray} from "lodash";
import citationsStore from "../stores/citationsStore";
import mentionsStore from "../stores/mentionsStore";

const PPTXGenerator = (data) => {
    let pptx = new PptxGenJS();
    pptx.layout = "LAYOUT_WIDE";
    pptx.theme = {headFontFace: "Helvetica Neue"};
    pptx.theme = {bodyFontFace: "Helvetica Neue"};
    pptx.author = userStore.publicUser.name;
    pptx.title = 'Sage Policy Profiles ' + userStore.publicUser.name;

    const concatenateAuthors = (authors, length = 5) => {
        if (isArray(authors)) {
            return authors.length > length ? authors[0] + " et al." : authors.join(", ")

        } else {
            return authors.length > 70 ? authors.slice(0, 70).concat("...") : authors
        }
    }

    pptx.defineSlideMaster({
        title: "MASTER_SLIDE",
        background: {color: "FFFFFF"},
        margin: [0.5, 0.5, 0.5, 0.5],
        objects: [
            {
                image: {
                    x: 11.3,
                    y: "5%",
                    w: 1.7,
                    h: 0.2,
                    path: window.location.origin + "/Sage_logo_policyprofiles_horizontal_RGB_navy_onwhite.png"
                }
            },
        ],
        slideNumber: {x: 0.3, y: "92.5%", fontSize: 12},
    });

    data.forEach(d => {

            // shared properties
            const slide = pptx.addSlide({masterName: "MASTER_SLIDE"});
            let slide2
            const authors = concatenateAuthors(d.authors, 3)
            const hyperlink = d.document_url

            // for a policy document
            if (d.policy_document_id) {
                const citations = citationsStore.citationsByPolicyId.get(d.policy_document_id)
                const mentions = mentionsStore.mentionsByPolicyId.get(d.policy_document_id)
                const snippet = d.snippet.length > 460 ? d.snippet.slice(0, 460) + "..." : d.snippet
                const thumbnail = d.thumbnail.includes("default_thumbnail") ? defaultThumbnail : d.thumbnail

                if (citations?.length > 3) {
                    slide2 = pptx.addSlide({masterName: "MASTER_SLIDE"});
                    slide2.addText("Continued.", {
                        x: 0.5,
                        y: 0.5,
                        w: "100%",
                        fontSize: 14,
                        valign: "top",
                        color: "dddddd",
                    })
                }
                if (data.length < 500) {
                    slide.addImage({x: 0.5, y: "10%", w: 1.5, h: 2, path: thumbnail})
                }

                slide
                    // policy doc properties
                    .addText(d.title, {
                        x: data.length < 500 ? 2.5 : 0.5,
                        y: "10%",
                        w: "80%",
                        fontSize: 22,
                        valign: "top",
                        color: "001A69",
                        hyperlink: {url: hyperlink}
                    })
                    .addText(authors + ', ' + formattedDate(d.published_on), {
                        x: data.length < 500 ? 2.5 : 0.5,
                        y: "28%",
                        w: "80%",
                        fontSize: 16,
                        valign: "top",
                        color: "616161"
                    })
                    .addText(snippet, {
                        x: data.length < 500 ? 2.5 : 0.5,
                        y: "33%",
                        w: "80%",
                        fontSize: 14,
                        valign: "top",
                        color: "616161"
                    })

                    // layout properties
                    .addShape(pptx.shapes.LINE, {
                        x: 0.5, y: "50%", w: "92%", h: 0.0,
                        line: {color: "001A69", width: 1},
                    })
                // add policy citations if the doc type is citation
                if (d.type === "Policy citation") {
                    slide.addText("Cited research", {
                        x: 0.5,
                        y: 4,
                        w: "65%",
                        fontSize: 14,
                        valign: "top",
                        color: "001A69",
                    })
                    citations?.map((c, index) => {
                        const article = dataStore.research.get(c.doi)
                        const title = article.title
                        const researchAuthors = (concatenateAuthors(article.authors, 3))
                        let editingSlide = slide
                        if (index > 2) {
                            index = index - 6
                            editingSlide = slide2
                        }
                        editingSlide
                            .addText(title, {
                                x: 0.5,
                                y: 4.4 + index,
                                w: "65%",
                                fontSize: 16,
                                valign: "top",
                                color: "0d0d0d",
                            })
                            .addText(`${researchAuthors && researchAuthors + " "} (${article.published_on} )`, {
                                x: "70%",
                                y: 4.4 + index,
                                w: "30%",
                                fontSize: 14,
                                valign: "top",
                                color: "616161"
                            })
                            .addShape(pptx.shapes.LINE, {
                                x: 0.5, y: 5.2 + index, w: "92%", h: 0.0,
                                line: {color: "dddddd", width: 1},
                            })
                    })
                }

                // add mentions if the doc type is mentions
                if (d.type === "Name mention") {
                    slide.addText("Name mentions", {
                        x: 0.5,
                        y: 4,
                        w: "65%",
                        fontSize: 14,
                        valign: "top",
                        color: "001A69",
                    })
                    mentions?.map((m, index) => {
                        slide
                            .addText(m.text, {
                                x: 0.5,
                                y: 4.4 + index,
                                w: "65%",
                                fontSize: 16,
                                italic: true,
                                valign: "top",
                                color: "0d0d0d",
                            })
                            .addText(`on page ${m.page}`, {
                                x: "70%",
                                y: 4.4 + index,
                                w: "65%",
                                fontSize: 14,
                                valign: "top",
                                color: "616161",
                            })
                    })
                }
            }

            // for a research document
            if (d.doi) {
                const citations = citationsStore.citations.get(d.doi)
                const citationCount = citations?.length ?? "0"
                const citationText = !citations || citations.length > 1 ? "policy citations" : "policy citation"

                slide
                    // research doc properties
                    .addText(d.title, {x: 0.5, y: "10%", w: "55%", fontSize: 24, valign: "top", color: "001A69"})
                    .addText(authors, {x: "65%", y: "10%", w: "35%", fontSize: 16, valign: "top"})
                    .addText(formattedDate(d.published_on), {
                        x: "65%",
                        y: "28%",
                        w: "35%",
                        fontSize: 12,
                        bold: false,
                        valign: "top"
                    })

                    // layout/shape properties

                    .addShape(pptx.shapes.LINE, {
                        x: 0.5, y: "45%", w: "90%", h: 0.0,
                        line: {color: "001A69", width: 1},
                    })
                    .addShape(pptx.shapes.LINE, {
                        x: 0.5, y: "67%", w: "20%", h: 0.0,
                        line: {color: "08C9C4", width: 4},
                    })
                    .addShape(pptx.shapes.LINE, {
                        x: "30%", y: 3.8, w: 0, h: "40%",
                        line: {color: "001A69", width: 1},
                    })

                    // citation properties

                    // citation count
                    .addText(citationCount, {x: 0.5, y: 3.8, w: "30%", fontSize: 48, valign: "top", color: "001A69"})
                    .addText(citationText, {x: 0.5, y: "61%", w: "30%", fontSize: 16, valign: "top"})

                // conditional - not all dois have document URL

                d.document_url && slide
                    .addText(d.journal, {
                        color: "046FF8",
                        x: "65%",
                        y: "20%",
                        w: "35%",
                        fontSize: 16,
                        hyperlink: {url: hyperlink},
                        valign: "top"
                    })
                    .addText(d.doi, {
                        x: "65%",
                        y: "33%",
                        w: "35%",
                        fontSize: 14,
                        hyperlink: {url: hyperlink},
                        color: "046FF8",
                        valign: "top"
                    })

                // only shows citation list if there are citations

                if (citations) {
                    citations.slice(0, 3).map((c, index) => {
                            const p = dataStore.policyDocuments.get(c.policy_document_id)
                            const policyTitle = p.title.length > 65 ? p.title.slice(0, 65) + "..." : p.title
                            const policyAuthors = p.authors.join(", ")
                            const published = p.published_on
                            const pageNumber = citations.find(c => c.policy_document_id === p.policy_document_id).page
                            const pdfURL = p.dont_show_pdf === true ? p.document_url : p.pdf_url + "#page=" + pageNumber

                            slide.addText(policyTitle, {
                                x: "35%",
                                y: 3.8 + index,
                                w: "65%",
                                fontSize: 16,
                                valign: "top",
                                color: "001A69"
                            })
                            slide.addText(`${policyAuthors && concatenateAuthors(policyAuthors, 3) + " "}(${published})`, {
                                x: "35%",
                                y: 4.1 + index,
                                w: "65%",
                                fontSize: 12,
                                valign: "top",
                                color: "616161"
                            })
                            slide.addText("on page " + pageNumber, {
                                x: "35%",
                                y: 4.4 + index,
                                w: "65%",
                                fontSize: 14,
                                valign: "top",
                                color: "046FF8",
                                hyperlink: {url: pdfURL}
                            })
                            if (citations.length > 3) {
                                slide.addText("Showing the 3 most recent citations", {
                                    x: "35%",
                                    y: "92%",
                                    w: "65%",
                                    fontSize: 14,
                                    bold: false,
                                    valign: "top",
                                    color: "717171"
                                })
                            }
                        }
                    )

                }
            }
        }
    )

    pptx.writeFile({ fileName: userStore.publicUser.name + '_Sage Policy Profile_' + new Date().toISOString().slice(0, 10) });

}

export {PPTXGenerator}
