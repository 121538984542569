import {Outlet, useNavigate} from "react-router";
import ScrollToTop from "../utils/ScrollToTop";
import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import UserAppFooter from "../components/AppFooter/UserAppFooter";
import AppHeader from "../components/AppHeader/AppHeader";
import userStore from "../stores/userStore";
import {observer} from "mobx-react-lite";
import {clearData, useFetchPublicCitationData, useFetchPublicMentionData} from "../hooks/dataHooks";
import {useFetchPublicUser, useFetchUser} from "../hooks/userHooks";

const PublicApp = observer(() => {
    const {userId} = useParams()
    const navigate = useNavigate()
    const fetchPublicCitationData = useFetchPublicCitationData()
    const fetchPublicMentionData = useFetchPublicMentionData()
    const fetchUser = useFetchUser();
    const fetchPublicUser = useFetchPublicUser();

    useEffect(() => {
        userStore.setIsPublic(true)

        fetchPublicUser(userId).then(() => {
            if (userStore.publicUser) {
                fetchPublicCitationData(userId)
                fetchPublicMentionData(userId)
            }
        })
        fetchUser()

        return () => {
            userStore.setPublicUser(null)
            userStore.setIsPublic(false)
            clearData();
        }
    })

    return (
        <>
            <ScrollToTop/>
            <AppHeader onCTAClick={() => navigate("/login")}/>
            <br/>
            <Outlet/>
            <UserAppFooter/>
        </>
    )
})

export default PublicApp
