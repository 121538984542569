import React, {useState} from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {TextField, InputAdornment, IconButton} from "@mui/material";
import {handleEnter} from "../../utils/utils";

const PasswordField = ({
                           textFieldLabel,
                           onChange,
                           onEnterRef,
                           passwordError,
                           passwordErrorMessage,
                           isRequired,
                           value,
                           autoComplete
                       }) => {
    const [showPassword, setShowPassword] = useState(false)

    return <TextField
        autoComplete={autoComplete}
        required={isRequired}
        className={"StackStretch"}
        value={value}
        helperText={passwordError ? passwordErrorMessage : null}
        error={passwordError}
        type={showPassword ? 'text' : 'password'}
        onKeyUp={event => handleEnter(event, onEnterRef)}
        onChange={e => onChange(e)}
        label={textFieldLabel}
        InputProps={{
            endAdornment:
                <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        size="small"
                        color="grey"
                    >
                        {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                    </IconButton>
                </InputAdornment>
        }}
    />
}

export default PasswordField
