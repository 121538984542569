import {Box} from "@mui/material";
import OvertonLogoOnWhite from "../../assets/overton_logo_on_white.svg";
import vector from "../../assets/vector_navy.svg";
import SageLogoOnWhite from "../../assets/Sage_logo_navy.svg";
import React from "react";
import "./AppFooter.scss"
import {getCurrentYear} from "../../utils/utils";
import {privacyPolicyLink, sagePubLink} from "../../utils/staticLinks";
import ExternalLink from "../ExternalLink";

const UserAppFooter = () => {
    return <Box className={"UserAppFooter"}>
        <Box display={"flex"} gap={2} alignItems={"center"}>
            <ExternalLink to={"https://www.overton.io/"}>
                <Box sx={{height: "1.5rem"}} component={"img"}
                     src={OvertonLogoOnWhite}
                     alt={"www.overton.io"}></Box></ExternalLink>
            <Box component={"img"} src={vector} alt={""}></Box>
            <ExternalLink to={sagePubLink}>
                <Box component={"img"} src={SageLogoOnWhite} alt={"sagepub.com"}></Box>
            </ExternalLink>
        </Box>
        <Box className={"FooterLinks"}>
            <ExternalLink to={privacyPolicyLink} style={{color: "inherit", textDecoration: "none"}}>
                <Box className={"text-bodySm"}>Privacy policy</Box>
            </ExternalLink>
            <Box className={"text-bodySm"}>© {getCurrentYear()} Sage Publications</Box>
        </Box>
    </Box>
}

export default UserAppFooter
