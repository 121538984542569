import React from "react";
import { Box } from "@mui/material";
import OvertonLogo from "../../assets/overton_logo_onnavy.svg";
import SageLogo from "../../assets/Sage_logo_white.svg";
import vector from "../../assets/vector_1.svg";
import "./AppFooter.scss"
import ExternalLink from "../ExternalLink";
import {overtonLink, sagePubLink} from "../../utils/staticLinks";

const UpperFooter = () => (
    <Box className={"UpperFooter"} sx={{bgcolor: "sageNavy.main"}}>
        <ExternalLink to={overtonLink}><Box sx={{height: "1.5rem"}} component={"img"} src={OvertonLogo} alt={"www.overton.io"}></Box></ExternalLink>
        <Box component={"img"} src={vector} alt={""}></Box>
        <ExternalLink to={sagePubLink}><Box component={"img"} src={SageLogo} alt={"sagepub.com"}></Box></ExternalLink>
    </Box>
)

export default UpperFooter
