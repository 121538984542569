import React from "react";
import {Box, TablePagination} from "@mui/material";
import "./CustomPagination.scss"

const CustomPagination = ({count, page, rowsPerPage, onPageChange, showFirstLast = true}) => {
    return (
        <Box className={"CustomPagination"}>
            <TablePagination showLastButton={showFirstLast && count > 100}
                             showFirstButton={showFirstLast && count > 100} component={"div"} rowsPerPage={rowsPerPage}
                             onPageChange={onPageChange} count={count} page={page}/>
        </Box>
    )
}

export default CustomPagination
