import React, {useState} from 'react';
import {Box, Button} from '@mui/material';
import PageIntro from '../../components/PageIntro/PageIntro';
import LaunchIcon from '@mui/icons-material/Launch';
import {useNavigate, useLocation} from "react-router-dom"
import StackBox from '../../components/StackBox/StackBox';
import ButtonContent from "../../components/ButtonContent";
import ExternalLink from "../../components/ExternalLink";
import {useUpdateUser} from "../../hooks/userHooks";

const ConfirmOrcid = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const author = location.state.author
    const updateUser = useUpdateUser();

    const handleConfirm = async () => {
        setLoading(true)
        await updateUser({name: author.author, name_variants: author.name_variants || [], orcid: author.orcid})
        navigate("/dashboard-setup")
    }

    return (
        <Box className={"SetupContent"}>
            <Box className={"SetUpFrame"}>
                <PageIntro>
                    <h1 className={"IntroHeading"}>Is this you?</h1>
                    <Box className={"OrcidContainer"}>
                        <Box>
                            <div className={"text-labelLg"}>ORCID iD</div>
                            <div className={"text-bodyLg"}>{author.orcid}</div>
                        </Box>
                        <Box className={"text-titleLg"}>{author.author}</Box>
                        {Object.keys(author.affiliations).join(", ")}
                        {author.name_variants.length > 0 &&
                            <div className={"text-bodyLg"}>Also known as: {author.name_variants.join(", ")}</div>}
                    </Box>
                    <ExternalLink to={author.url} className={"OrcidUriContainer"}>
                        <img style={{marginRight: 4}} alt="ORCID logo"
                             src="https://info.orcid.org/wp-content/uploads/2019/11/orcid_16x16.png"
                             width="16" height="16"/> {author.url} <LaunchIcon sx={{fontSize: "1rem"}}/>
                    </ExternalLink>
                </PageIntro>
            </Box>
            <Box className={"SetUpFrame"}>
                <StackBox>
                    <Button className={"StackStretch"} variant={"contained"} onClick={handleConfirm}>
                        <ButtonContent isLoading={loading} title={"Yes, continue"}/>
                    </Button>
                    <Button className={"StackStretch"} variant={"outlined"}
                            onClick={() => navigate("/account-setup/orcid-lookup", {state: {orcid: author.orcid}})}>No,
                        go back</Button>
                </StackBox>
            </Box>
        </Box>
    )
}

export default ConfirmOrcid
