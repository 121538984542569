import React from "react"
import {useRef, useState} from "react";
import {Box, Button} from "@mui/material";
import StackBox from "../../components/StackBox/StackBox";
import EmailField from "./EmailField";
import { useNavigate } from "react-router";
import AuthService from "../../services/AuthService";
import { dispatchError } from "../../services/Notifications";
import MagicLinkButton from "./MagicLinkButton";
import PageIntro from "../../components/PageIntro/PageIntro";
import ButtonContent from "../../components/ButtonContent";

const ForgotPassword = () => {
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [loading, setLoading] = useState(false)
    const sendLinkButton = useRef()
    const emailInputRef = useRef()
    const navigate = useNavigate()

    const getResetLink = async () => {
        setLoading(true)
        try {
            await AuthService.resetPasswordRequest(email)
            navigate("/login/check-email", {state: {email, useCase: "password-reset"}})
        } catch (err) {
            setLoading(false)
            dispatchError(err.message)
            setEmailError(true)
        }
    }

    return (
        <Box className={"LoginContainer"}>
            <PageIntro>
                <Box component={"h1"} className={"IntroHeading"}>Reset your password</Box>
                <Box component={"p"} className={"IntroCopy"}>
                    Enter the email address associated with your account, and we’ll email you a link to reset your password.
                </Box>
            </PageIntro>
            <StackBox>
                <EmailField
                    setEmail={setEmail}
                    emailError={emailError}
                    onEnterRef={sendLinkButton}
                    emailInputRef={emailInputRef}
                />
                <Button className={"StackStretch"} onClick={getResetLink} variant={"contained"} ref={sendLinkButton}>
                    <ButtonContent isLoading={loading} title={"Send me a reset link"}/>
                </Button>
                <Button className={"StackStretch"} variant={"outlined"} onClick={()=> navigate(-1)}>Back</Button>
                <Box sx={{textAlign: "center"}} className={"text-labelLg"}>or</Box>
                <MagicLinkButton email={email} setEmailError={setEmailError} />
            </StackBox>
        </Box>
    )
}

export default ForgotPassword
