import {action, makeAutoObservable} from "mobx";
import {groupBy} from "../utils/utils";
import _, {isEqual} from "lodash";
import userStore from "./userStore";

const createMentionsStore = (mentions, mentionsByPolicyId, metaData, currentData, updatedData, initialised, updateData) => {
    return makeAutoObservable({
        mentions,
        mentionsByPolicyId,
        currentData,
        initialised,
        updatedData,
        updateData,
        metaData,

        setHasUpdatedData(data) {
            this.updatedData = data;
            this.updateData = true
        },

        async setMentions(mentions) {
            const array = Object.values(mentions).flat()
            const grouped = await groupBy(array, "policy_document_id")
            const mappedMentions = mentions ? new Map(Object.entries(grouped)) : null
            const blacklist = userStore.blacklist;
            blacklist.forEach(b => {
                if (mappedMentions.has(b.policy_document_id)) {
                    mappedMentions.delete(b.policy_document_id)
                }
            })
            action(() => this.mentions = mappedMentions)()
            action(() => this.mentionsByPolicyId = mappedMentions)()
        },

        hasUpdatedMentionData(newData) {
            return !isEqual(_.size(newData.mentions), _.size(this.currentData.mentions))
        },

        initialise(data, initialised = true) {
            this.setMentions(data.mentions)
            this.metaData = data.metadata
            this.currentData = data
            this.initialised = initialised;
            this.updateData = false;
        }
    })
}

const mentionsStore = createMentionsStore(new Map(), new Map(), {}, {}, {}, false, false)
export default mentionsStore
