import React from "react"

import {useState, useRef} from "react";
import {Box, Button, Checkbox, FormControlLabel} from "@mui/material";
import StackBox from "../../components/StackBox/StackBox";
import PasswordField from "./PasswordField";
import AuthService from "../../services/AuthService";
import userStore from "../../stores/userStore";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import PageIntro from "../../components/PageIntro/PageIntro";
import ButtonContent from "../../components/ButtonContent";
import {dispatchError} from "../../services/Notifications";
import {useUpdateUser} from "../../hooks/userHooks";

const CreatePassword = observer(() => {
    const continueButtonRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
    const [marketingOptIn, setMarketingOptIn] = useState(false)
    const navigate = useNavigate()
    const updateUser = useUpdateUser();
    const path = userStore?.user?.has_onboarded ? "/dashboard" : "/account-setup/orcid-lookup"

    const handleChange = (e) => {
        setPasswordErrorMessage("")
        setPassword(e.target.value)
    }

    const handleOptIn = (value) => {
        setMarketingOptIn(value)
    }

    const handleSetPassword = async (createPassword) => {
        if (createPassword) {
            setLoading(true)
            try {
                await AuthService.setPassword(
                    {
                        "email": userStore?.user?.data?.email,
                        "password": password,
                        "password_confirmation": password
                    })
            } catch (err) {
                setLoading(false)
                if (err.errors.password) {
                    setPasswordErrorMessage(err.errors.password)
                } else {
                    dispatchError(err.message)
                }
                setPasswordError(true)
            }
        }
        await updateUser({marketing_opt_in: marketingOptIn, features_opt_in: marketingOptIn})
        navigate(path)
        setLoading(false)
    }

    return (
        <Box className={"LoginContainer"}>
            <PageIntro>
                <Box component={"h1"} className={"IntroHeading"}>Welcome, <br/>{userStore.user?.data?.email}</Box>
                <Box className={"IntroCopy"}>
                    <Box component={"p"}>
                        Would you like to save time next time you log in by creating a password?
                    </Box>
                    <Box component={"p"} className={"text-bodyMd"}>
                        This isn’t mandatory, if you don’t create a password, next time you log in we can email you
                        another magic link.
                    </Box>
                </Box>
            </PageIntro>
            <StackBox>
                <PasswordField
                    isRequired={false}
                    textFieldLabel={"Create a password (minimum 10 characters)"}
                    onChange={handleChange}
                    onEnterRef={continueButtonRef}
                    passwordError={passwordError}
                    passwordErrorMessage={passwordErrorMessage}/>
                <FormControlLabel
                    slotProps={{
                        typography: {
                            margin: "0.5rem 0 0.75rem",
                            fontSize: "0.875rem",
                            fontFamily: "Open Sans, sans-serif"
                        }
                    }}
                    control={
                        <Checkbox checked={marketingOptIn}/>
                    }
                    value={marketingOptIn}
                    label={`I’m happy to receive emails about new features, policy insights, and occasional feedback surveys to help improve the product.`}
                    color={"common.onBackground"}
                    onChange={() => handleOptIn(!marketingOptIn)}
                />
                <Button className={"StackStretch"} variant={'contained'} onClick={() => handleSetPassword(true)}
                        ref={continueButtonRef}>
                    <ButtonContent isLoading={loading} title={"Continue"}/>
                </Button>
                <Button className={"StackStretch"} variant={"outlined"}
                        onClick={handleSetPassword}>Continue without creating a password</Button>
            </StackBox>
        </Box>
    )
})

export default CreatePassword
