import React from "react";

import {Box, Button, Divider, IconButton, ListItemIcon, Menu, MenuItem, Switch} from "@mui/material";
import {
    EmailOutlined,
    Launch,
    LinkOutlined,
    NotificationsOutlined, SettingsOutlined,
    ShareOutlined
} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import {dispatchError, dispatchSuccess} from "../../services/Notifications";
import userStore from "../../stores/userStore";
import {observer} from "mobx-react-lite";
import {useUpdateUser} from "../../hooks/userHooks";

const ShareMenu = observer(() => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const isPublic = userStore.user?.is_public_profile
    const updateUser = useUpdateUser();
    const handleShareMenuClick = (event) => setAnchorEl(event.currentTarget);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUpdateUser = () => {
        updateUser({is_public_profile: !isPublic}, true)
    }
    const getShareLink = () => {
        if (!userStore.user) {
            return
        }
        const base = "/profile/"
        const id = userStore.user.user_id
        const name = userStore.user.name?.replace(" ", "-").toLowerCase()
        return base + id + "/" + name
    }

    const shareLink = window.location.origin + getShareLink()
    const mailtoBody = "I'm using Sage Policy Profiles to track how my research has been cited in policy %0D%0D"
    const mailtoSubject = "Policy Impact"

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(shareLink)
            dispatchSuccess('Copied to the clipboard')
        } catch (e) {
            dispatchError(e.message)
        }
    }

    return (
        <Box sx={{display: "inline-flex", gap: {lg: 2}}}>
            <IconButton onClick={() => navigate("/alerts")}
                        sx={{display: {xs: "inline-flex", lg: "none"}}}>
                <NotificationsOutlined/>
            </IconButton>
            <Button onClick={() => navigate("/alerts")}
                    startIcon={<NotificationsOutlined/>}
                    variant={"text"}
                    sx={{display: {xs: "none", lg: "inline-flex"}}}>Alerts
            </Button>

            <IconButton onClick={handleShareMenuClick}
                        sx={{display: {xs: "inline-flex", lg: "none"}}}>
                <ShareOutlined/>
            </IconButton>

            <Button id={"share-button"}
                    aria-controls={open ? 'share-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    startIcon={<ShareOutlined/>}
                    variant={"contained"}
                    sx={{display: {xs: "none", lg: "inline-flex"}}}>Share my profile
            </Button>
            <Menu id={"share-menu"}
                  open={open}
                  sx={{maxWidth: 320}}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  MenuListProps={{'aria-labelledby': 'share-button'}}
            >
                <MenuItem disableRipple sx={{justifyContent: "space-between"}}>
                    Turn {isPublic ? "off" : "on"} profile sharing
                    <Switch checked={isPublic} onChange={handleUpdateUser}/>
                </MenuItem>
                {isPublic &&
                    <div>
                        <Divider/>
                        <MenuItem onClick={copyToClipboard}>
                            <ListItemIcon><LinkOutlined fontSize={"small"}/></ListItemIcon>
                            Copy public profile share link
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon><EmailOutlined fontSize={"small"}/></ListItemIcon>
                            <Link target={"_blank"} to={"mailto:?subject=" + mailtoSubject + "&body=" + mailtoBody + shareLink}>Send link via email</Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon><Launch fontSize={"small"}/></ListItemIcon>
                            <Link to={getShareLink()} target="_blank">View my public profile</Link>
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={copyToClipboard} sx={{
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            flexFlow: "column",
                            alignItems: "flex-start",
                            lineBreak: "anywhere"
                        }}>
                            <span style={{color: "#717171"}} className={"text-titleSm"}>Public share link</span>
                            {window.location.origin + getShareLink()}
                        </MenuItem>
                    </div>
                }
            </Menu>
            <IconButton onClick={() => navigate("/account-settings")}><SettingsOutlined/></IconButton>
        </Box>
    )
})

export default ShareMenu
