import React, {useState} from "react"
import {Helmet} from "react-helmet";
import {Box, Chip, Container, Divider, Stack} from "@mui/material";
import AppToolbar from "../../components/AppToolbar/AppToolbar";
import Prompt from "../../hooks/usePrompt";
import "../../components/AccountSetting/AccountSetting.scss"
import userStore from "../../stores/userStore";
import {observer} from "mobx-react-lite";
import AccountSetting from "../../components/AccountSetting/AccountSetting";
import {useUpdateUser} from "../../hooks/userHooks";
import SkeletonItem from "../../components/Skeleton";
import {useFetchMentionData} from "../../hooks/dataHooks";

const AccountSettings = observer(() => {
    const [hasUpdates, setHasUpdates] = useState(false);
    const orcid = userStore.user?.orcid
    const name = userStore.user?.name
    const updateUser = useUpdateUser();
    const fetchMentionData = useFetchMentionData()

    const skeletonSettings = <Stack gap={3} flex={"1 1 60%"}>
        <SkeletonItem type={"SETTING"}/>
        <Divider/>
        <SkeletonItem type={"SETTING"}/>
        <Divider/>
        <SkeletonItem type={"SETTING"}/>
        <Divider/>
        <SkeletonItem type={"SETTING"}/>
        <Divider/>
    </Stack>

    const handleUpdateUser = (variants, field) => {
        updateUser({[field]: variants}).then(() => fetchMentionData(true))
    }

    const settings = () => {
        return <Stack flex={"1 1 60%"} marginBottom={8} gap={3}>
            <AccountSetting canEdit={false}
                            title={"Email"}
                            field={"email"}
                            content={userStore.user.email}
            />
            {orcid && <AccountSetting canEdit={false}
                                      field={"ORCID iD"}
                                      title={"ORCID iD"}
                                      content={orcid}/>}
            <AccountSetting isEditable={true}
                            setHasUpdates={setHasUpdates}
                            content={userStore.user.has_password ? "********" : "Create a password"}
                            canEdit={true} field={"password"}
                            title={"Password"}/>
            <AccountSetting canEdit={!orcid}
                            isEditable={true}
                            field={"name variants"}
                            setHasUpdates={setHasUpdates}
                            title={"Also known as"}
                            inputLabel={"Name variant"}
                            inputPlaceholder={"eg 'Liz Smith"}
                            updateUser={(variants) => handleUpdateUser(variants, "name_variants")}
                            content={userStore.user.name_variants}/>
            <AccountSetting canEdit={!orcid}
                            field={"affiliations"}
                            isEditable={true}
                            updateUser={(variants) => handleUpdateUser(variants, "affiliations")}
                            setHasUpdates={setHasUpdates}
                            title={"Affiliations"}
                            inputLabel={"Affiliation"}
                            inputPlaceholder={"eg 'University of Edinburgh"}
                            content={userStore.user.affiliations}/>
        </Stack>
    }

    return (
        <>
            <Helmet>
                <title>Account settings</title>
            </Helmet>
            <Prompt when={hasUpdates}
                    message={"Your changes will be lost, are you sure you don't want to save them first?"}
                    beforeUnload={true}/>
            {name && <Container maxWidth={false}>
                <Chip className={"NameChip"} variant={"outlined"} label={name}/>
            </Container>
            }
            <Stack minHeight={"calc(100vh - 146px)"}>
                <AppToolbar title={"Account settings"} allowExports={false}/>
                <Box className={"AlertsContainer"}>
                    <Stack flex={"1 1 40%"}>
                        <h2 className={"text-bodyLg"}>Info about you and your preferences.</h2>
                    </Stack>
                    {!userStore.user ? skeletonSettings : settings()}
                </Box>
            </Stack>
        </>
    )
})

export default AccountSettings
