import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, FormControlLabel, FormGroup} from "@mui/material"
import PageIntro from '../../components/PageIntro/PageIntro';
import {useNavigate, useLocation} from 'react-router';
import ButtonContent from "../../components/ButtonContent";
import InfoToolTip from "../../components/InfoToolTip/InfoToolTip";
import {openAlexLink} from "../../utils/staticLinks";
import OpenAlexArticle from "../../components/OpenAlexArticle/OpenAlexArticle";
import {dispatchError} from "../../services/Notifications";
import StackBox from "../../components/StackBox/StackBox";
import {useUpdateUser} from "../../hooks/userHooks";

const ConfirmArticles = () => {
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [checkedList, setCheckedList] = useState([])
    const updateUser = useUpdateUser();
    const keyedArticles = location.state?.articles

    useEffect(() => {
        if (keyedArticles === undefined) {
            dispatchError("No articles")
            navigate("/account-setup/confirm-profile")
        }
    }, [keyedArticles, navigate])

    if (keyedArticles === undefined) {
        return
    }

    const submit = async () => {
        setLoading(true)
        await updateUser({
            dois: checkedList,
            name: location.state.name,
            name_variants: location.state.name_variants,
            affiliations: location.state.affiliations
        })
        navigate("/dashboard-setup")
    }

    const handleSelectAll = () => {
        if (checkedList.length < articleCount) {
            setCheckedList(Object.keys(keyedArticles))
        } else {
            setCheckedList([])
        }
    }

    const handleSelect = (key) => {
        const index = checkedList.indexOf(key)
        if (index !== -1) {
            checkedList.splice(index, 1)
        } else {
            checkedList.push(key)
        }
        setCheckedList([...checkedList])
    }

    const articles = Object.values(keyedArticles)
    const articleCount = articles.length

    const formLabel = <Box sx={{display: "flex", justifyContent: "space-between"}}>
        <span>Select all</span>
        <span className={"text-bodyMd"}>{checkedList.length} of {articleCount} selected</span>
    </Box>

    return (
        <Box className={"SetupContent"}>
            <Box className={"SetUpFrame SetupIntroContainer"}>
                <PageIntro>
                    <h1 className={"IntroHeading"}>Confirm the articles you authored</h1>
                    <p className={"IntroCopy"}>
                        We&apos;ve found {articleCount} articles linked to your name which have a DOI. Please check and confirm the ones you wrote.
                    </p>
                    <br/>
                    <p className={"IntroCopy"}>
                        Please note, our policy tracking feature can only identify articles cited in policy if they have a DOI associated with them.
                    </p>
                    <br/>
                    <InfoToolTip label={"How did we find these articles?"}>
                        <Box className={"InfoPopOver"}>
                            <Box className={"InfoPopOverCopy"}>
                                <div className={"InfoPopoverTitle"}>
                                    How did we find these articles?
                                </div>
                                <p className={"text-bodyMd"}>
                                    We use OpenAlex, an open source and comprehensive catalog of scholarly papers, authors,
                                    institutions, and more.
                                </p>
                            </Box>
                            <Box className={"InfoPopOverActions"}>
                                <Button onClick={() => window.open(openAlexLink, "_blank", "noreferrer")}>Find out
                                    more</Button>
                            </Box>
                        </Box>
                    </InfoToolTip>
                </PageIntro>
            </Box>
            <Box className={"SetUpFrame"}>
                <StackBox>
                    <FormGroup style={{width: "100%"}}>
                        <FormControlLabel aria-flowto={"confirm-articles-continue"} className={"oaArticle oaSelectAll"} style={{alignItems: "center"}} value={"all"}
                                          control={<Checkbox checked={checkedList.length === articleCount}
                                                             onChange={handleSelectAll}/>} label={formLabel}/>
                        {Object.entries(keyedArticles)
                            .sort(([, a], [, b]) => b.publication_date.localeCompare(a.publication_date))
                            .map(([key, value]) => (
                                <OpenAlexArticle
                                    doi={key}
                                    key={key}
                                    article={value}
                                    onChange={() => handleSelect(key)}
                                    checked={checkedList.includes(key)}
                                />
                            ))}
                    </FormGroup>
                </StackBox>
            </Box>
            <Box className={"SetUpButtonContainer OverflowFrameButtons"}>
                <Button id={"confirm-articles-continue"} className={"StackStretch"} variant={"contained"} onClick={submit}>
                    <ButtonContent isLoading={loading} title={"Continue"}/>
                </Button>
                <Button className={"StackStretch"} variant={"outlined"}
                        onClick={() => navigate(-1)}>Back</Button>
            </Box>
        </Box>
    )
}

export default ConfirmArticles
