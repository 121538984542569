import React, {useEffect, useRef} from "react";
import {Helmet} from "react-helmet";
import {Box, Button, useMediaQuery} from "@mui/material";
import "./LandingPage.scss";
import AppHeader from "../../components/AppHeader/AppHeader";
import UpperFooter from "../../components/AppFooter/UpperFooter";
import BasicFooter from "../../components/AppFooter/BasicFooter";
import EmailCollectionForm from "../Login/EmailCollectionForm";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import {ArrowForwardOutlined, Launch} from "@mui/icons-material";
import ScrollToTop from "../../utils/ScrollToTop";
import userStore from "../../stores/userStore";
import ExternalLink from "../../components/ExternalLink";
import {useFetchUser} from "../../hooks/userHooks";
import {overtonLink} from "../../utils/staticLinks";
import dataPreview from "../../assets/sage_policy_profiles_preview_data.png"
import researchPreview from "../../assets/sage_policy_profiles_preview_research.png"
import SocialScienceSpaceBlock from "../../components/SocialScienceSpaceBlock/SocialScienceSpaceBlock";
import theme from "../../theme/theme";

const PreviewSection = ({heading, bullets, imageSrc, reverse}) => {
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const direction = (!isTablet && reverse === true) ? "row-reverse" : ""

    return <Box component={"section"} style={{flexDirection: direction}} className={"PreviewSection"}>
        <Box className={"Frame2"} sx={{alignItems: "center"}}>
            <img width={"100%"} src={imageSrc} alt={"Preview of the app showing a data visualisation"}/>
        </Box>
        <Box className={"Frame2"}>
            <Box component={"h2"} className={"text-headlineMd"} sx={{color: "sageNavy.main"}}>{heading}</Box>
            <br/>
            {bullets?.map(bullet =>
                <Box key={bullet} width={"100%"} component={"h3"} marginBottom={2} className={"text-bodyLg"}
                     sx={{display: "flex", flexFlow: "row nowrap", color: "sageNavy.main", gap: 2}}>
                    <ArrowForwardOutlined sx={{marginTop: 0.5}} fontSize={"inherit"}/>
                    <span>{bullet}</span>
                </Box>
            )}
        </Box>
    </Box>
}

const LandingPage = observer(function LandingPage() {
    const ref = useRef(null)
    const emailInputRef = useRef(null)
    const navigate = useNavigate()
    const fetchUser = useFetchUser();

    useEffect(() => {
        fetchUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sssArticles = [
        {
            title: "The why of turning research into impact",
            snippet: "Assertions that social and behavioral science have no real-world impact diminish our work and our world. One great defense is to understand how policymakers discover scholarly insights to ensure the uptake of your own work.",
            url: "https://www.socialsciencespace.com/why-policy-impact",
            borderColor: ""
        },
        {
            title: "The how of turning research into impact",
            snippet: "The value of social and behavioral science research ultimately rests on the improvements it makes in the lives of human beings and their institutions. How can your work make the leap from research to policy?",
            url: "https://www.socialsciencespace.com/how-policy-impact",
            borderColor: "#08C9C4"
        }
    ]

    const loginSection = () => {
        return <Box component={"section"} className={"LandingPageSection1"}>
            <Box className={"Frame1"} ref={ref} sx={{alignItems: "center"}}>
                <Box component={"p"} className={"text-titleLg StackBox"}
                     sx={{color: "sageNavy.main", maxWidth: "400px"}}>
                    Please input your email address to log in or sign up for an account.
                </Box>
                <EmailCollectionForm
                    emailInputRef={emailInputRef}
                    maxWidth={"400px"}
                />
            </Box>
            <Box className={"Frame2"} sx={{bgcolor: "sageHighlightTeal.main"}}>
                <Box component={"h2"} className={"Frame2Text"} sx={{color: "sageNavy.main"}}>
                    Are you a researcher or an academic? Track how your research has been cited in policy from
                    governments, think tanks, and
                    policymakers across the world
                </Box>
            </Box>
        </Box>
    }

    const freeSection = () => {
        return <Box component={"section"} className={"LandingPageSection2"} sx={{bgcolor: "sagePale.main"}}>
            <Box className={"Frame1"} sx={{alignItems: "flex-start"}}>
                <Box component={"h2"} className={"text-displaySm"} sx={{color: "sageNavy.main"}}>
                    It’s completely free
                </Box>
                <Box component={"p"} className={"text-bodyLg"} sx={{color: "sageNavy.main"}}>
                    We believe in the power of research to improve the world. That’s why we’ve teamed up with
                    the
                    policy experts at <ExternalLink className={"BodyLink"} to={overtonLink}>
                    Overton <Launch fontSize={"inherit"}/></ExternalLink> to help you understand your published
                    work’s policy impact, served up in an easy-to-use
                    dashboard.
                </Box>
                <Button variant={"contained"}
                        onClick={() => navigate(userStore.user ? "/dashboard" : "/login")}>
                    {userStore.user ? "Go to my profile" : "Get started"}
                </Button>
            </Box>
        </Box>
    }

    return (<>
        <Helmet>
            <meta name={"theme-color"} content={"#001A69"}/>
        </Helmet>
        <ScrollToTop/>
        <AppHeader onCTAClick={() => navigate("/login")} variant={"dark"}/>
        <main style={{scrollBehavior: "smooth"}} role="main">
            <section className={"LandingPageHero"}>
                <h1 className={"text-displayLg"}>Track your impact on policy</h1>
                <p className={"LandingPageIntro"}>
                    From research and name mentions through to policy citations; utilize data
                    from <ExternalLink className={"BodyLink"} to={overtonLink}>Overton</ExternalLink> to
                    track, visualize and share your policy impact. A free tool for individual researchers and
                    academics.
                </p>
            </section>

            {loginSection()}

            {freeSection()}

            <PreviewSection heading={"See an overview of when and where your research has been cited in policy"}
                            imageSrc={dataPreview}
                            bullets={["Visualize how your research has been cited across the world and over time.", "Export and download your data visualizations to share with colleagues, use in funding applications or anything else you can think of."]}
            />
            <PreviewSection heading={"Explore a detailed view of policy citations for each of your works"}
                            imageSrc={researchPreview}
                            reverse
                            bullets={["Filter policy citations by year, and download visualizations of when and where you've been cited.", "Export and download your list of policy citations as a CSV or PowerPoint file."]}
            />
            <SocialScienceSpaceBlock articles={sssArticles}/>
        </main>
        <footer role="contentinfo">
            <UpperFooter/>
            <BasicFooter/>
        </footer>
    </>)
})

export default LandingPage
