import {ListItemIcon, MenuItem} from "@mui/material";
import {Check} from "@mui/icons-material";
import React from "react";

const CustomMenuItem = ({selected, label, onClick, autoFocus}) => {
    return <MenuItem autoFocus={autoFocus} selected={selected}
                     onClick={onClick}>
        {selected && <ListItemIcon><Check/></ListItemIcon>}
        {label}
    </MenuItem>
}

export default CustomMenuItem
