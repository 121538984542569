import React from "react";
import {Button, IconButton, Menu} from "@mui/material";

const ToolbarItem = ({title, icon, children, onClick}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <Button onClick={onClick ? onClick : handleOpenMenu} sx={{display: {xs: "none", md: "inline-flex"}}}
                startIcon={icon}
                variant={"text"}>{title}</Button>
        <IconButton aria-label={title} onClick={onClick ? onClick : handleOpenMenu}
                    sx={{display: {xs: "inline-flex", md: "none"}}}>{icon}</IconButton>
        {!onClick && <Menu anchorEl={anchorEl}
                           onClick={handleClose}
                           onClose={handleClose}
                           open={open}>
            {children}
        </Menu>
        }
    </>
}

export default ToolbarItem
