import {Box, Button, IconButton, Toolbar, useMediaQuery} from "@mui/material";
import {Menu} from "@mui/icons-material";
import {Link, useNavigate} from "react-router-dom";
import LogoOnWhite from "../../assets/Sage_logo_policyprofiles_horizontal_RGB_navy_onwhite.svg";
import LogoOnNavy from "../../assets/Sage_logo_policyprofiles_horizontal_RGB_navy_onnavy.svg";
import React from "react";
import userStore from "../../stores/userStore";
import {observer} from "mobx-react-lite";
import ShareMenu from "../ShareMenu/ShareMenu";
import theme from "../../theme/theme";

const AppHeader = observer(({variant, toggleMenu, onCTAClick}) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (variant === 'light') {
        return (
            <Toolbar className={"NavigationHeader"}
                     component={"header"}
                     role={"banner"}>
                <Box className={"LogoContainer"}>
                    <IconButton aria-label={"menu"} sx={{display: {lg: "none"}}} onClick={toggleMenu}>
                        <Menu/>
                    </IconButton>
                    <Link style={{display: "flex"}} to={"/dashboard"}>
                        <img alt="dashboard-link" style={{height: "1.5rem"}} src={LogoOnWhite}/>
                    </Link>
                </Box>
                <ShareMenu/>
            </Toolbar>
        )
    } else {
        return <Toolbar className={"NavigationHeader Dark"} component={"header"} role={"banner"}>
            <Link style={{display: "flex", alignItems: "center"}} to={"/"}>
                <img alt="dashboard-link" style={{height: "1.5rem"}} src={LogoOnNavy}/>
            </Link>
            {onCTAClick && <Button onClick={userStore.user ? () => navigate("/dashboard") : onCTAClick} variant={"contained"}>
                {userStore.user ? (`${isMobile ? "M" : "Go to m"}y profile`) : "Log in"}
            </Button>}
        </Toolbar>
    }
})

export default AppHeader
