import HTTPService from "../services/HTTPService";
import userStore from "../stores/userStore";
import {dispatchError, dispatchInfo} from "../services/Notifications";
import {useLocation, useNavigate} from "react-router";
import {useOrcidAPI} from "./dataHooks";

export const useFetchUser = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const fetchUserAlerts = useFetchUserAlerts();
    const fetchBlacklist = useFetchBlacklist();
    const fetchOrcid = useOrcidAPI();

    return async (redirect = false) => {
        const response = await HTTPService.get('/spa/users/current', (err) => {
            if (err.response?.status === 404) {
                userStore.setUser(null)
                if (redirect) {
                    dispatchInfo("Please login to continue to the app")
                    navigate("/login", {state: {prevPath: location.pathname}})
                }
            }
        });
        if (response) {
            const user = response.data.data
            if (user.orcid) {
                const orcidUser = await fetchOrcid(user.orcid)
                user.name_variants = orcidUser.name_variants
                user.affiliations = Object.keys(orcidUser.affiliations)
            }
            userStore.setUser(user)
            if (!user.has_onboarded && !location.pathname.includes("account-setup") && location.pathname !== "/") {
                navigate("/account-setup/orcid-lookup")
            } else {
                await fetchUserAlerts()
                await fetchBlacklist()
            }
        }
    }
}

export const useFetchPublicUser = () => {
    const navigate = useNavigate();

    return async (id) => {
        const response = await HTTPService.get('/spa/public/users/' + id, (err) => {
            if (err.response?.status === 404) {
                userStore.setPublicUser(null)
            }
            if (err.response?.status === 403) {
                dispatchError("Sorry, the profile you're trying to access is not public.")
                setTimeout(() => navigate("/"), 2000)
            }
        });
        if (response) {
            userStore.setPublicUser(response.data.data)
        }
    }
}

export const useUpdateUser = () => {
    return async (data, updateStore = true) => {
        const response = await HTTPService.put('/spa/users/current', data, null)
        const user = response.data.data
        if (user.orcid) {
            user.name_variants = userStore.user.name_variants
            user.affiliations = userStore.user.affiliations
        }
        updateStore && userStore.setUser(response.data.data)
    }
}

export const useFetchUserAlerts = () => {
    return async () => {
        const response = await HTTPService.get('/spa/users/current/emailAlerts')
        userStore.setUserAlerts(response.data.data)
    }
}

export const useUpdateUserAlerts = () => {
    return async (alerts) => {
        const response = await HTTPService.post('/spa/users/current/emailAlerts/bulk-create-or-update', {alerts: alerts})
        userStore.setUserAlerts(response.data.data)
    }
}

export const useFetchBlacklist = () => {
    return async () => {
        const response = await HTTPService.get('/spa/users/current/blacklistedMentions')
        userStore.setBlacklist(response.data.data)
    }
}

export const useUpdateBlacklist = () => {
    const fetchBlacklist = useFetchBlacklist();

    return async (policy_document_id, deleteEntry = true) => {
        const blacklistedMention = userStore.updatedBlackList.find(li => li.policy_document_id === policy_document_id)
        const index = userStore.updatedBlackList.findIndex(li => li.policy_document_id === policy_document_id)

        if (blacklistedMention && deleteEntry) {
            // if the document is already blacklisted, delete the entry and remove it from the temp blacklist
            await HTTPService.delete('/spa/users/current/blacklistedMentions/' + blacklistedMention.id, {})
            userStore.updatedBlackList.splice(index, 1)
        } else {
            const response = await HTTPService.post('/spa/users/current/blacklistedMentions', {policy_document_id})
            userStore.setUpdatedBlacklist([...userStore.updatedBlackList, response.data.data])
        }
        await fetchBlacklist()
    }
}
