import React from "react"
import {Launch} from "@mui/icons-material";
import {Stack} from "@mui/material";
import ExternalLink from "../ExternalLink";
import "./SSSArticle.scss"

const SSSArticle = ({url, title, snippet, borderColor}) => {
    return (
        <ExternalLink style={{borderColor: borderColor}} className={"sssArticle"} to={url}>
            <Stack sx={{height: "100%"}}>
                <h3 className={"sssTitle"}>{title} <Launch fontSize={"small"}/></h3>
                <p className={"sssSnippet"}>{snippet}</p>
                <p className={"sssAuthor"}>Social Science Space</p>
            </Stack>
        </ExternalLink>
    )
}

export default SSSArticle
