import CustomMenuItem from "./CustomMenuItem";
import FilterChip from "./FilterChip";
import React from "react";
import {typeLabel} from "../utils/utils";

const TypeFilter = ({updateFilters, filterValue, clearFilter}) => {

    return <FilterChip clearFilter={clearFilter}
                       active={filterValue}
                       label={typeLabel(filterValue)}>
        <CustomMenuItem label={"Policy citation"}
                        selected={filterValue === "citation"}
                        onClick={() => updateFilters("citation", "type")}/>
        <CustomMenuItem label={"Name mention"}
                        selected={filterValue === "mention"}
                        onClick={() => updateFilters("mention", "type")}/>
    </FilterChip>
}

export default TypeFilter
