import {Link} from "react-router-dom";
import {ArrowForward, Launch} from "@mui/icons-material";
import {Box, Button, Stack} from "@mui/material";
import {formattedDate} from "../../utils/utils";
import React from "react";
import PDFPageLink from "../PDFPageLink";
import dataStore from "../../stores/dataStore";
import "./Citations.scss"
import ExternalLink from "../ExternalLink";

const Citation = ({citation, citingDocument}) => {
    const citationTitle = () => {
        return <ExternalLink to={citingDocument.document_url}>
            <h4 className={"CitationTitle"}>{citingDocument.title} <Launch fontSize={"inherit"}/></h4>
        </ExternalLink>
    }

    const authors = () => {
        if (citingDocument.authors.length > 1) {
            return citingDocument.authors[0] + " et al."
        }
        return citingDocument.authors[0]
    }

    return (
        <Box className={"Citation"}>
            {citationTitle()}
            <Box display={"flex"} flexDirection={"column"}>
                <span>{authors()} on {formattedDate(citingDocument.published_on)}</span>
                <span className={"text-labelLg"}>
                on <PDFPageLink document={citingDocument} pageNumber={citation.page}/>
            </span>
            </Box>
        </Box>
    )
}

const Citations = ({researchDOI, showAll, citations}) => {
    const slicedCitations = citations && !showAll ? citations.slice(0, 3) : citations

    if (!citations) {
        return <Box flexBasis={"40%"} display={"flex"} flexDirection={"column"} gap={1}>
            <span className={"text-labelLg"}>No policy citations found</span>
        </Box>
    } else {
        return <Box flexBasis={"40%"} display={"flex"} flexDirection={"row"} gap={4}>
            <span className={"text-labelLg"} style={{whiteSpace: "nowrap"}}>Cited in:</span>
            <Stack flexBasis={"100%"} gap={2}>
                {slicedCitations.map(c => {
                    const citingDocument = dataStore.policyDocuments.get(c.policy_document_id)
                    return <Citation key={citingDocument.policy_document_id} citation={c}
                                     citingDocument={citingDocument}/>
                })}
                {!showAll && citations.length > 3 && <Button component={Link} to={"/research/" + encodeURIComponent(researchDOI)} sx={{alignSelf: "flex-start"}} variant={"outlined"}
                        endIcon={<ArrowForward/>}>View all {citations.length.toLocaleString()} citations </Button>
                }
            </Stack>
        </Box>

    }
}

export default Citations
export {Citation}
