import {Navigate, useSearchParams} from "react-router-dom";
import {dispatchError} from "../services/Notifications";
import React from "react"
import {observer} from "mobx-react-lite";

const Redirect = observer(({to, condition, value, conditionalTo}) => {
    const [searchParams] = useSearchParams()

    const conditionalParam = searchParams.get(condition)
    const message = searchParams.get("message")

    try {
        if (conditionalParam === value) {
            return <Navigate to={conditionalTo}/>
        } else if (message) {
            dispatchError(message)
            return <Navigate to={to}/>
        }
    } catch (error) {
        dispatchError(error.message)
    }
})

export default Redirect
