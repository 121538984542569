import React from "react"
import {MaterialDesignContent, SnackbarProvider} from "notistack";
import {styled} from "@mui/material";

const CustomSnackbarProvider = () => {

    const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
        '&.notistack-MuiContent-success': {
            backgroundColor: '#edf7ed',
            boxShadow: 'none',
            color: '#1e4620'
        },
        '&.notistack-MuiContent-error': {
            backgroundColor: '#fdeded',
            boxShadow: 'none',
            color: '#5f2120'
        },
        '&.notistack-MuiContent-warning': {
            backgroundColor: '#FFF4E5',
            boxShadow: 'none',
            color: '#663c00'
        },
        '&.notistack-MuiContent-info': {
            backgroundColor: '#D9EEFF',
            boxShadow: 'none',
            color: '#014361'
        },
    }));


    return <SnackbarProvider
        Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
            warning: StyledMaterialDesignContent,
            info: StyledMaterialDesignContent,

        }}
        preventDuplicate autoHideDuration={4000} anchorOrigin={{vertical: 'top', horizontal: 'center'}}/>
}

export default CustomSnackbarProvider
