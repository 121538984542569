import React, {useEffect, useState} from "react";
import {Box, Button} from "@mui/material";
import PageIntro from "../../components/PageIntro/PageIntro";
import StackBox from "../../components/StackBox/StackBox";
import {useNavigate} from "react-router";
import ButtonContent from "../../components/ButtonContent";
import {useLocation} from "react-router-dom";
import VariantsInput from "../../components/VariantsInput";
import {useUpdateUser} from "../../hooks/userHooks";
import userStore from "../../stores/userStore";

const NameVariants = () => {
    const [nameVariants, setNameVariants] = useState(['']);
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const orcid = location.state?.orcid
    const hasDois = location.state?.hasDois
    const updateUser = useUpdateUser();

    useEffect(() => {
        if (!orcid && !hasDois) {
            navigate("/account-setup/orcid-lookup")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasDois])

    const handleUpdateUser = async (skip) => {
        setLoading(true)
        setError("")
        if (!skip && !nameVariants[0].length) {
            setError("Please add a name variant")
            setLoading(false)
        } else {
            nameVariants[nameVariants.length - 1] === "" && nameVariants.pop()
            if (orcid) {
                await updateUser({orcid})
            }
            await updateUser({name_variants: [...userStore.user.name_variants, ...nameVariants]}, false)
        }
        navigate("/dashboard-setup")
    }

    return (
        <Box className={"SetupContent"}>
            <Box className={"SetUpFrame"}>
                <PageIntro>
                    <h1 className={"IntroHeading"}>Great! One more thing...</h1>
                    <p className={"IntroCopy"}>
                        In order for us to locate all the relevant citations and mentions, it would be helpful to know
                        if there are any other name variations you know you’ve been cited as or published as (eg “Lizzy
                        Smith” or “Liz Smith”)
                    </p>
                </PageIntro>
            </Box>
            <Box className={"SetUpFrame"}>
                <StackBox>
                    <VariantsInput variantLabel={"Name variant"} variantPlaceholder={"eg 'Liz Smith'"} variants={nameVariants}
                                   setVariants={setNameVariants} errors={error} setErrors={setError}/>
                    <Button className={"StackStretch"} variant={"contained"} onClick={() => handleUpdateUser(false)}>
                        <ButtonContent isLoading={loading} title={"Continue"}/>
                    </Button>
                    <Button className={"StackStretch"} variant={"outlined"} onClick={() => handleUpdateUser(true)}>Skip this
                        step</Button>
                </StackBox>
            </Box>
        </Box>
    )
}

export default NameVariants
