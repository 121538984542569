import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet";
import {Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Switch} from "@mui/material";
import ButtonContent from "../../components/ButtonContent";
import AppToolbar from "../../components/AppToolbar/AppToolbar";
import Prompt from "../../hooks/usePrompt";
import {dispatchSuccess} from "../../services/Notifications";
import "./Alerts.scss"
import userStore from "../../stores/userStore";
import {observer} from "mobx-react-lite";
import {autorun} from "mobx";
import {useUpdateUser, useUpdateUserAlerts} from "../../hooks/userHooks";
import SkeletonItem from "../../components/Skeleton";

const AlertSwitch = observer(({alert, title, handleChange, description = true}) => {

    if (!alert) {
        return
    }

    return (
        <Stack>
            <Box className={"AlertSwitch"}>
                <Stack>
                    <h3 className={"text-titleLg"}>{title}</h3>
                    <h3 className={"text-bodyMd"}>{description}</h3>
                </Stack>
                <Switch checked={alert.frequency !== "never"}
                        onClick={() => handleChange(alert.frequency !== 'never' ? 'never' : 'weekly')}/>
            </Box>
            <br/>
            {alert.frequency !== "never" && <Stack>
                <RadioGroup value={alert.frequency} onChange={(e) => handleChange(e.target.value)}
                            defaultValue={alert.frequency}>
                    <FormControlLabel value={"weekly"} control={<Radio/>} label={"Weekly"}/>
                    <FormControlLabel value={"monthly"} control={<Radio/>} label={"Monthly"}/>
                </RadioGroup>
            </Stack>}
        </Stack>
    )
})

const Alerts = observer(() => {
    const [alerts, setAlerts] = useState([])
    const [user, setUser] = useState(null)
    const [hasUpdates, setHasUpdates] = useState(false);
    const updateUserAlerts = useUpdateUserAlerts();
    const updateUser = useUpdateUser();
    const [loading, setLoading] = useState("")


    useEffect(() => {
        autorun(() => {
            userStore.alerts && setAlerts(JSON.parse(JSON.stringify(userStore.alerts)))
            setUser({...userStore.user})
        })
        return () => {
            setAlerts(JSON.parse(JSON.stringify(userStore.alerts)))
        }
    }, [])

    const handleUpdateAlerts = async () => {
        setLoading("Update")
        await updateUserAlerts(alerts)
        await updateUser({marketing_opt_in: user.marketing_opt_in, features_opt_in: user.features_opt_in})
        dispatchSuccess("Your alert preferences have been saved ")
        setHasUpdates(false)
        setLoading("")
    }

    const unsubscribeFromAll = async () => {
        setLoading("Unsubscribe")
        const updatedAlerts = []
        alerts.forEach(a => {
            if (a.id) {
                const alert = {
                    frequency: "never",
                    id: a.id,
                    alert_type: a.alert_type
                }
                updatedAlerts.push(alert)
            }
        })
        await updateUserAlerts(updatedAlerts)
        await updateUser({marketing_opt_in: false, features_opt_in: false})
        dispatchSuccess("You've been unsubscribed from all email alerts")
        setHasUpdates(false)
        setLoading("")
    }

    const handleSetAlert = (alert, value) => {
        alert.frequency = value
        setAlerts([...alerts])
        setHasUpdates(true)
    }

    const updateUserOptIn = (option) => {
        user[option] = !user[option]
        setUser({...user})
        setHasUpdates(true)
    }

    const citationAlert = alerts?.find(a => a.alert_type === "citation")
    const mentionAlert = alerts?.find(a => a.alert_type === "mention")

    const skeletonAlerts = <Stack gap={4} flex={"1 1 60%"}>
        <SkeletonItem type={"ALERT"}/>
        <SkeletonItem type={"ALERT"}/>
        <SkeletonItem type={"ALERT"}/>
        <SkeletonItem type={"ALERT"}/>
        <SkeletonItem type={"ALERT"}/>
    </Stack>

    return (
        <>
            <Helmet>
                <title>Alerts</title>
            </Helmet>
            <Prompt when={hasUpdates}
                    message={"Your alert preference update will be lost, are you sure you don't want to save them first?"}
                    beforeUnload={true}/>
            <Stack minHeight={"calc(100vh - 146px)"}>
                <AppToolbar title={"Email alerts"} allowExports={false}/>
                <Box className={"AlertsContainer"}>
                    <Stack flex={"1 1 40%"}>
                        <h2 className={"text-bodyLg"}>Get alerted when your research is cited somewhere new. You can
                            choose to group alerts into weekly or monthly digests. </h2>
                    </Stack>
                    {(!userStore.user || !user) ?
                        skeletonAlerts
                        :
                        <Stack flex={"1 1 60%"} gap={4}>
                            <AlertSwitch alert={citationAlert}
                                         handleChange={v => handleSetAlert(citationAlert, v)}
                                         title={"Policy citations"}
                                         description={"Get alerted when your research is cited in policy."}
                            />
                            <AlertSwitch alert={mentionAlert}
                                         handleChange={v => handleSetAlert(mentionAlert, v)}
                                         title={"Policy mentions"}
                                         description={"Get alerted when you’re mentioned in policy"}
                            />
                            <Box className={"AlertSwitch"}>
                                <Stack>
                                    <h3 className={"text-titleLg"}>Policy insights and app features</h3>
                                    <h3 className={"text-bodyMd"}>Get the latest policy insights and engagement advice,
                                        and
                                        be the first to know when new app features are available.</h3>
                                </Stack>
                                <Switch checked={user.marketing_opt_in}
                                        onClick={() => updateUserOptIn("marketing_opt_in")}/>
                            </Box>
                            <Box className={"AlertSwitch"}>
                                <Stack>
                                    <h3 className={"text-titleLg"}>Feedback requests</h3>
                                    <h3 className={"text-bodyMd"}>Help improve Sage Policy Profiles by allowing us to
                                        contact you for feedback about this product.</h3>
                                </Stack>
                                <Switch checked={user.features_opt_in}
                                        onClick={() => updateUserOptIn("features_opt_in")}/>
                            </Box>
                            <Stack gap={2}>
                                <Button disabled={!hasUpdates} className={"StackStretch"} variant={"contained"}
                                        onClick={handleUpdateAlerts}>
                                    <ButtonContent isLoading={loading === "Update"} title={"Update preferences"}/>
                                </Button>
                                <Button className={"StackStretch"} variant={"outlined"} onClick={unsubscribeFromAll}>
                                    <ButtonContent isLoading={loading === "Unsubscribe"}
                                                   title={"Unsubscribe from all emails"}/>
                                </Button>
                            </Stack>
                        </Stack>
                    }
                </Box>
            </Stack>
        </>
    )
})

export default Alerts
