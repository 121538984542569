import {Helmet} from "react-helmet";
import React, {useEffect, useState} from "react";
import {Box, Button, List} from "@mui/material";
import {ArrowBack, Launch} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import dataStore from "../../stores/dataStore";
import {observer} from "mobx-react-lite";
import {autorun} from "mobx";
import "./ResearchDocument.scss"
import {formattedDate, getDataFromResearchDoc, getYearFromString} from "../../utils/utils";
import Citations from "../../components/Citations/Citations";
import DataDisplay from "../../templates/DataDisplay";
import AppToolbar from "../../components/AppToolbar/AppToolbar";
import {policyExportHeaders} from "../../components/CSVExports";
import SkeletonItem from "../../components/Skeleton";
import {dispatchError} from "../../services/Notifications";
import userStore from "../../stores/userStore";
import ExternalLink from "../../components/ExternalLink";
import citationsStore from "../../stores/citationsStore";
import DOMPurify from "dompurify";

const ResearchDocument = observer(() => {
    const navigate = useNavigate();
    const params = useParams();
    const [document, setDocument] = useState(null);
    const [filteredCitations, setFilteredCitations] = useState(null)

    const documentCitations = citationsStore.initialised && citationsStore.citations?.get(params.documentId)

    const getCSVData = () => {
        return filteredCitations.map(citation => {
            const policyDoc = dataStore.policyDocuments.get(citation.policy_document_id)
            policyDoc.cited_dois = citation.doi
            policyDoc.cited_titles = dataStore.getResearchDoc(citation.doi).title
            return {...policyDoc}
        })
    }

    useEffect(() => {
        autorun(() => {
            if (!dataStore.initialised || !userStore.user) {
                return
            }

            if (params?.documentId) {
                const currentDocument = dataStore.getResearchDoc(params.documentId)
                const citations = citationsStore.citations.get(params.documentId)

                if (dataStore.research && !currentDocument) {
                    navigate("/research")
                    dispatchError("Sorry, we couldn't find that research paper.")
                }
                setDocument(currentDocument)
                if (citations) {
                    setFilteredCitations(sortCitationsByDate(citations))
                }
            }
        })
        return () => {
            params.documentId = null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const sortCitationsByDate = (citations) => {
        return citations.map(c => {
            c.published_on = dataStore.getDocument(c.policy_document_id).published_on
            return c
        })
    }
    const setFilteredData = (filters) => {
        let filteredCitations = citationsStore.citations.get(document.doi)
        filters.forEach(filter => {
            if (filter.value) {
                filteredCitations = filteredCitations.filter(citation => {
                    if (filter.key === "policy_published_on") {
                        const year = getYearFromString(dataStore.policyDocuments.get(citation.policy_document_id).published_on)
                        return year >= filter.value[0] && year <= filter.value[1]
                    }
                })
            }
        })
        setFilteredCitations(filteredCitations)
    }

    if (!document) {
        return <List sx={{padding: {xs: 2, sm: 3}}}>
            <SkeletonItem/>
            <SkeletonItem/>
            <SkeletonItem/>
            <SkeletonItem/>
        </List>
    }

    const title = DOMPurify.sanitize(document.title)

    return (
        <>
            <Helmet>
                <title>Research | {title} </title>
            </Helmet>
            <Box className={"ResearchDocument"}>
                <Box className={"ResearchDocumentInfo"}>
                    <Button onClick={() => navigate("/research")} variant={"text"} startIcon={<ArrowBack/>}>
                        View all research documents
                    </Button>
                    <h1 className={"text-headlineMd"}>{title}</h1>
                    <span className={"text-titleMd"}>{document.authors?.join(', ')}</span>
                    <ExternalLink to={document.document_url}>
                        <span className={"text-titleMd"}>{document.journal} {document.document_url && <Launch fontSize={"inherit"}/>}</span>
                    </ExternalLink>
                    <span className={"text-bodyMd"}>{formattedDate(document.published_on)}</span>
                    <ExternalLink to={document.document_url ?? "https://doi.org/" + document.doi}>
                        <span className={"text-bodyLg"}>{document.doi} <Launch fontSize={"inherit"}/></span>
                    </ExternalLink>
                </Box>
                <AppToolbar showTypeFilter={false}
                            getCSVData={getCSVData}
                            exportHeaders={policyExportHeaders}
                            allowExports={documentCitations?.length > 0}
                            showPublicationFilter={false}
                            setFilteredData={documentCitations?.length > 0 && setFilteredData}
                            title={"Policy citations"}/>
                <Box className={"ResearchDocumentCitations"}>
                    <Box sx={{flex: "1 1 50%", paddingTop: 1}}>
                        <Citations citations={filteredCitations} showAll/>
                    </Box>
                    {documentCitations?.length > 5 && <Box sx={{flex: "1 1 50%"}}>
                        <DataDisplay key={filteredCitations.length} showDateFilter={false} showResearch={false}
                                     chartData={getDataFromResearchDoc(document, filteredCitations)}/>
                    </Box>}
                </Box>
            </Box>
        </>
    )
})

export default ResearchDocument
