import {Box, Button, Container, Toolbar, useMediaQuery} from "@mui/material";
import {FilterList, SortByAlpha} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import "./AppToolbar.scss"
import ToolbarItem from "../ToolbarItem";
import Typography from "@mui/material/Typography";
import {setFilters} from "../../utils/utils";
import {observer} from "mobx-react-lite";
import DateFilterMenu from "../DateFilterMenu";
import ExportMenu from "../ExportMenu";
import dataStore from "../../stores/dataStore";
import InfoToolTip from "../InfoToolTip/InfoToolTip";
import userStore from "../../stores/userStore";
import theme from "../../theme/theme";
import TypeFilter from "../TypeFilter";
import CustomMenuItem from "../CustomMenuItem";

const AppToolbar = observer(({
                                 title,
                                 headingComponent = "h1",
                                 showPublicationFilter = true,
                                 showCitedFilter = true,
                                 showTypeFilter = true,
                                 setFilteredData,
                                 setSortedData,
                                 exportHeaders,
                                 getCSVData,
                                 allowExports = true,
                                 sortByCited = false,
                                 infoTipTitle,
                                 infoTipContent,
                                 infoTipCTA
                             }) => {
    const [showFilters, setShowFilters] = useState(Boolean(setFilteredData));
    const chipReference = useRef(null);
    const policyTipRef = useRef()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleFilterBar = (event) => {
        if (!showFilters) {
            setShowFilters(true)
            if (event.type === 'keydown' && (event.key === 'Enter')) {
                chipReference.current.focus()
            }
        } else {
            setShowFilters(false)
        }
    }

    useEffect(() => {
        if (chipReference.current) {
            chipReference.current.focus()
        }
    })

    const updateFilters = (value, key) => {
        setFilters(value, key, setFilteredData)
    }

    const filterValue = (key) => dataStore.getFilterValue(key)

    return (<Container className={"AppToolbar" + (userStore.isPublic ? " PublicToolbar" : "")}>
        <Toolbar sx={{justifyContent: "space-between"}}>
            <Typography component={headingComponent} className={isMobile ? "text-headlineSm" : "text-headlineMd"}>
                {title}
                {infoTipTitle && <InfoToolTip label={infoTipCTA ? infoTipCTA : ""} ref={policyTipRef}>
                    <Box className={"InfoPopOver"}>
                        <Box className={"InfoPopOverCopy"}>
                            <Box className={"InfoPopoverTitle"}>
                                {infoTipTitle}
                            </Box>
                            <Box component={"p"} className={"text-bodyMd"}>
                                {infoTipContent}
                            </Box>
                            <Box className={"InfoPopOverActions"}>
                                <Button onClick={() => policyTipRef.current.closePopOver()}>Ok, got it</Button>
                            </Box>
                        </Box>
                    </Box>
                </InfoToolTip>}
            </Typography>
            <Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"}>
                {setFilteredData &&
                    <ToolbarItem title={"Filter"} icon={<FilterList/>} onClick={(e) => toggleFilterBar(e)}/>}
                {setSortedData && <ToolbarItem title={"Sort by"} icon={<SortByAlpha/>}>
                    <CustomMenuItem label={"Newest first"}
                                    selected={dataStore.sortValue.type === "date" && dataStore.sortValue.descending}
                                    onClick={() => dataStore.setSortValue("date", true)}/>
                    <CustomMenuItem label={"Oldest first"}
                                    selected={dataStore.sortValue.type === "date" && !dataStore.sortValue.descending}
                                    onClick={() => dataStore.setSortValue("date", false)}/>
                    <CustomMenuItem label={"Alphabetically A-Z"}
                                    selected={dataStore.sortValue.type === "title" && !dataStore.sortValue.descending}
                                    onClick={() => dataStore.setSortValue("title", false)}/>
                    <CustomMenuItem label={"Alphabetically Z-A"}
                                    selected={dataStore.sortValue.type === "title" && dataStore.sortValue.descending}
                                    onClick={() => dataStore.setSortValue("title", true)}/>
                    {sortByCited && <CustomMenuItem label={"Most cited"}
                                                    selected={dataStore.sortValue.type === "citations" && dataStore.sortValue.descending}
                                                    onClick={() => dataStore.setSortValue("citations", true)}/>}
                    {sortByCited && <CustomMenuItem label={"Least cited"}
                                                    selected={dataStore.sortValue.type === "citations" && !dataStore.sortValue.descending}
                                                    onClick={() => dataStore.setSortValue("citations", false)}/>}
                </ToolbarItem>}
                {allowExports && <ExportMenu getCSVData={getCSVData} headers={exportHeaders} title={"Export data"}/>}
            </Box>
        </Toolbar>
        <Toolbar sx={{gap: 1}} className={"FilterBar " + (showFilters ? "Visible" : "")}>
            {showPublicationFilter && <DateFilterMenu ref={chipReference}
                                                      prefix={"Research published"}
                                                      filterValue={filterValue("research_published_on")}
                                                      label={"Publication date"}
                                                      filter={"research_published_on"}
                                                      clearFilter={() => updateFilters(null, "research_published_on")}
                                                      updateFilters={updateFilters}
            />}
            {showCitedFilter && <DateFilterMenu filterValue={filterValue("policy_published_on")}
                                                prefix={"Citations from"}
                                                label={"Cited date"}
                                                filter={"policy_published_on"}
                                                clearFilter={() => updateFilters(null, "policy_published_on")}
                                                updateFilters={updateFilters}
            />}
            {showTypeFilter && <TypeFilter filterValue={filterValue("type")} updateFilters={updateFilters}
                                           clearFilter={() => updateFilters(null, "type")}/>}
        </Toolbar>
    </Container>)
})

export default AppToolbar
