import {Box, Button, IconButton, Popover} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ArrowBack} from "@mui/icons-material";
import React, {useState} from "react";
import dayjs from "dayjs";

const DateRangePicker = ({anchorEl, open, setShowDatePicker, applyDateRange}) => {
    const today = dayjs();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    return (
        <Popover anchorOrigin={{vertical: 'bottom', horizontal: 'left',}} anchorEl={anchorEl} open={open}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{padding: "8px 12px 0"}} gap={1} display={"flex"} flexWrap={"wrap"} alignItems={"flex-start"}
                     flexDirection={"Column"}>
                    <IconButton onClick={() => setShowDatePicker(false)}><ArrowBack/></IconButton>
                    <DatePicker label={"From"} value={startDate} views={["year"]} maxDate={endDate ? endDate : today} closeOnSelect onChange={value => setStartDate(value)}/>
                    <DatePicker label={"To"} value={endDate} views={["year"]} minDate={startDate}  maxDate={today} closeOnSelect onChange={value => setEndDate(value)}/>
                </Box>
                <Box sx={{padding: "8px 12px"}} display={"flex"} justifyContent={"flex-end"} alignSelf={"flex-end"}>
                    <Button onClick={() => applyDateRange(false)} variant={"text"}>Cancel</Button>
                    <Button onClick={() => applyDateRange([startDate.get('year').toString(), endDate.get('year').toString()])} variant={"text"}>Save</Button>
                </Box>
            </LocalizationProvider>
        </Popover>
    )
}

export default DateRangePicker
