import React, {useRef, useState} from 'react';
import {Box, TextField, Button, IconButton, InputAdornment} from "@mui/material"
import PageIntro from '../../components/PageIntro/PageIntro';
import InfoToolTip from '../../components/InfoToolTip/InfoToolTip';
import StackBox from '../../components/StackBox/StackBox';
import {handleEnter} from "../../utils/utils";
import { useNavigate, useLocation } from 'react-router';
import ButtonContent from "../../components/ButtonContent";
import {orcidLink} from "../../utils/staticLinks";
import {Close} from "@mui/icons-material";
import {useFetchOpenAlexAuthors, useOrcidAPI} from "../../hooks/dataHooks";

const OrcidLookup = () => {
    const location = useLocation()
    const buttonRef = useRef(null)
    const [orcid, setOrcid] = useState(location?.state?.orcid ?? "")
    const [name, setName] = useState(location?.state?.name ?? "")
    const [orcidError, setOrcidError] = useState("")
    const [nameError, setNameError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const fetchOrcid = useOrcidAPI();
    const fetchOpenAlexAuthors = useFetchOpenAlexAuthors();

    const submit = async () => {
        setLoading(true)
        setOrcidError("")
        setNameError("")
        if (orcid.length) {
            try {
                const response = await fetchOrcid(orcid)
                navigate("/account-setup/confirm-orcid", {state: {author: response}})
            } catch (err) {
                setLoading(false)
                setOrcidError(err.errors)
            }
        } else try {
            const authors = await fetchOpenAlexAuthors(name)
            navigate("/account-setup/confirm-profile", {state: {name, authors}})
        } catch (err) {
            setNameError(err.errors)
        }
        setLoading(false)
    }

    return (
        <Box className={"SetupContent"}>
            <Box className={"SetUpFrame SetupIntroContainer"}>
                <PageIntro>
                    <h1 className={"IntroHeading"}>Welcome!</h1>
                    <p className={"IntroCopy"}>
                        This tool is designed for individuals to track and understand how their research impacts policy.
                    </p>
                    <br/>
                    <p className={"IntroCopy"}>
                        First, we’ll need a few details to help us find your research.
                    </p>
                    <br/>
                </PageIntro>
            </Box>
            <Box className={"SetUpFrame"}>
                <StackBox>
                    <p className={"IntroCopy"}>
                        We recommend using your ORCID iD, if you have one, to find the research linked to your ORCID
                        record. This
                        is usually the fastest way to get started.
                    </p>
                    <InfoToolTip label={"What's an ORCID iD/how do I get one?"}>
                        <Box className={"InfoPopOver"}>
                            <Box className={"InfoPopOverCopy"}>
                                <div className={"InfoPopoverTitle"}>
                                    What&apos;s an ORCID iD?
                                </div>
                                <div className={"text-bodyMd"}>
                                    ORCID® provides a persistent digital identifier (an ORCID iD) that you own and
                                    control, and that distinguishes you from every other researcher. You can connect
                                    your iD with your professional information — affiliations, grants, publications,
                                    peer review, and more. You can use your iD to share your information with other
                                    systems, ensuring you get recognition for all your contributions, saving you time
                                    and hassle, and reducing the risk of errors.
                                </div>
                            </Box>
                            <Box className={"InfoPopOverActions"}>
                                <Button onClick={() => window.open(orcidLink, "_blank", "noreferrer")}>Get an ORCID iD
                                    on orcid.org ↗</Button>
                            </Box>
                        </Box>
                    </InfoToolTip>
                    <TextField
                        InputProps={{
                            endAdornment: <InputAdornment position={"end"}>
                                {orcid && <IconButton
                                    aria-label="clear ORCID field"
                                    color={"inherit"}
                                    onClick={() => setOrcid("")}
                                ><Close/>
                                </IconButton>}
                            </InputAdornment>
                        }}
                        error={!!orcidError}
                        onKeyUp={(event) => handleEnter(event, buttonRef)}
                        label={"ORCID iD (optional)"}
                        value={orcid}
                        placeholder={"XXXX-XXXX-XXXX-XXXX"}
                        helperText={orcidError}
                        onChange={event => setOrcid(event.target.value)}
                        className={"StackStretch"}/>
                    <TextField
                        required
                        error={!!nameError}
                        onKeyUp={(event) => handleEnter(event, buttonRef)}
                        label={"Your full name, as it appears your articles"}
                        value={name}
                        placeholder={"eg. Elizabeth Smith"}
                        helperText={nameError}
                        onChange={event => setName(event.target.value)}
                        className={"StackStretch"}/>
                    <Button ref={buttonRef} className={"StackStretch"} variant={"contained"} onClick={submit}>
                        <ButtonContent isLoading={loading} title={"Continue"}/>
                    </Button>
                </StackBox>
            </Box>
        </Box>
    )
}

export default OrcidLookup
