import axios from "axios";
import {dispatchError} from "./Notifications";

class HTTPService {
    static async handleRequest(method, url, data, headers, errorHandler, params, signal) {
        const request = async () => {
            try {
                return await axios({ method, url, data, headers, params, signal });
            } catch (err) {
                if (axios.isCancel(err)) {
                    return;
                }
                if (err.response && err.response.status === 419) {
                    // retry the request after refreshing CSRF token
                    await axios({ method: "get", url: "/sanctum/csrf-cookie", signal });
                    // retry the original request
                    return await request();
                }
                if (errorHandler) {
                    errorHandler(err);
                } else {
                    if (err.response) {
                        if (err.response.data && err.response.data.message) {
                            dispatchError(err.response.data.message);
                        } else {
                            dispatchError(`${err.response.status}: Sorry, something went wrong. Please try again.`);
                        }
                    } else {
                        dispatchError("Network Error: Please check your internet connection.");
                    }
                }
            }
        };

        return await request();
    }

    static async post(url, data, errorHandler = null, signal) {
        return HTTPService.handleRequest('post', url, data, null, errorHandler, null, signal)
    }

    static async put(url, data, errorHandler = null, signal) {
        return HTTPService.handleRequest('put', url, data, null, errorHandler, null, signal)
    }

    static async delete(url, data, errorHandler = null, signal) {
        return HTTPService.handleRequest('delete', url, data, null, errorHandler, null, signal)
    }

    static async postForm(url, data, errorHandler = null, signal = null) {
        const headers = {
            "Content-Type": "multipart/form-data"
        }
        return HTTPService.handleRequest('post', url, data, headers, errorHandler, null, signal)
    }

    static async get(url, errorHandler = null, params = null, signal) {
        return HTTPService.handleRequest('get', url, null, null, errorHandler, params, signal)
    }

}

export default HTTPService
