import {observer} from "mobx-react-lite";
import React, {useRef, useState} from "react";
import {Box, Button, IconButton, Stack} from "@mui/material";
import {ModeEdit} from "@mui/icons-material";
import ExternalLink from "../ExternalLink";
import {helpContactLink} from "../../utils/staticLinks";
import ButtonContent from "../ButtonContent";
import {dispatchError, dispatchSuccess} from "../../services/Notifications";
import userStore from "../../stores/userStore";
import AuthService from "../../services/AuthService";
import PasswordField from "../../screens/Login/PasswordField";
import VariantsInput from "../VariantsInput";
import {useFetchUser} from "../../hooks/userHooks";

const ActionButtons = ({save, cancel, saveButtonRef}) => {
    const [loading, setLoading] = useState(false)
    const handleSave = async () => {
        setLoading(true)
        await save()
        setLoading(false)
    }

    return <Box className={"SetUpButtonContainer"}>
        <Button ref={saveButtonRef} onClick={handleSave} variant={"contained"}>
            <ButtonContent isLoading={loading} title={"Save"}/>
        </Button>
        <Button onClick={cancel} variant={"outlined"}>Cancel</Button>
    </Box>
}


const defaultPasswordErrors = {newPassword: "", oldPassword: ""}

const PasswordFields = ({cancel, setIsEditing}) => {
    const [passwordErrors, setPasswordErrors] = useState(defaultPasswordErrors);
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const user = userStore.user;
    const saveButtonRef = useRef(null)
    const fetchUser = useFetchUser();

    const handleChange = (e, setPassword) => {
        setPasswordErrors(defaultPasswordErrors)
        setPassword(e.target.value)
    }
    const handleSetPassword = async () => {
        try {
            await AuthService.setPassword(
                {
                    "email": userStore.user?.email,
                    "password": password,
                    "password_confirmation": password,
                    "old_password": oldPassword
                })
        } catch (err) {
            if (err.errors.password) {
                setPasswordErrors({...passwordErrors, newPassword: err.errors.password})
                return
            }
            if (err.errors.old_password) {
                setPasswordErrors({...passwordErrors, oldPassword: err.errors.old_password})
                return
            } else {
                dispatchError(err.message)
            }
        }
        await fetchUser()
        dispatchSuccess("We've saved your new password")
        setIsEditing(false)
    }

    if (!user) {
        return
    }

    const renderField = () => {
        if (!user.has_password) {
            return <PasswordField
                autoComplete={"new-password"}
                isRequired={false}
                onEnterRef={saveButtonRef}
                textFieldLabel={"Create a password (minimum 10 characters)"}
                onChange={(e) => handleChange(e, setPassword)}
                passwordError={Boolean(passwordErrors.newPassword)}
                passwordErrorMessage={passwordErrors.newPassword}/>
        }

        return <Stack sx={{marginBottom: 2, gap: 2}}>
            <PasswordField
                onEnterRef={saveButtonRef}
                autoComplete={"current-password"}
                isRequired={false}
                textFieldLabel={"Old password"}
                onChange={(e) => handleChange(e, setOldPassword)}
                passwordError={Boolean(passwordErrors.oldPassword)}
                passwordErrorMessage={passwordErrors.oldPassword}/>
            <PasswordField
                onEnterRef={saveButtonRef}
                autoComplete={"new-password"}
                isRequired={false}
                textFieldLabel={"New password (minimum 10 characters)"}
                onChange={(e) => handleChange(e, setPassword)}
                passwordError={Boolean(passwordErrors.newPassword)}
                passwordErrorMessage={passwordErrors.newPassword}/>
        </Stack>
    }

    return <>
        {renderField()}
        <ActionButtons saveButtonRef={saveButtonRef} cancel={cancel} save={() => handleSetPassword()}/>
    </>
}

const AccountSetting = observer(({
                                     inputLabel,
                                     inputPlaceholder,
                                     title,
                                     field,
                                     content,
                                     canEdit,
                                     updateUser,
                                     setHasUpdates,
                                     isEditable
                                 }) => {
    const [isEditing, setIsEditing] = useState(false)
    const [array, setArray] = useState(content?.length ? content : [""])
    const [errors, setErrors] = useState("")
    const setEditSetting = (value) => {
        setIsEditing(value)
        setHasUpdates(value)
    }

    const cancel = () => {
        setEditSetting(false)
        setArray(content.length ? content : [""])
        setErrors("")
    }

    const save = async () => {
        if (!array[0].length) {
            setErrors("Please add " + (field === "affiliations" ? "an affiliation" : "a name variant"))
        } else {
            array[array.length - 1] === "" && array.pop()
            await updateUser(array)
            dispatchSuccess("We've saved your changes")
            setEditSetting(false)
        }
    }
    const editable = () => {
        if (title.toLowerCase() === "password") {
            return <PasswordFields cancel={cancel} setIsEditing={setEditSetting}/>
        }
        return <>
            <VariantsInput variantLabel={inputLabel} variantPlaceholder={inputPlaceholder} variants={array}
                           setVariants={setArray}
                           errors={errors} setErrors={setErrors}/>
            <ActionButtons cancel={cancel} save={save}/>
        </>
    }

    const readonly = () => {
        if (Array.isArray(content)) {
            if (content.length) {
                return <Box className={"text-bodyLg StackStretch"}>{content.join(", ")}</Box>
            }
        } else if (typeof content === "string") {
            return <span className={"text-bodyLg StackStretch"}>{content}</span>
        }
        return <div className={"text-bodyMd StackStretch"}><i>Add {title.toLowerCase()} to see where your mentioned in
            policy</i></div>
    }

    const editButton = () => {
        if (isEditable) {
            if (canEdit) {
                return isEditing ? null : <IconButton onClick={() => setEditSetting(true)}><ModeEdit/></IconButton>
            } else {
                return <span style={{textAlign: "right"}}>To update your {field}, please edit your ORCID record</span>
            }
        }
        return <span style={{textAlign: "right"}}>To update your {field}, please <ExternalLink
            to={helpContactLink}>contact support</ExternalLink></span>
    }

    return (
        <Box id={`/${title.toLowerCase()}`} className={"Setting"}>
            <Stack className={"SettingContent"}>
                <h3 className={"text-titleLg StackStretch"}>{title}</h3>
                {isEditing ? editable() : readonly()}
            </Stack>
            {editButton()}
        </Box>
    )
})

export default AccountSetting
