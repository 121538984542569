import React from "react";
import ResearchCard from "../ResearchCard/ResearchCard";
import {Box} from "@mui/material";
import "./ResearchListItem.scss";
import Citations from "../Citations/Citations";
import citationsStore from "../../stores/citationsStore";

const ResearchListItem = ({research}) => {

    return (
        <Box component={"li"} className={"ResearchListItem"}>
            <ResearchCard largeTitle research={research}/>
            <Citations researchDOI={research.doi} citations={citationsStore.citations.get(research.doi)}/>
        </Box>
    )
}

export default ResearchListItem
