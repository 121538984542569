import React, {useRef, useState} from "react";
import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import Chart from "../components/Chart";
import Map from "../components/Map/Map";
import ExportMenu from "../components/ExportMenu";
import html2canvas from "html2canvas";
import DateFilterMenu from "../components/DateFilterMenu";
import {dispatchError} from "../services/Notifications";

const DataDisplay = ({combined, chartData, showResearch = true, showCitations = true, showDateFilter = true}) => {
    const [view, setView] = useState("map");
    const [data, setData] = useState(chartData);
    const [filter, setFilter] = useState(null);
    const chartRef = useRef(null);
    const mapRef = useRef(null);

    const filterDataByYear = (filter) => {
        if (typeof filter === "string") {
            const newData = chartData.find(d => d.year === filter.toString())
            setData([newData])
            setFilter(filter)
        }
        if (Array.isArray(filter)) {
            const newData = chartData.filter(d => d.year >= filter[0] && d.year <= filter[1])
            setData(newData)
            setFilter(filter)
        }
    }

    const clearFilter = () => {
        setData(chartData)
        setFilter(null)
    }

    const saveChart = (ref, fileName = view, type = "png" ) => {
        const chartElement = ref.current;
        const today = new Date().toISOString().slice(0, 10);

        html2canvas(chartElement)
            .then((canvas) => {
                const chartImage = canvas.toDataURL('image/' + type);

                const link = document.createElement('a');
                link.href = chartImage;
                link.download = fileName + "_" + today;
                link.click();
            })
            .catch((error) => {
                dispatchError('Error saving chart, please try again:', error);
            });
    };

    return (
        <Box display={"flex"} flexDirection={"column"} gap={2} flex={"1 1 60%"}>
            {combined && <Box display={"flex"} justifyContent={"space-between"}>
                <ToggleButtonGroup value={view}>
                    <ToggleButton onClick={() => setView('map')} value={'map'}>Map</ToggleButton>
                    <ToggleButton onClick={() => setView('timeline')} value={'timeline'}>Timeline</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            }
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                {showDateFilter && <DateFilterMenu updateFilters={filterDataByYear}
                                                   label={"Any time"}
                                                   filterValue={filter}
                                                   filter={"year"}
                                                   clearFilter={clearFilter}/>}
                {combined && <ExportMenu exportToPPT={false} exportToCSV={false}
                                         exportToPng={() => saveChart(view === "timeline" ? chartRef : mapRef)}
                                         title={"Export " + view} data={chartData}/>}
            </Box>
            {!combined && <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <h3 className={"text-titleMd"}>Policy citations by location</h3>
                <ExportMenu exportToPPT={false} exportToCSV={false} exportToPng={() => saveChart(mapRef, "map")} title={"Export map"} data={data}/>
            </Box>}
            {(!combined || view === "map") && <>
                <Map mapData={data} ref={mapRef}/>
            </>}
            {!combined && <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <h3 className={"text-titleMd"}>{showResearch ? "Research and p" : "P"}olicy citations over time</h3>
                <ExportMenu exportToPPT={false} exportToCSV={false} exportToPng={() => saveChart(chartRef, "timeline")} title={"Export timeline"}
                            data={data}/>
            </Box>}
            {(!combined || view === "timeline") && <>
                <Chart showResearch={showResearch} showCitations={showCitations} ref={chartRef} data={data}/>
            </>
            }
        </Box>
    )
}

export default DataDisplay
