import React, {useState} from "react";
import {
    DashboardOutlined,
    FindInPageOutlined,
    AnalyticsOutlined,
    FormatQuoteOutlined,
    ContactSupportOutlined,
    Logout,
    Close
} from "@mui/icons-material";
import "./ResponsiveNavigationRail.scss";
import Typography from "@mui/material/Typography";
import {NavLink, useNavigate} from "react-router-dom";
import {Box, Drawer, IconButton} from "@mui/material";
import AppHeader from "../AppHeader/AppHeader";
import {Helmet} from "react-helmet";
import AuthService from "../../services/AuthService";
import {helpContactLink} from "../../utils/staticLinks";

const ResponsiveNavigationRail = ({variant = 'light', onCTAClick}) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const NavItem = ({icon, children, path, target}) => {
        return (
            <NavLink target={target} to={path} onClick={() => setOpen(false)} className={"NavLink"}>
                <Box className={"NavLinkIconContainer"}>
                    {icon}
                </Box>
                {children && <Typography color={"white"} className={"NavLinkLabel"}>{children}</Typography>}
            </NavLink>
        )
    }
    const toggleMenu = (event, value) => {
        //prevent closing on keyboard navigation
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(value ? value : !open)
    }

    const handleLogout = async () => {
        await AuthService.logOut();
        return navigate("/");
    }

    const navigation = () => {
        return (
            <Box onClick={() => setOpen(false)} component={"nav"}>
                {/* mobile navigation drawer*/}
                <Drawer className={"NavigationDrawer"} variant={"temporary"} open={open}
                        sx={{display: {xs: "block", lg: "none"}}}>
                    <Box><IconButton onClick={() => setOpen(false)}><Close color={"white"}/></IconButton></Box>
                    <NavItem path={"/dashboard"} icon={<DashboardOutlined/>}>Overview</NavItem>
                    <NavItem path={"/policy-citations"} icon={<FormatQuoteOutlined/>}>Policy citations</NavItem>
                    <NavItem path={"/research"} icon={<FindInPageOutlined/>}>My research</NavItem>
                    <NavItem path={"/about-the-data"} icon={<AnalyticsOutlined/>}>About the data</NavItem>
                    <div style={{flexGrow: 1}}></div>
                    <button onClick={handleLogout} className={"NavLink"}>
                        <Box className={"NavLinkIconContainer"}><Logout/></Box>
                        <Typography color={"white"} className={"NavLinkLabel"}>Logout</Typography>
                    </button>
                    <NavItem target={"_blank"} path={helpContactLink} icon={<ContactSupportOutlined/>}>Help</NavItem>
                </Drawer>

                {/* desktop navigation rail*/}
                <Drawer className={"NavigationRail"} variant={"permanent"}
                        sx={{display: {xs: "none", lg: "block"}}}>
                    <NavItem path={"/dashboard"} icon={<DashboardOutlined/>}>Overview</NavItem>
                    <NavItem path={"/policy-citations"} icon={<FormatQuoteOutlined/>}>Policy citations</NavItem>
                    <NavItem path={"/research"} icon={<FindInPageOutlined/>}>My research</NavItem>
                    <NavItem path={"/about-the-data"} icon={<AnalyticsOutlined/>}>About the data</NavItem>
                    <div style={{flexGrow: 1}}></div>
                    <button onClick={handleLogout} className={"NavLink"}>
                        <Box className={"NavLinkIconContainer"}><Logout/></Box>
                        <Typography color={"white"} className={"NavLinkLabel"}>Logout</Typography>
                    </button>
                    <NavItem target={"_blank"} path={helpContactLink} icon={<ContactSupportOutlined/>}>Help</NavItem>
                </Drawer>
            </Box>
        )

    }

    return (
        <>
            {open && <Helmet>
                <meta name={"theme-color"} content={"#001A69"}/>
            </Helmet>}
            <AppHeader variant={variant} onCTAClick={onCTAClick} toggleMenu={(event) => toggleMenu(event)}/>
            {navigation('user')}
        </>
    )
}

export default ResponsiveNavigationRail
