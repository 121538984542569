import React from "react";
import {Card, CardContent, Box} from "@mui/material";
import {Link} from "react-router-dom";
import "./ResearchCard.scss"
import Typography from "@mui/material/Typography";
import {Launch} from "@mui/icons-material";
import {formattedDate} from "../../utils/utils";
import ExternalLink from "../ExternalLink";
import userStore from "../../stores/userStore";
import DOMPurify from "dompurify"

const ResearchCard = ({research, largeTitle}) => {
    const authors = (authors) => {
        if(!authors) {
            return
        }
        if (authors.length > 1) {
            return authors[0] + " et al."
        }
        return authors[0]
    }

    if (!research) {
        return
    }
    const title = DOMPurify.sanitize(research.title)

    return (
        <Card elevation={3} className={"ResearchCard"}>
            <CardContent sx={{display: "flex", flexDirection: "column", gap: 1}}>
                <Link target={userStore.publicUser ? "_blank" : ""} to={userStore.publicUser ? research.document_url : "/research/" + encodeURIComponent(research.doi)}>
                    <Typography component={largeTitle ? "h3" : "h4"}
                                className={largeTitle ? "text-titleLg" : "text-titleMd"}
                                dangerouslySetInnerHTML={{__html: title}}/>
                </Link>
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography component={"span"}
                                className={"text-bodyMd"}>{authors(research.authors.reverse())} ({formattedDate(research.published_on, "year")})</Typography>
                    {research.document_url && <ExternalLink to={research.document_url} className={"ResearchCardLink"}>
                        {research.journal} <Launch fontSize={"inherit"}/>
                    </ExternalLink>}
                </Box>
            </CardContent>
        </Card>
    )
}

export default ResearchCard
