import React from "react"
import {Box, Button, Stack} from "@mui/material";
import "./FeatureBox.scss"
import {Launch} from "@mui/icons-material";
import {Link} from "react-router-dom";

const FeatureBox = ({title, children, ctaTitle, ctaIsExternal, ctaLink}) => {
    return <Stack className={"FeatureBox"}>
        <Box component={"h3"} color={"sageNavy.main"} className={"text-titleLg"}>{title}</Box>
        {children}
        {ctaLink && <Box>
            <Button justifySelf={"flex-start"} variant={"contained"} endIcon={ctaIsExternal && <Launch/>}
                    component={Link} to={ctaLink} target={ctaIsExternal && "_blank"}>{ctaTitle}</Button>
        </Box>}
    </Stack>
}

export default FeatureBox
