import React, {useRef, useState} from "react";
import {Box, Button, TextField} from "@mui/material";
import PageIntro from "../../components/PageIntro/PageIntro";
import InfoToolTip from "../../components/InfoToolTip/InfoToolTip";
import StackBox from "../../components/StackBox/StackBox";
import {useNavigate, useLocation, useOutletContext} from "react-router";
import MatchAuthorNames from "../../utils/MatchAuthorNames";
import {observer} from "mobx-react-lite";
import userStore from "../../stores/userStore";
import {handleEnter} from "../../utils/utils";
import ButtonContent from "../../components/ButtonContent";
import Bugsnag from "@bugsnag/js";
import {useUpdateUser} from "../../hooks/userHooks";
import {useValidateDois} from "../../hooks/dataHooks";

const DoiLookup = observer(() => {
    const ref = useRef()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(location?.state?.name ?? "")
    const [affiliation, setAffiliation] = useState(location?.state?.affiliation ?? "")
    const [nameError, setNameError] = useState("")
    const [ids, setIds] = useState(location?.state?.ids ?? "")
    const [doiError, setDoiError] = useState("")
    const nameInput = useRef()
    const continueButtonRef = useRef()
    const navigate = useNavigate()
    const [view, setView] = useOutletContext();
    const updateUser = useUpdateUser();
    const validateDois = useValidateDois();

    // Get route path
    const path = location.pathname.split('/')[1]
    const nameErrorMessage = "Please enter your full name (this will help us find your citations and mentions in policy)"
    const doiErrorMessage = "Please enter at least one valid DOI or PubMed ID"

    const lookupDois = async () => {
        setDoiError("")
        setNameError("")
        setLoading(true)
        if (ids) {
            if (path === "account-setup") {
                if (!name) {
                    setNameError(nameErrorMessage)
                    setLoading(false)
                    nameInput.current.scrollIntoView({behavior: "smooth", block: "start"})
                    return
                }
                // Save given name to user
                await updateUser({name, affiliations: [affiliation]})
            }
            try {
                const dois = ids.split("\n").filter(id => id.length)
                Bugsnag.notify(dois)
                const response = await validateDois(dois)
                const validDois = Object.keys(response.valid_dois)
                const invalidDois = Object.keys(response.invalid_dois)
                if (!validDois.length) {
                    setDoiError(doiErrorMessage)
                    setLoading(false)
                }
                if (invalidDois.length) {
                    navigate(`/${path}/doi-not-found`, {state: {response, name, affiliation, ids}})
                } else {
                    const nameMatches = MatchAuthorNames(response.valid_dois, name)
                    if (nameMatches.length) {
                        // If names to confirm
                        navigate(`/${path}/confirm-dois`, {state: {nameMatches, ids, validDois, affiliation}})
                    } else {
                        const currentDois = userStore.user.manual_dois || []
                        await updateUser({dois: [...currentDois, ...validDois],}, false)
                        return setView ? setView() : navigate(`/${path}/name-variants`, {state: {hasDois: true}})
                    }
                }
            } catch (err) {
                setDoiError(err.errors)
                setLoading(false)
            }
        } else {
            path === "account-setup" && !name && setNameError(nameErrorMessage)
            setDoiError(doiErrorMessage)
            setLoading(false)
        }

    }
    return (
        <Box className={"SetupContent"}>
            <Box className={"SetUpFrame"} sx={{scrollMarginTop: "4.5rem"}}>
                <PageIntro>
                    <h1 className={"IntroHeading"}>{"Find your research via it’s DOI or PubMed ID"}</h1>
                    <p className={"IntroCopy"}>
                        You can paste in a list of DOIs or PubMed IDs. Each DOI or PubMed ID should be entered on a
                        separate
                        line.
                    </p>
                </PageIntro>
                <InfoToolTip label={"What's a DOI/PubMed ID?"} ref={ref}>
                    <Box className={"InfoPopOver"}>
                        <Box className={"InfoPopOverCopy"}>
                            <p className={"InfoPopoverTitle"}>
                                What&apos;s a DOI?
                            </p>
                            <p className={"text-bodyMd"}>
                                A DOI (Digital Object Identifier) is a unique and never-changing string assigned to
                                online (journal) articles, books, and other works. DOIs make it easier to retrieve
                                works, which is why citation styles, like APA and MLA Style, recommend including
                                them in citations.
                            </p>
                            <p className={"InfoPopoverTitle"}>
                                What&apos;s a PubMed ID?
                            </p>
                            <p className={"text-bodyMd"}>
                                PubMed is an index of the biomedical literature. A PubMed ID or PMID, also known as
                                the PubMed reference number, is a number assigned by the NIH National Library of
                                Medicine to papers indexed in PubMed.
                            </p>
                            <Box className={"InfoPopOverActions"}>
                                <Button onClick={() => ref.current.closePopOver()}>Ok, got it</Button>
                            </Box>
                        </Box>
                    </Box>
                </InfoToolTip>
            </Box>
            <Box className={"SetUpFrame"}>
                <StackBox>
                    {path === "account-setup" &&
                        <>
                            <TextField
                                required
                                error={!!nameError}
                                label={"Your full name, as it appears in your articles"}
                                value={name}
                                placeholder={"eg. Elizabeth Smith"}
                                ref={nameInput}
                                onKeyUp={() => handleEnter(event, continueButtonRef)}
                                className={"StackStretch"}
                                helperText={nameError}
                                onChange={event => setName(event.target.value)}
                            />
                            <TextField
                                label={"Your current or most recent affiliation (optional)"}
                                value={affiliation}
                                placeholder={"eg. University of Edinburgh"}
                                onKeyUp={() => handleEnter(event, continueButtonRef)}
                                className={"StackStretch"}
                                helperText={"This will help us find mentions of you in policy"}
                                onChange={event => setAffiliation(event.target.value)}
                            />
                        </>
                    }
                    <TextField
                        required
                        error={!!doiError}
                        multiline
                        value={ids}
                        sx={{width: "100%"}}
                        variant={"outlined"}
                        minRows={2}
                        inputProps={{
                            style: {
                                padding: "8px 0px 16px 0px",
                            }
                        }}
                        placeholder={"eg '10.1080/09692290.2019.1657480' or 'https://doi.org/10.1007/s10597-016-0076-5'"}
                        label={"List of DOIs or PubMed IDs"}
                        onChange={event => setIds(event.target.value)}
                        helperText={doiError ? doiError : null}
                    />
                </StackBox>
                <Box className={"SetUpButtonContainer"}>
                    <Button className={"StackStretch"} ref={continueButtonRef} variant={"contained"}
                            onClick={lookupDois}>
                        <ButtonContent isLoading={loading} title={"Continue"}/>
                    </Button>
                    <Button className={"StackStretch"} variant={"outlined"}
                            onClick={setView ? () => setView(false) : () => navigate(-1)}>{view ? "Cancel" : "Back"}</Button>
                </Box>
            </Box>
        </Box>
    )
})

export default DoiLookup
