import React from "react"

import {useRef, useState} from "react";
import {Button} from "@mui/material";
import StackBox from "../../components/StackBox/StackBox"
import {useNavigate} from "react-router-dom";
import AuthService from "../../services/AuthService";
import { dispatchError } from "../../services/Notifications";
import EmailField from "./EmailField";
import ButtonContent from "../../components/ButtonContent";
import ExternalLink from "../../components/ExternalLink";
import {privacyPolicyLink, termsLink} from "../../utils/staticLinks";
import {useLocation} from "react-router";
import SocialButton from "../../components/SocialButton";
import {GoogleIcon, LinkedInIcon} from "../../components/CustomIcons/IconsSocial";

const EmailCollectionForm = ({emailInputRef, maxWidth}) => {
    const continueButtonRef = useRef(null)
    const location = useLocation();
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()


    const handleLogin = async () => {
        const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!validEmail.test(email)) {
            setEmailError(true)
            return
        } else {
            setLoading(true)
        }
        const userAccount = await AuthService.checkEmail(email)
        if (!userAccount.email_mapping) {
            // Register User
            try {
                await AuthService.register(email)
                navigate("/login/check-email", {state: {email, useCase: "verification"}})
            } catch (err) {
                dispatchError(err.errors.email)
            }
        } else if (!userAccount.has_verified_email) {
            try {
                await AuthService.sendMagicVerifyLink(email)
                navigate("/login/check-email", {state: {email, useCase: "verification"}})
            } catch (err) {
                dispatchError(err.errors.email)
            }
        } else {
            if (userAccount.has_password) {
                // Navigate to password collection
                navigate("/login/password-collection", {state: {email, forwardPath: location.state?.prevPath}})
            } else {
                // Send sign in link
                await AuthService.sendMagicLoginLink(email)
                navigate("/login/check-email", {state: {email}})
            }
        }

    }

    return (
        <StackBox sx={{maxWidth: maxWidth}}>
            <EmailField
                setEmail={setEmail}
                emailError={emailError}
                onEnterRef={continueButtonRef}
                emailInputRef={emailInputRef}
            />
            <Button className={"StackStretch"} onClick={handleLogin} variant={"contained"} ref={continueButtonRef}>
                <ButtonContent isLoading={loading} title={"Continue"}/>
            </Button>
            <span>or</span>
            <SocialButton to={"google"} className={"StackStretch"} label={"Continue with Google"} icon={<GoogleIcon/>}/>
            <SocialButton to={"linkedin"} className={"StackStretch"} label={"Continue with LinkedIn"} icon={<LinkedInIcon/>}/>
            <p className={"text-bodySm"}>By continuing, I agree to the Sage Policy
                Profiles <ExternalLink to={termsLink} className={"PrivacyLinks"}>Terms of use</ExternalLink>, and
                acknowledge the <ExternalLink to={privacyPolicyLink} className={"PrivacyLinks"}>Privacy
                    policy</ExternalLink>.
            </p>
        </StackBox>
    )
}

export default EmailCollectionForm
