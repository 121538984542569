import React, {forwardRef} from 'react';
import {
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer, Bar, ComposedChart
} from 'recharts';
import {Box} from "@mui/material";
import {sortByDate} from "../utils/utils";

const Chart = forwardRef(({data, showResearch, showCitations}, ref) => {

    const reduceByYear = (array, initialValue = []) => {
        return sortByDate(array, false, false, "year").reduce((accumulator, item) => {
            const year = item.year;
            const citations = item.citations;
            const research = item.research;
            if (!accumulator.find(obj => obj.year === year)) {
                accumulator.push({
                    year,
                    research,
                    citations
                });
            } else {
                accumulator.find(obj => obj.year === year).citations += citations;
                accumulator.find(obj => obj.year === year).research += research;
            }
            return accumulator;
        }, initialValue);
    };

    const chartLabelLeft = () => {
        return <div style={{transform: "rotate(-90deg)", position: "absolute", left: showResearch ? -30 : -45, top: "40%"}}
                    className={"text-bodyMd"}>{showResearch ? "Research" : "Policy citation"} count</div>
    }

    const chartLabelRight = () => {
        return <div style={{transform: "rotate(-90deg)", position: "absolute", right: -60, top: "40%"}}
                    className={"text-bodyMd"}>Policy citation Count
        </div>
    }
    return (
        <Box ref={ref} sx={{position: "relative", width: "100%", height: {xs: 250, sm: 300, lg: 400}}}>

            {chartLabelLeft()}
            {showResearch && chartLabelRight()}
            <ResponsiveContainer>
                <ComposedChart interval={0} margin={{top: 0, left: 0, right: 0, bottom: 0}} data={reduceByYear(data)}>
                    <XAxis stroke={"#0d0d0d"} dataKey="year"/>
                    <YAxis allowDecimals={false}
                           yAxisId={showResearch ? "right" : "left"} orientation={showResearch ? "right" : "left"}
                           allowDataOverflow
                           stroke={"#0d0d0d"}>
                    </YAxis>
                    {showResearch &&
                        <YAxis allowDecimals={false} yAxisId={"left"} allowDataOverflow
                               stroke={"#0d0d0d"}>
                        </YAxis>}

                    <Legend/>
                    <div>No results</div>
                    {showResearch && <Bar yAxisId={"left"} fill="#046FF8" name={"Research"} dataKey="research"/>}
                    {showCitations && showResearch ?
                        <Line yAxisId={"right"} type="monotone" name={"Policy citations"} dataKey="citations"
                              stroke="#001a69"/>
                        :
                        <Bar yAxisId={"left"} fill="#046FF8" name={"Policy citations"} dataKey="citations"/>
                    }
                    <Tooltip/>
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
});

Chart.displayName = 'Chart';
export default Chart;
