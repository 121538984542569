import React, {useEffect} from "react"
import {Helmet} from "react-helmet";
import {observer} from "mobx-react-lite";
import {Stack} from "@mui/material";
import PolicyCitationsList from "../components/PolicyCitationsList";
import userStore from "../stores/userStore";
import mentionsStore from "../stores/mentionsStore";

const PolicyCitations = observer(() => {

    useEffect(() => {
        return () => {
            if (userStore.updatedBlackList.length) {
                userStore.setUpdatedBlacklist([])
                mentionsStore.setMentions(mentionsStore.currentData.mentions)
            }
        }
    })

    return (
        <>
            <Helmet>
                <title>Policy citations</title>
            </Helmet>
            <Stack minHeight={"calc(100vh - 146px)"}>
                <PolicyCitationsList title={"Policy citations"} headingComponent={"h1"}/>
            </Stack>
        </>
    )
})

export default PolicyCitations
