import {dispatchError} from "../services/Notifications";
import HTTPService from "../services/HTTPService";
import dataStore from "../stores/dataStore";
import {validateOrcid} from "../utils/Validators";
import CustomError from "../utils/CustomError";
import citationsStore from "../stores/citationsStore";
import mentionsStore from "../stores/mentionsStore";

const emptyData = {
    articles: {},
    documents: {},
    citations: {},
    second_order_citations: {},
    articles_not_from_overton: {},
    mentions: {},
    metadata: {}
}

export const useFetchMentionData = () => {
    const fetchData = async (forceUpdate = false, signal = null) => {
        const response = await HTTPService.get('/spa/name-mentions/for-user/current', (err) => {
            if (err?.response?.status === 429) {
                dispatchError("Too many requests, waiting for data")
                setTimeout(fetchData, 30000)
                return
            }
            if (err?.response?.status === 504) {
                dispatchError(err.response.status + " gateway timeout: Couldn't fetch new data from the server. Please refresh the page to try again.")
                return
            }
            if (err?.response.status === 401) {
                dispatchError("Unauthenticated")
                return;
            }
            dispatchError(err.message)
        }, forceUpdate && {forceUpdate}, signal);
        if (response) {
            const data = response.data?.data || emptyData
            if (mentionsStore.initialised && mentionsStore.hasUpdatedMentionData(data)) {
                mentionsStore.setHasUpdatedData(data)
                return
            }
            mentionsStore.initialise(data)
            dataStore.setPolicyDocuments(data.documents)
        }
    }
    return fetchData
}


export const useFetchPublicMentionData = () => {
    const fetchPublicData = async (userId) => {
        const response = await HTTPService.get('/spa/public/name-mentions/for-user/' + userId, (err) => {
            if (err?.response?.status === 429) {
                dispatchError("Too many requests, waiting for data")
                setTimeout(fetchPublicData, 30000)
                return
            }
            dispatchError(err.message)
        });
        const data = response.data?.data || emptyData
        dataStore.setPolicyDocuments(data.documents)
        mentionsStore.initialise(data)
    }
    return fetchPublicData
}

export const useFetchCitationData = () => {

    const fetchData = async (forceUpdate = false, signal = null) => {
        const response = await HTTPService.get('/spa/citation-data/for-user/current', (err) => {
            if (err.response) {
                const status = err.response.status
                if (status === 429) {
                    dispatchError("Too many requests, waiting for data")
                    setTimeout(fetchData, 30000)
                    return
                }
                if (status === 504) {
                    dispatchError(err.response.status + " gateway timeout: Couldn't fetch new data from the server. Please refresh the page to try again.")
                    return
                }
                if (status === 401) {
                    dispatchError("Unauthenticated")
                    return;
                }
            }
            dispatchError(err.message)
        }, forceUpdate && {forceUpdate}, signal);

        if (response) {
            const data = response.data?.data || emptyData
            if (dataStore.initialised && citationsStore.initialised) {
                if (dataStore.hasUpdatedData(data)) {
                    dataStore.setHasUpdatedData(data)
                }
                if (citationsStore.hasUpdatedCitationData(data)) {
                    citationsStore.setHasUpdatedData(data)
                }
                return
            }
            dataStore.initialise(data)
            citationsStore.initialise(data)
        }
    }
    return fetchData
}

export const useFetchPublicCitationData = () => {
    const fetchPublicData = async (userId) => {
        const response = await HTTPService.get('/spa/public/citation-data/for-user/' + userId, (err) => {
            if (err?.response?.status === 429) {
                dispatchError("Too many requests, waiting for data")
                setTimeout(fetchPublicData, 30000)
                return
            }
            dispatchError(err.message)
        });
        const data = response.data?.data || emptyData
        dataStore.initialise(data)
        citationsStore.initialise(data)
    }
    return fetchPublicData
}

export const useOrcidAPI = () => {
    return async (orcid) => {
        const validOrcid = validateOrcid(orcid)
        const response = await HTTPService.post('/spa/orcid/details-by-id', {orcid: validOrcid})
        if (!response) {
            throw new CustomError("Sorry, we couldn't find this ORCID iD, please check it and try again")
        } else if (Object.keys(response.data).includes("success") && !response.data.success) {
            throw new CustomError(response.data.message)
        } else {
            return response.data
        }
    }
}

export const useValidateDois = () => {
    return async (dois) => {
        if (!dois.length) {
            throw new CustomError("Please enter at least one valid DOI or PubMed ID")
        }
        const response = await HTTPService.post('/spa/dois/validate-dois', {dois})
        return response.data
    }
}

export const clearData = () => {
    dataStore.initialise(emptyData, false)
    mentionsStore.initialise(emptyData, false)
    citationsStore.initialise(emptyData, false)
}

export const useFetchOpenAlexAuthors = () => {
    return async (name) => {
        if (!name) {
            throw new CustomError("Please enter your full name")
        }
        const response = await HTTPService.post('/spa/openalex/search-profiles', {search_term: name})
        return response.data.data
    }
}
export const useFetchOpenAlexArticles = () => {
    return async (authorId) => {
        if (!authorId) {
            throw new CustomError("Please select an author from the list to continue")
        }
        const response = await HTTPService.post('/spa/openalex/get-dois-for-id', {openalex_id: authorId})
        return response.data.dois
    }
}

