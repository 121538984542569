import React from "react"

import {useState, useRef} from "react";
import {Box, Button} from "@mui/material";
import StackBox from "../../components/StackBox/StackBox";
import PasswordField from "./PasswordField";
import AuthService from "../../services/AuthService";
import {observer} from "mobx-react-lite";
import {Navigate, useNavigate, useParams, useSearchParams} from "react-router-dom";
import PageIntro from "../../components/PageIntro/PageIntro";
import ButtonContent from "../../components/ButtonContent";
import {Helmet} from "react-helmet";
import {dispatchSuccess} from "../../services/Notifications";

const ResetPassword = observer(() => {
    const continueButtonRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [invalidToken, setInvalidToken] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const params = useParams();
    const email = searchParams.get("email")
    const token = params.token

    const handleChange = (e) => {
        setPasswordErrorMessage("")
        setPassword(e.target.value)
    }
    const handleUpdatePassword = async () => {
        setLoading(true)
        try {
            const response = await AuthService.resetPasswordWithToken(
                {
                    token,
                    email,
                    password
                })
            dispatchSuccess(response.data.message)
            navigate("/login")
        } catch (err) {
            setLoading(false)
            setPasswordErrorMessage(Object.values(err.errors).join(", "))
            if (err.errors.email) {
                setInvalidToken(true)
            }
        }
    }

    if (!email || !token) {
        return <Navigate to={"/"}/>
    }

    return (
        <>
            <Helmet>
                <title>Reset password</title>
            </Helmet>
            <Box className={"LoginContainer"}>
                <PageIntro>
                    <Box component={"h1"} className={"IntroHeading"}>Create a new password</Box>
                    <Box className={"IntroCopy"}>
                        <Box component={"p"}>
                            Your password should be at least 10 characters long
                        </Box>
                    </Box>
                </PageIntro>
                <StackBox>
                    <PasswordField
                        isRequired
                        value={password}
                        textFieldLabel={"New password (required)"}
                        onChange={handleChange}
                        onEnterRef={continueButtonRef}
                        passwordError={!!passwordErrorMessage}
                        passwordErrorMessage={passwordErrorMessage}/>
                    {!invalidToken ?
                        <Button className={"StackStretch"} variant={'contained'} onClick={handleUpdatePassword}
                                ref={continueButtonRef}>
                            <ButtonContent isLoading={loading} title={"Set password"}/>
                        </Button>
                        :
                        <Button className={"StackStretch"} variant={'outlined'}
                                onClick={() => navigate('/login/forgot-password')}>
                            <ButtonContent title={"Request a new reset link"}/>
                        </Button>
                    }
                </StackBox>
            </Box>
        </>
    )
})

export default ResetPassword
