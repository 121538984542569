import moment from "moment";
import dataStore from "../stores/dataStore";
import {action} from "mobx";
import citationsStore from "../stores/citationsStore";

export const sortByField = (items, descending, updateState, sortValue) => {
    const sortedItems = items.sort((a, b) => a[sortValue].toLowerCase().localeCompare(b[sortValue].toLowerCase()))
    if (descending) {
        sortedItems.reverse()
    }
    if (updateState) {
        updateState([...sortedItems])
    }
    return sortedItems
}
export const sortByDate = (items, descending = true, updateState, date, field) => {
    const sortedItems = items.sort((a, b) => {
        const dateA = new Date(a[date]);
        const dateB = new Date(b[date]);
        if (dateA.getTime() === dateB.getTime()) {
            if (a[field] && b[field]) {
                return a[field].toLowerCase().localeCompare(b[field].toLowerCase());
            }
        }
        return dateA < dateB ? (descending ? 1 : -1) : (descending ? -1 : 1)
    })
    if (updateState) {
        updateState([...sortedItems])
    }
    return sortedItems
};

export const sortByNumber = (items, descending, updateState, sortValue) => {
    const sortedItems = items.sort((a, b) => {
        return a[sortValue] - b[sortValue]
    })
    if (descending) {
        sortedItems.reverse()
    }
    if (updateState) {
        updateState([...sortedItems])
    }
    return sortedItems
}

export const filterMapByKeyValue = (map, field, id) => {
    return new Map([...map].filter(([, value]) => {
                return value.some(v => v[field] === id)
            }
        )
    )
}


//Date functions

export const getCurrentYear = () => new Date().getFullYear().toString();

export const formattedDate = (date, type) => {
    if (type === "year") {
        return moment(date).format(`Y`)
    }
    return moment(date).format(`MMMM Do Y`)

}

export const getYearFromString = (dateString) => {
    return dateString.split('-')[0];
}

//adds empty objects to a data array in order to pad out the chart display with all years between the min citation/research date and the current year
const addMissingYears = (data) => {
    if (!data.length) {
        return data
    }
    const currentYear = getCurrentYear();
    const firstYear = data.reduce((prev, curr) => prev.year < curr.year ? prev : curr).year;
    for (let year = firstYear; year <= currentYear; year++) {
        const yearExists = data.some(obj => obj.year === year.toString());
        if (!yearExists) {
            data.push({
                year: year.toString(),
                research: 0,
                citations: 0,
            });
        }
    }
    return data
}

// takes a single research document and returns an array of objects with year, citation, research and location data to
// use in the DataDisplay component
export const getDataFromResearchDoc = (researchData, citationData) => {
    const data = []
    const year = getYearFromString(researchData.published_on)
    const dataYear = data.find(d => d.year === year)
    if (dataYear) {
        dataYear.research += 1
    } else {
        data.push({
            year: year,
            citations: 0,
            research: 1,
            country: null,
        })
    }
    citationData.forEach(citation => {
        const document = dataStore.getDocument(citation.policy_document_id)
        const year = getYearFromString(document.published_on)
        const dataYear = data.find(d => d.year === year)
        if (dataYear && dataYear.country === document.source.country) {
            dataYear.citations += 1
        } else {
            data.push({
                year: year,
                citations: 1,
                research: 0,
                country: document.source.country
            })
        }
    })
    return addMissingYears(sortByDate(data, false, false, "year"))
}

// take a Map of documents and returns an array of objects with year, citation, research and location data to use in
// the DataDisplay component

export const getYearDataFromResearchAndCitations = (researchData, citationData) => {
    const data = []
    researchData.forEach(r => {
        const year = getYearFromString(r.published_on)
        const dataYear = data.find(d => d.year === year)
        if (dataYear) {
            dataYear.research += 1
        } else {
            data.push({
                year: year,
                citations: 0,
                research: 1,
                country: null,
            })
        }
    })
    citationData.forEach(citations => {
        citations.forEach(citation => {
            const document = dataStore.getDocument(citation.policy_document_id)
            const year = getYearFromString(document.published_on)
            const dataYear = data.find(d => d.year === year)
            if (dataYear && dataYear.country === document.source.country) {
                dataYear.citations += 1
            } else {
                data.push({
                    year: year,
                    citations: 1,
                    research: 0,
                    country: document.source.country
                })
            }
        })
    })
    return sortByDate(addMissingYears(data), false, false, "year")
}

// Handle enter key click through
export const handleEnter = (event, buttonRef) => {
    if (event.key === "Enter") {
        buttonRef.current.click()
    }
}

export const groupBy = async (array, key) => {
    return array.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const getCitationsCount = (citations) => {
    return citations && [...citations.values()].reduce((sum, value) => sum + value.length, 0);
}

export const typeLabel = (value) => {

    if (value === "citation") {
        return "Policy citations"
    } else if (value === "mention") {
        return "Name mentions"
    } else  {
        return "Type"
    }
}

export const setFilters = (value, key, setFilteredData) => {
    const filters = dataStore.filters;
    const filter = filters.find(aF => aF.key === key)
    if (!value) {
        action(() => filter.value = null)();
    } else {
        action(() => filter.value = value)();
    }
    dataStore.setFilters(filters)
    return setFilteredData(filters)
}

export const sortByMostCited = (data, descending, updateState) => {
    data.forEach(d => {
        d.citation_count = citationsStore.citations.get(d.doi)?.length || 0
    })
    return sortByNumber(data, descending, updateState, "citation_count")
}

export const sortData = (data, descending, updateState, type) => {
    switch (type) {
        case "date": return sortByDate(data, descending, updateState, "published_on", "title");
        case "title": return sortByField(data, descending, updateState, "title")
        case "citations": return sortByMostCited(data, descending, updateState, "citation_count")
    }
}
