import React from "react"

import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom"
import AuthService from "../../services/AuthService";

const MagicLinkButton = ({email, setEmailError}) => {
    const navigate = useNavigate()

    const getSignInLink = async () => {
        try {
            await AuthService.sendMagicLoginLink(email)
            navigate("/login/check-email", {state: {email: email}})
        } catch (err) {
            if (setEmailError){
                setEmailError(true)
            }
        }
    }

    return <Button className={"StackStretch"} variant={"outlined"} onClick={getSignInLink}>Email me a log in link</Button>
}

export default MagicLinkButton
