import React from "react"
import {useState, useRef} from "react";
import {Box, Button} from "@mui/material";
import StackBox from "../../components/StackBox/StackBox";
import {Link, useLocation, useNavigate} from "react-router-dom"
import PasswordField from "./PasswordField";
import AuthService from "../../services/AuthService";
import MagicLinkButton from "./MagicLinkButton";
import PageIntro from "../../components/PageIntro/PageIntro";
import ButtonContent from "../../components/ButtonContent";

const PasswordCollection = () => {
    const continueButtonRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
    const navigate = useNavigate()
    const location = useLocation()

    const handleChange = (e) => {
        setPasswordErrorMessage("")
        setPassword(e.target.value)
    }

    const handlePasswordInput = async () => {
        setLoading(true)
        try {
            await AuthService.loginWithPassword(location.state.email, password)
            navigate(location.state.forwardPath ? location.state.forwardPath : "/dashboard")
        } catch (err) {
            setLoading(false)
            setPasswordErrorMessage(err.errors.email)
            setPasswordError(true)
        }
    }

    return (
        <Box className={"LoginContainer"}>
            <PageIntro>
                <Box component={"h1"} className={"IntroHeading"}>Welcome Back!</Box>
                <Box component={"p"} className={"IntroCopy"}>
                        Please enter your password to continue
                </Box>
            </PageIntro>
            <StackBox >
                <PasswordField
                    isRequired={true}
                    textFieldLabel={"Password"}
                    onChange={handleChange}
                    value={password}
                    onEnterRef={continueButtonRef}
                    passwordError={passwordError}
                    passwordErrorMessage={passwordErrorMessage}/>
                <Button variant={'contained'} onClick={handlePasswordInput} ref={continueButtonRef} className={"StackStretch"}>
                    <ButtonContent isLoading={loading} title={"Continue"}/>
                </Button>
                <Box className={"text-bodySm StackStretch"}><Link to={"/login/forgot-password"} style={{color: "inherit"}}>Forgot your password?</Link></Box>
                <Box sx={{textAlign: "center"}} className={"text-labelLg"}>or</Box>
                <MagicLinkButton email={location?.state?.email} />
            </StackBox>
        </Box>
    )
}

export default PasswordCollection
