import {experimental_extendTheme as extendTheme} from "@mui/material";


const theme = extendTheme({
    cssVarPrefix: "",
    typography: {
        fontFamily: "Sage Peak, Open Sans, sans-serif",
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1440
        },
    },
    palette: {
        mode: "light",
        primary: {
            main: "#001A69"
        },
        secondary: {
            main: "#046FF8"
        },
        common: {
            onBackground: "#44464F",
            light: "rgba(0,0,0, 0.05)",
            white: "#fff"
        },
        sageNavy: {
            main: "#001A69"
        },
        sageNavyTint: {
            main: "#001F83"
        },
        sageAccent: {
            main: "#046FF8"
        },
        sagePale: {
            main: "#D9EEFF"
        },
        sageHighlightTeal: {
            main: "#08C9C4"
        },
        sageHighlightBlue: {
            main: "#08C9C4"
        }
    },
    components: {
        MuiScopedCssBaseline: {
            styleOverrides: {
                root: {
                    fontFamily: "Sage Peak, Open Sans, sans-serif",
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                color: "primary"
            }
        },
        MuiMenu: {
            defaultProps: {
                elevation: 2
            }
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: {
                    shrink: true
                },
            }
        },
        MuiCheckbox: {
            defaultProps: {
                color: "secondary"
            }
        },
        MuiRadio: {
            defaultProps: {
                color: "secondary"
            }
        },
        MuiSwitch: {
            defaultProps: {
                color: "secondary"
            }
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            }
        }
    }
})

export default theme
