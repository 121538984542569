import React, {useState} from "react";
import {
    Box,
    Button,
    Chip, FormControlLabel,
    IconButton,
    ListItem,
    ListItemIcon,
    Menu,
    MenuItem, Radio, RadioGroup,
    Stack
} from "@mui/material";
import "./PolicyListItem.scss"
import Typography from "@mui/material/Typography";
import {Add, Block, ExpandLess, Launch, MoreHoriz, TranslateOutlined} from "@mui/icons-material";
import ResearchCard from "../ResearchCard/ResearchCard";
import {filterMapByKeyValue, formattedDate, setFilters, typeLabel} from "../../utils/utils";
import PDFPageLink from "../PDFPageLink";
import defaultThumbnail from "../../assets/default_thumbnail.png"
import dataStore from "../../stores/dataStore";
import ExternalLink from "../ExternalLink";
import {Citation} from "../Citations/Citations";
import MentionCard from "../MentionCard/MentionCard";
import ConfirmationDialog from "../ConfirmationDialog";
import citationsStore from "../../stores/citationsStore";
import mentionsStore from "../../stores/mentionsStore";
import {useUpdateBlacklist} from "../../hooks/userHooks";
import {helpContactLink} from "../../utils/staticLinks";
import userStore from "../../stores/userStore";
import {observer} from "mobx-react-lite";


const PolicyListItem = observer(({document, type, setFilteredData}) => {
    const limit = 10
    const [count, setCount] = useState(0);
    const secondOrderCitations = citationsStore.getSecondOrderCitations(document.policy_document_id) ?? []

    const [showAll, setShowAll] = useState(false);
    const [secondOrderCitationsList, setSecondOrderCitationsList] = useState(secondOrderCitations.slice(0, count))

    const citedResearch = [...filterMapByKeyValue(citationsStore.citations, "policy_document_id", document.policy_document_id)]
    const slicedResearch = citedResearch && !showAll ? citedResearch.slice(0, 3) : citedResearch
    const nameMentions = [...filterMapByKeyValue(mentionsStore.mentions, "policy_document_id", document.policy_document_id)]
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [showIssueModal, setShowIssueModal] = useState(false)
    const [radioValue, setRadioValue] = useState("");
    const updateBlacklist = useUpdateBlacklist();
    const isDeleted = userStore.updatedBlackList.find(li => li.policy_document_id === document.policy_document_id)

    const handleIssueMenuClick = (event) => setAnchorEl(event.currentTarget);

    const handleReportIssue = () => {
        setShowIssueModal(true)
        handleClose()
    }

    const handleClose = () => {
        setAnchorEl(null);
        setRadioValue("")
    };

    const deleteItem = async () => {
        await updateBlacklist(document.policy_document_id)
        setRadioValue("")
        setShowIssueModal(false)
    }

    const undoDelete = async () => {
        await updateBlacklist(document.policy_document_id)
    }

    const showAllButton = () => {
        return citedResearch.length > 3 && <Button onClick={() => setShowAll(!showAll)}
                                                   sx={{alignSelf: "flex-start"}} variant={"outlined"}
                                                   endIcon={<Add/>}>
            {!showAll && `Show all ${citedResearch.length} cited research documents`}
            {showAll && `Show less`}
        </Button>
    }

    const showMore = (value) => {
        setSecondOrderCitationsList(secondOrderCitations.slice(0, value))
        setCount(value)
    }

    const renderMentionsOrCitations = () => {
        const isCitation = type === "citation"
        return (isCitation ? slicedResearch : nameMentions).map(([key, value]) => {
            const pageNumber = value.find(v => v.policy_document_id === document.policy_document_id).page
            return <Box key={key} className={"CitedResearchDoc"}>
                <span className={"text-labelLg"}>
                                {isCitation ? "Cited research" : "Mentioned"} on <PDFPageLink pageNumber={pageNumber}
                                                                                              document={document}/>
                            </span>
                {isCitation ?
                    <ResearchCard research={dataStore.getResearchDoc(key)} showCitedOn={"cited on"}/>
                    :
                    <>
                        <IconButton onClick={handleIssueMenuClick}
                                    sx={{position: "absolute", right: 0, top: -12}}><MoreHoriz/></IconButton>
                        <MentionCard mention={value[0]}/>
                    </>
                }
            </Box>
        })
    }

    const showUndo = () => {
        return <ListItem className={"PolicyListItem"} sx={{justifyContent: "space-between", alignItems: "flex-start"}}>
            <Stack>
                <h4 className={"text-bodyLg"}>We’ve hidden this name mention and unlinked it from your profile.</h4>
                <p className={"text-bodyMd"}> It will no longer show up in results or in name mentions count in the
                    dashboard summaries</p>
            </Stack>
            <Button variant={"outlined"} onClick={undoDelete}>Undo</Button>
        </ListItem>
    }

    if (isDeleted) {
        return showUndo()
    }

    return <ListItem className={"PolicyListItem"}>
        <ConfirmationDialog submitText={radioValue === "other" ? "Contact support" : "Submit"}
                            title={"Report an issue"} text={"Tell us what the issue is"}
                            submit={radioValue === "other" ? () => window.open(helpContactLink, "_blank") : deleteItem}
                            onClose={() => setShowIssueModal(false)} open={showIssueModal}>
            <br/>
            <RadioGroup value={radioValue} onChange={(e) => setRadioValue(e.target.value)}>
                {type === "mention" &&
                    <FormControlLabel value={"incorrect"} control={<Radio/>} label={"This name mention is not me."}/>}
                <FormControlLabel value={"other"} control={<Radio/>} label={"Other"}/>
            </RadioGroup>
            <br/>
        </ConfirmationDialog>
        <Menu id={"report-menu"}
              open={open}
              sx={{maxWidth: 320}}
              anchorEl={anchorEl}
              onClose={handleClose}
              MenuListProps={{'aria-labelledby': 'share-button'}}
        >
            <MenuItem onClick={handleReportIssue}>
                <ListItemIcon><Block fontSize={"small"}/></ListItemIcon>
                Report an issue
            </MenuItem>
        </Menu>
        {/* Only renders on desktop */}
        <Box className={"PolicyThumbnailContainer"}>
            <img className={"PolicyThumbnail"} alt="" src={document.thumbnail || defaultThumbnail}/>
            <Chip onClick={() => setFilters(type, "type", setFilteredData)} style={{maxWidth: "fit-content"}}
                  size={"small"} variant={"outlined"} label={typeLabel(type)}/>
        </Box>
        <Box className={"PolicyContent"}>
            {/* Only renders on mobile */}
            <Box className={"PolicyThumbnailContainer PolicyThumbnailContainer-mobile"}>
                <img className={"PolicyThumbnail"} alt="" src={document.thumbnail || defaultThumbnail}/>
                <Chip onClick={() => setFilters(type, "type", setFilteredData)} key={type}
                      style={{maxWidth: "fit-content"}} variant={"outlined"} size={"small"} label={typeLabel(type)}/>
            </Box>
            <Box flexDirection={"column"} display={"flex"} gap={1}>
                <ExternalLink className={"PolicyTitle"} to={document.document_url}>
                    <h3>{document.title} <Launch style={{fontSize: "1rem"}}/></h3>
                    {document.translated_title && <h3 className={"Translated"}>
                        <TranslateOutlined/> {document.translated_title}
                    </h3>}
                </ExternalLink>
                <span
                    className={"text-bodyMd"}>{document.authors?.join(', ')} on {formattedDate(document.published_on)}</span>
                <Typography display={{xs: "none", sm: "block"}}
                            className={"text-bodyMd"}>{document.snippet}</Typography>
                <Box className={"PolicyTopics"}>{document.topics?.slice(0, 3).map(t => (<Chip sx={{
                    height: "auto",
                    padding: "0.25rem 0",
                    alignSelf: "flex-start",
                    span: {whiteSpace: "break-spaces"}
                }}
                                                                                              key={t} size={"small"}
                                                                                              label={t}/>))}
                </Box>
            </Box>
        </Box>
        <Box className={"Research"}>
            {renderMentionsOrCitations()}
            {type === "citation" && showAllButton()}
        </Box>
        <Box flex={"1 1 100%"}>
            {secondOrderCitations.length > 0 && <h4 className={"text-labelLg"}>
                    <span>
                        This policy document has been cited
                        <span style={{fontWeight: 700}}> {secondOrderCitations.length} </span>
                        time{secondOrderCitations.length > 1 && "s"} by other policy document{secondOrderCitations.length > 1 && "s"}.
                    </span>
                <a style={{cursor: "pointer", marginLeft: 4}} onClick={() => showMore(count > 0 ? 0 : count + limit)}>
                    {count > 0 ? "Hide" : "Show"} second order citations
                </a>
            </h4>}
            {count > 0 &&
                <Box paddingTop={4} paddingLeft={4} flexBasis={"40%"} display={"flex"} flexDirection={"column"}
                     gap={2}>
                    {secondOrderCitationsList.map(c => <Citation citation={c}
                                                                 key={c.second_order_policy_document_id}
                                                                 citingDocument={dataStore.policyDocuments.get(c.second_order_policy_document_id)}/>)}
                    {count < secondOrderCitations.length &&
                        <Box>
                            <Button variant={"outlined"} sx={{alignSelf: "flex-start"}}
                                    onClick={() => showMore(count + limit)}
                                    startIcon={<Add/>}>Load more</Button>
                            <span className={"text-bodyMd"}
                                  style={{marginLeft: 8}}> showing {count} of {secondOrderCitations.length}</span>
                        </Box>}
                    <Button sx={{alignSelf: "flex-start"}}
                            onClick={() => showMore(0)}
                            startIcon={<ExpandLess/>}>Hide all second order citations</Button>

                </Box>
            }
        </Box>
    </ListItem>
})

export default PolicyListItem
