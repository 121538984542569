import CircularLoading from "./CircularLoading";
import React from "react";

const ButtonContent = ({isLoading, title}) => {
    if (isLoading) {
        return <CircularLoading />
    }
    return title
}

export default ButtonContent
