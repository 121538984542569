import React from 'react';
import { createSvgIcon } from '@mui/material';

const AppleIcon = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon">
      <path id="Vector" d="M9.73125 3.43125C9.97868 3.08921 10.2985 2.806 10.668 2.60181C11.0375 2.39762 11.4475 2.2775 11.8688 2.25C11.9055 2.63335 11.8646 3.02017 11.7487 3.38741C11.6327 3.75464 11.444 4.09476 11.1937 4.3875C10.9652 4.70578 10.6594 4.9606 10.3051 5.12791C9.95081 5.29522 9.55972 5.3695 9.16875 5.34375C9.12818 5.00349 9.15711 4.65854 9.2538 4.32979C9.35049 4.00105 9.51292 3.69536 9.73125 3.43125ZM6.91875 5.5125C5.34375 5.5125 3.65625 6.91875 3.65625 9.61875C3.65625 12.375 5.625 15.75 7.2 15.75C7.7625 15.75 8.60625 15.1875 9.45 15.1875C10.2938 15.1875 10.9125 15.6937 11.7 15.6937C13.4437 15.6937 14.6813 12.0938 14.6813 12.0938C14.1437 11.8618 13.6864 11.4767 13.3663 10.9866C13.0462 10.4964 12.8775 9.9229 12.8812 9.3375C12.8816 8.82437 13.0169 8.32036 13.2736 7.87606C13.5303 7.43176 13.8994 7.06281 14.3438 6.80625C14.0554 6.37384 13.6609 6.02266 13.198 5.7863C12.7351 5.54993 12.2194 5.43629 11.7 5.45625C10.575 5.45625 9.73125 6.075 9.28125 6.075C8.775 6.075 7.93125 5.5125 6.91875 5.5125Z" fill="black"/>
    </g>
  </svg>,
      "custom"
    );

const GoogleIcon = createSvgIcon(
  <svg width="18" height="18" viewBox='0 0 18 18' fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon">
      <path id="Vector" d="M13.5703 14.0625C15.1526 12.5859 15.8558 10.125 15.4339 7.76923H9.14062V10.3714H12.7266C12.5859 11.2151 12.0938 11.9182 11.3906 12.375L13.5703 14.0625Z" fill="#4285F4"/>
      <path id="Vector_2" d="M3.16406 11.9886C3.62657 12.8996 4.28985 13.6937 5.1039 14.3111C5.91795 14.9285 6.86154 15.353 7.86352 15.5527C8.86551 15.7524 9.89977 15.722 10.8883 15.4639C11.8769 15.2058 12.7939 14.7266 13.5703 14.0625L11.3906 12.375C9.52706 13.6057 6.43331 13.1484 5.37862 10.2656L3.16406 11.9886Z" fill="#34A853"/>
      <path id="Vector_3" d="M5.37901 10.2656C5.09776 9.38644 5.09776 8.57812 5.37901 7.69894L3.16389 5.97656C2.35501 7.59375 2.1092 9.87919 3.16389 11.9886L5.37901 10.2656Z" fill="#FBBC02"/>
      <path id="Vector_4" d="M5.37919 7.69949C6.15206 5.27342 9.45731 3.86717 11.6719 5.94111L13.6058 4.04323C10.8636 1.40623 5.51981 1.51198 3.16406 5.97599L5.37919 7.69893V7.69949Z" fill="#EA4335"/>
    </g>
  </svg>,
    "google"
  );

const LinkedInIcon = createSvgIcon(
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon">
      <path id="path14" d="M2.25 3.22317C2.25 2.68584 2.69664 2.24998 3.24762 2.24998H14.7524C15.3033 2.24998 15.75 2.68584 15.75 3.22317V14.8624C15.75 15.4 15.3033 15.8356 14.7524 15.8356H3.24762C2.69664 15.8356 2.25 15.4 2.25 14.8624V3.22317Z" fill="#0468C5"/>
      <path id="path28" fillRule="evenodd" clipRule="evenodd" d="M6.34233 13.6226V7.48794H4.30328V13.6226H6.34233ZM5.32279 6.65035C6.03385 6.65035 6.47643 6.17928 6.47643 5.59057C6.46318 4.98861 6.03386 4.53061 5.33628 4.53061C4.63878 4.53061 4.18274 4.98861 4.18274 5.59057C4.18274 6.17928 4.62522 6.65035 5.3095 6.65035H5.32279Z" fill="white"/>
      <path id="path30" fillRule="evenodd" clipRule="evenodd" d="M7.47058 13.6226H9.50962V10.1968C9.50962 10.0134 9.52287 9.83024 9.57671 9.69918C9.72411 9.33284 10.0596 8.95344 10.6229 8.95344C11.3608 8.95344 11.6559 9.51601 11.6559 10.3407V13.6226H13.6948V10.1051C13.6948 8.22078 12.6888 7.34398 11.3473 7.34398C10.2473 7.34398 9.76436 7.95883 9.49605 8.37762H9.50967V7.48794H7.47064C7.49739 8.06358 7.47058 13.6226 7.47058 13.6226Z" fill="white"/>
    </g>
  </svg>,
  "LinkedIn"
)

export {
    AppleIcon,
    GoogleIcon,
    LinkedInIcon
}