export const privacyPolicyLink = "https://us.sagepub.com/en-us/nam/privacy-policy"

export const sagePubLink = "https://sagepub.com/"

export const overtonLink = "https://www.overton.io/"

export const socialScienceSpaceLink = "https://www.socialsciencespace.com"

export const overtonHelpArticle = "https://help.overton.io/article/"

export const overtonDataLink = "https://overton.io/overtons-commitment-to-being-a-responsible-data-custodian"

export const helpContactLink = "https://www.socialsciencespace.com/sage-policy-profiles-feedback-and-assistance/"

export const orcidLink = "https://orcid.org/"

export const termsLink = "https://www.socialsciencespace.com/sage-policy-profiles-terms-and-conditions/"
export const openAlexLink = "https://openalex.org/"
