import React from "react"
import {NoteAltOutlined} from "@mui/icons-material";
import {Link} from "react-router-dom";

const SurveyCTA = () => {
    return <Link style={{display: "flex", alignItems: "center", zIndex: 1000, position: "fixed", bottom: 0, right: 0, padding: "0.5rem 1rem", background: "#f7f7f7", color: "#001A69"}} to={"https://www.surveymonkey.com/r/WJGK6ZM"} target={"_blank"}>
        Help us improve <NoteAltOutlined sx={{marginLeft: "0.25rem"}} fontSize={"small"} />
    </Link>
}

export default SurveyCTA
