import React from "react"
import {Box, FormControlLabel, Radio, Stack} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ExternalLink from "../ExternalLink";
import "./OpenAlexAuthor.scss"

const OpenAlexAuthor = ({author}) => {

    if (!author) {
        return
    }

    const label = (
        <Box className={"oaAuthorLabel"}>
            <Stack className={"oaAuthorDetails"}>
                <h2 className={"text-titleLg"}>{author.display_name}</h2>
                <div>{author.last_known_institution.display_name}</div>
                {author.display_name_alternative && <div className={"text-bodyMd"}>Also known as: {author.display_name_alternative?.join(', ')}</div>}
                {author.orcid && <ExternalLink to={"https://orcid.org/" + author.orcid} className={"oaAuthorOrcid"}>
                    <img style={{marginRight: 4}} alt="ORCID logo"
                         src="https://info.orcid.org/wp-content/uploads/2019/11/orcid_16x16.png"
                         width="16" height="16"/> {author.orcid} <LaunchIcon sx={{fontSize: "1rem"}}/>
                </ExternalLink>}
            </Stack>
            <Stack className={"oaAuthorArticleCount"}>
                <div className={"text-titleLg"}>{author.works_count}</div>
                <div>articles</div>
            </Stack>
        </Box>
    )

    return (
        <FormControlLabel className={"oaAuthor"} value={author.id} control={<Radio/>} label={label}/>
    )
}

export default OpenAlexAuthor
