import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ReactDOM from 'react-dom/client'
import {Experimental_CssVarsProvider as CssVarsProvider} from '@mui/material/styles';
import "./index.scss"
import theme from "./theme/theme"
import {ScopedCssBaseline, StyledEngineProvider} from "@mui/material";
import LandingPage from "./screens/LandingPage/LandingPage";
import Dashboard from "./screens/Dashboard/Dashboard";
import Research from "./screens/Research/Research";
import ResearchDocument from "./screens/ResearchDocument/ResearchDocument";
import PolicyCitations from "./screens/PolicyCitations";
import Alerts from "./screens/Alerts/Alerts";
import AboutTheData from "./screens/AboutData/AboutData";
import Setup from "./screens/SetUp/Setup";
import ErrorPage from "./screens/ErrorPage/ErrorPage";
import UserApp from "./templates/UserApp";
import CustomSnackbarProvider from "./components/CustomSnackbarProvider";
import PublicApp from "./templates/PublicApp";
import Login from "./screens/Login/Login";
import CheckEmail from "./screens/Login/CheckEmail";
import CreatePassword from "./screens/Login/CreatePassword";
import PasswordCollection from "./screens/Login/PasswordCollection";
import LoginEmailCollection from "./screens/Login/LoginEmailCollection";
import ForgotPassword from "./screens/Login/ForgotPassword";
import Redirect from "./components/Redirect";
import OrcidLookup from "./screens/SetUp/OrcidLookup";
import ConfirmOrcid from "./screens/SetUp/ConfirmOrcid";
import NameVariants from "./screens/SetUp/NameVariants";
import DoiLookup from "./screens/SetUp/DoiLookup";
import DoiNotFound from "./screens/SetUp/DoiNotFound";
import ConfirmDois from "./screens/SetUp/ConfirmDois";
import SetupInterstitial from "./components/SetupInterstitial";
import ResetPassword from "./screens/Login/ResetPassword";
import {Helmet} from "react-helmet";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import AccountSettings from "./screens/AccountSettings/AccountSettings";
import ConfirmOAProfile from "./screens/SetUp/ConfirmOAProfile";
import ConfirmArticles from "./screens/SetUp/ConfirmArticles";

Bugsnag.start({
    apiKey: 'c11ad3c99070d531d722e219f453ecd6',
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.PROD ? "production" : "development"
})

const routes = [
    {
        path: "/",
        element: <LandingPage/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "/dashboard-setup",
        element: <SetupInterstitial/>
    },
    {
        path: "/login",
        element: <Login/>,
        children: [
            {
                path: "",
                element: <LoginEmailCollection/>
            },
            {
                path: "check-email",
                element: <CheckEmail/>
            },
            {
                path: "create-password",
                element: <CreatePassword/>
            },
            {
                path: "password-collection",
                element: <PasswordCollection/>
            },
            {
                path: "forgot-password",
                element: <ForgotPassword/>
            },
        ]
    },
    {
        element: <PublicApp/>,
        children: [
            {
                path: "/profile/:userId/*",
                element: <Dashboard view={"public"}/>
            }
        ]
    },
    {
        // the main user account paths are wrapped in the User App container, which provides the standard layout header/sidebar
        element: <UserApp/>,
        children: [
            {
                path: "dashboard",
                element: <Dashboard/>,
            },
            {
                path: "about-the-data",
                element: <AboutTheData/>
            },
            {
                path: "account-settings",
                element: <AccountSettings/>
            },
            {
                path: "alerts",
                element: <Alerts/>
            },
            {
                path: "policy-citations",
                element: <PolicyCitations/>
            },
            {
                path: "research",
                element: <Research/>,
                children: [
                    {
                        path: "doi-lookup",
                        element: <DoiLookup/>
                    },
                    {
                        path: "doi-not-found",
                        element: <DoiNotFound/>
                    },
                    {
                        path: "confirm-dois",
                        element: <ConfirmDois/>
                    }
                ]
            },
            {
                path: "research/:documentId",
                element: <ResearchDocument/>
            },
        ]
    },
    {
        // the onboarding process. If the user has an onboarding flag set to true, their account should auto navigate here until set to false
        path: "account-setup",
        element: <Setup/>,
        children: [
            {
                path: "confirm-orcid",
                element: <ConfirmOrcid/>
            },
            {
                path: "confirm-profile",
                element: <ConfirmOAProfile/>
            },{
                path: "confirm-articles",
                element: <ConfirmArticles/>
            },
            {
                path: "doi-lookup",
                element: <DoiLookup/>
            },
            {
                path: "doi-not-found",
                element: <DoiNotFound/>
            },
            {
                path: "name-variants",
                element: <NameVariants/>
            },
            {
                path: "confirm-dois",
                element: <ConfirmDois/>
            },
            {
                path: "orcid-lookup",
                element: <OrcidLookup/>
            }
        ]
    },
    {
        path: "/auth/",
        element: <Login/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "post-magic-link-login",
                element: <Redirect condition={"success"} value={"true"} conditionalTo={"/dashboard-setup"} to={"/"}/>,
            },
            {
                path: "post-verification",
                element: <Redirect condition={"success"} value={"true"}
                                   conditionalTo={"/login/create-password"} to={"/"}/>,
            },
            {
                path: "post-social-login",
                element: <Redirect condition={"success"} value={"true"}
                                   conditionalTo={"/dashboard-setup"} to={"/"}/>,
            },
            {
                path: "reset-password/:token",
                element: <ResetPassword/>
            },
        ]
    }
]

const router = createBrowserRouter(routes)

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

const App = () => {
    return <>
        <Helmet defaultTitle={"Sage Policy Profiles"} titleTemplate={"%s - Sage Policy Profiles"}>
            <meta name={"theme-color"} content={"#001A69"} data-react-helmet={true}/>
        </Helmet>
        <CookieBanner/>
        <RouterProvider router={router}/>
    </>
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <>
        <ErrorBoundary>
            <CustomSnackbarProvider/>
            <StyledEngineProvider injectFirst>
                <ScopedCssBaseline>
                    <CssVarsProvider theme={theme}>
                        <App/>
                    </CssVarsProvider>
                </ScopedCssBaseline>
            </StyledEngineProvider>
        </ErrorBoundary>
    </>
);
