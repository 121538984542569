import CustomError from "../utils/CustomError";
import HTTPService from "./HTTPService";
import userStore from "../stores/userStore";
import {clearData} from "../hooks/dataHooks";

const PREFIX = '/spa/auth/';

class AuthService {
    static async checkEmail(email) {
        const response = await HTTPService.postForm(PREFIX + 'check-email', {email})
        if (response.data.success === false) {
            throw new CustomError(response.data.errors)
        }
        return response.data
    }

    static async register(email) {
        const response = await HTTPService.postForm(PREFIX + "register", {email})
        if (response.data.success === false) {
            throw new CustomError(response.data.errors)
        }
        return response.data
    }

    static async sendMagicVerifyLink(email) {
        const response = await HTTPService.post(PREFIX + 'send-magic-verify-link', {email})
        if (response.data.success === false) {
            throw new CustomError(response.data.errors)
        }
        return response.data.data
    }

    static async sendMagicLoginLink(email) {
        const response = await HTTPService.post(PREFIX + 'send-magic-login-link', {email})
        if (response.data.success === false) {
            throw new CustomError(response.data.errors)
        }
        return response.data
    }

    static async loginWithPassword(email, password) {
        if (password === '') {
            throw new CustomError({email: "Please enter your password"})
        }
        const response = await HTTPService.postForm(PREFIX + 'login', {email, password})
        if (response.data.success === false) {
            throw new CustomError(response.data.errors)
        }
        userStore.setUser(response.data.data)
        return response.data.data
    }

    static async logOut() {
        await HTTPService.get(PREFIX + 'logout')
        clearData()
        userStore.setUser(null)
    }

    // create a password for a user that's only authd via the link
    static async setPassword({email, password, password_confirmation, old_password}) {
        const errors = {}
        if (password.length < 10) {
            errors.password = "Your password should be at least 10 characters long"
        }
        if (password_confirmation !== password) {
            errors.confirmPassword = "Password confirmation doesn't match."
        }
        if (Object.keys(errors).length > 0) {
            throw new CustomError(errors)
        }
        const response = await HTTPService.post(PREFIX + 'set-password', {email, password, password_confirmation, old_password}, (err) => {
            throw err.response.data
        })
        if (response.data.success === false) {
            throw new CustomError(response.data.errors)
        }
    }

    static async resetPasswordRequest(email) {
        const response = await HTTPService.post(PREFIX + 'forgot-password', {email}, (err) => {
            throw err.response.data
        })
        if (response.data.success === false) {
            throw new CustomError(response.data.errors)
        }
        return response
    }

    static async resetPasswordWithToken({token, email, password}) {
        const errors = {}
        if (password.length < 10) {
            errors.password = "Your password should be at least 10 characters long"
        }
        if (Object.keys(errors).length > 0) {
            throw new CustomError(errors)
        }
        const response = await HTTPService.post(PREFIX + 'reset-password', {
            token,
            email,
            password,
            password_confirmation: password
        }, (err) => {
            throw err.response.data
        })
        return response
    }

}

export default AuthService
