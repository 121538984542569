import React from "react"
import Button from '@mui/material/Button';

const SocialButton = ({label, icon, className = "", to}) => (
  <Button component={"a"} href={`${window.location.origin}/login/${to}`} variant="outlined" startIcon={icon} className={'iconButton ' + className}>
    <div className='text-labelLg'>{label}</div>
  </Button>
)

export default SocialButton
