import React from 'react';
import { Box } from '@mui/material';
import "./DoiListItem.scss";
import {Check, Close} from "@mui/icons-material";

const DoiListItem = ({doi, found}) => (
    <Box className={"DoiListItem"}>
        {found ? <Check color={"secondary"}/> : <Close color={"error"} />}
        <div className={"text-bodyLg"}>{doi}</div>
    </Box>
)


export default DoiListItem
