import React from "react"
import {Helmet} from "react-helmet";
import AppToolbar from "../../components/AppToolbar/AppToolbar";
import SummaryCard from "../../components/SummaryCard/SummaryCard";
import {Launch} from "@mui/icons-material";
import {Box} from "@mui/material";
import "./AboutData.scss";
import {overtonDataLink, overtonHelpArticle, overtonLink} from "../../utils/staticLinks";
import ExternalLink from "../../components/ExternalLink";
import FeatureBox from "../../components/FeatureBox";
import {Link} from "react-router-dom";
import SocialScienceSpaceBlock from "../../components/SocialScienceSpaceBlock/SocialScienceSpaceBlock";

const sssArticles = [
    {
        title: "Seeking policy impact? Show your evidence",
        snippet: "What are the best practices in demonstrating that your research has made an impact in the policy arena. How do you communicate impact outside the academy to colleagues, promotion boards or funders?",
        url: "https://www.socialsciencespace.com/show-impact",
        borderColor: ""
    },
    {
        title: "The personal benefits of policy impact",
        snippet: "From tangible career boosts to additional funding opportunities to genuine satisfaction in potentially having improved the human condition, these articles describe the benefits of having made an impact in the policy world.",
        url: "https://www.socialsciencespace.com/impact-benefits",
        borderColor: "#08C9C4"
    }
]
const AboutData = () => {
    return (
        <>
            <Helmet>
                <title>About the data</title>
            </Helmet>
            <AppToolbar title={"About the data"} allowExports={false}/>
            <Box minHeight={"calc(100vh - 210px)"} className={"AboutData"} component={"main"}>
                <Box>
                    <p className={"text-bodyLg"}>
                        Sage Policy Profiles is a collaboration between Sage and Overton, who supply the underlying
                        data. <ExternalLink to={overtonLink}>Find out more about Overton <Launch
                        fontSize={"xs"}/></ExternalLink>.
                    </p>
                </Box>
                <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} gap={"2rem"}>
                    <FeatureBox title={"What’s the definition of a policy document?"}>
                        <p className={"text-bodyMd"}>
                            There isn’t a broadly accepted, pragmatic definition of what a “policy
                            document” is. We define them very broadly as documents written primarily for or by
                            policymakers that are published by a policy focused source.
                        </p>
                        <p className={"text-bodyMd"}>
                            We collect policy documents from governments and official bodies but also from IGOs,
                            some
                            NGOs and think tanks.
                        </p>
                        <Link color={""} to={overtonHelpArticle + "/what-is-overtons-coverage-and-how-does-it-compare-to-other-systems/"} target={"_blank"}>
                            Find out more about Overton’s policy coverage<Launch fontSize={"inherit"}/>
                        </Link>
                    </FeatureBox>
                    <FeatureBox title={"How do we find policy citations?"}>
                        <p className={"text-bodyMd"}>
                            We scan the full text of each policy document we collect, and look for lines that
                            seem to
                            be references to scholarly articles. They might be in table captions, footnotes or in a
                            more
                            traditional bibliography.
                        </p>
                        <p className={"text-bodyMd"}>
                            As a rule the reference needs to have enough information for a human to be able to use
                            it to
                            quickly find that document.
                        </p>
                        <p className={"text-bodyMd"}>
                            Putting it plainly, if a human can&apos;t look at a reference and use the info there to
                            get a
                            paper (e.g. it&apos;s just &quot;Smith et al,&apos;s work from 1980&quot;), we
                            won&apos;t match it either.
                        </p>
                    </FeatureBox>
                </Box>
                <Box>
                    <h2 className={"text-headlineSm"}>Being a responsible data provider</h2>
                    <br/>
                    <p className={"text-bodyLg"}>When building Sage Policy Profiles we committed ourselves to following
                        three simple rules:</p>
                    <br/>
                    <Box display={"flex"} flexWrap={"wrap"} gap={4}>
                        <SummaryCard title={"1"}
                                     content={"We’ll be open about the scope of our data, and transparent about its limitations and what we’re doing to address them"}/>
                        <SummaryCard title={"2"}
                                     content={"We’ll support the principle that quantitative data should be used to support, evidence or challenge qualitative assessments, not replace them"}/>
                        <SummaryCard title={"3"}
                                     content={"We’ll work with the bibliometrics and research assessment communities to better understand the data we collect, and to develop research backed approaches to using it"}/>
                    </Box>
                    <p style={{marginTop: "2rem"}}>You can find out more at <ExternalLink to={overtonDataLink}>
                        overton.io/overtons-commitment-to-being-a-responsible-data-custodian <Launch
                        fontSize={"inherit"}/>
                    </ExternalLink>
                    </p>
                </Box>
                <br/>
            </Box>
            <SocialScienceSpaceBlock articles={sssArticles} location={"ABOUT"}/>
        </>
    )
}

export default AboutData
