import React from "react"
import {Box, Checkbox, FormControlLabel, Stack} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import ExternalLink from "../ExternalLink";
import "./OpenAlexArticle.scss"
import {getYearFromString} from "../../utils/utils";
import DOMPurify from 'dompurify';

const OpenAlexArticle = ({doi, article, onChange, checked}) => {

    if (!article) {
        return
    }

    const author = (article.authorships.find(a => a.author_position === "first").author.display_name ?? "") + ' et al.';
    const publicationYear = getYearFromString(article.publication_date) ?? "";
    const url = article.primary_location.landing_page_url ?? "";
    const title = DOMPurify.sanitize(article.title)

    const label = (
        <Box className={"oaArticleLabel"}>
            <Stack className={"oaArticleDetails"}>
                {/*<h2 className={"text-labelLg"}>{article.title}</h2>*/}
                <h2 className={"text-labelLg"} dangerouslySetInnerHTML={{__html: title}}/>
                <div className={"text-bodyMd"}>{author} ({publicationYear})</div>
                <ExternalLink to={url} className={"oaArticleDoi"}>
                    {doi} <LaunchIcon sx={{fontSize: "1rem"}}/>
                </ExternalLink>
            </Stack>
        </Box>
    )

    return (
        <FormControlLabel className={"oaArticle"} value={article.doi}
                          control={<Checkbox checked={checked} onChange={onChange}/>} label={label}/>
    )
}

export default OpenAlexArticle
