import React from "react";
import {Box} from "@mui/material";
import "./AppFooter.scss"
import {getCurrentYear} from "../../utils/utils";
import {helpContactLink, privacyPolicyLink, termsLink} from "../../utils/staticLinks";
import ExternalLink from "../ExternalLink";

const BasicFooter = ({showHelp}) => (
    <Box className={"BasicFooter"}>
        <Box className={"FooterMenu"}>
            <ExternalLink to={privacyPolicyLink} style={{color: "inherit", textDecoration: "none"}}><Box
                className={"text-bodySm"}>Privacy policy</Box>
            </ExternalLink>
            <ExternalLink to={termsLink} style={{color: "inherit", textDecoration: "none"}}><Box
                className={"text-bodySm"}>Terms of use</Box>
            </ExternalLink>
            {showHelp ? <ExternalLink to={helpContactLink} style={{color: "inherit", textDecoration: "none"}}><Box
                className={"text-bodySm"}>Help</Box>
            </ExternalLink> : null}
        </Box>
        <Box className={"text-bodySm"}>© {getCurrentYear()} Sage Publications</Box>
    </Box>
)

export default BasicFooter
