import React, {useState} from "react"
import {Box, Button} from "@mui/material"
import PageIntro from "../../components/PageIntro/PageIntro"
import DoiListItem from "../../components/DoiListItem/DoiListItem"
import {useNavigate, useLocation} from "react-router"
import MatchAuthorNames from "../../utils/MatchAuthorNames";
import ButtonContent from "../../components/ButtonContent";
import {Add} from "@mui/icons-material";
import userStore from "../../stores/userStore";
import {openAlexLink} from "../../utils/staticLinks";
import ExternalLink from "../../components/ExternalLink";
import {useUpdateUser} from "../../hooks/userHooks";

const DoiNotFound = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showAllValid, setShowAllValid] = useState(false);
    const updateUser = useUpdateUser();

    const validDois = Object.keys(location.state.response.valid_dois);
    const slicedDois = showAllValid ? validDois : validDois.slice(0, 5);
    const invalidDois = Object.keys(location.state.response.invalid_dois)

    // Get route path
    const path = location.pathname.split('/')[1]

    const handleContinue = async () => {
        setLoading(true)
        const nameMatches = MatchAuthorNames(location.state.response.valid_dois, location.state.name)
        if (nameMatches.length) {
            // If names to confirm
            navigate(`/${path}/confirm-dois`, {state: {nameMatches, ids: location.state.ids, validDois}})
        } else {
            const currentDois = userStore.user.manual_dois || []
            await updateUser({dois: [...currentDois, ...validDois]})
            navigate("/dashboard-setup")
        }
    }

    return (
        <Box className={"SetupContent"}>
            <Box className={"SetUpFrame"}>
                <PageIntro>
                    <h1 className={"IntroHeading IntroError"}>We couldn&apos;t
                        find {validDois.length ? "some" : "any"} of those DOIs...</h1>
                    <br/>
                    <p className={"IntroCopy"}>
                        <span>{validDois.length ? "Some of t" : "T"}he DOI or PubMed IDs you’ve added couldn’t be found.</span>
                        <span> We use <ExternalLink to={openAlexLink}>OpenAlex</ExternalLink> to retrieve
                            information about DOIs, so if the research is very recent, it might not yet be indexed. Or,
                            there may be a typo.</span>
                    </p>
                </PageIntro>
            </Box>
            <Box className={"SetUpFrame"}>
                <Box className={"SetUpDoiResultsContainer"}>
                    <div className={"text-bodyMd"}>Not Found</div>
                    {invalidDois.map(doi => <DoiListItem doi={doi} found={false} key={doi}/>)}
                    {validDois.length > 0 && <>
                        <Box>Found</Box>
                        {slicedDois.map(doi => <DoiListItem doi={doi} found={true} key={doi}/>)}
                        {validDois.length > 5 && <Button startIcon={<Add/>} onClick={() => setShowAllValid(!showAllValid)}
                                variant={"text"}>{!showAllValid ? `${validDois.length - 5} more` : "Show less"}</Button>}
                    </>}
                </Box>
                <Box className={"SetUpButtonContainer"}>
                    {validDois.length > 0 && <Button className={"StackStretch"} variant={"contained"} onClick={handleContinue}>
                        <ButtonContent isLoading={loading} title={"Continue"}/>
                    </Button>}
                    <Button className={"StackStretch"} variant={"outlined"}
                            onClick={() => navigate(`/${path}/doi-lookup`, {state: location.state})}>Back</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default DoiNotFound
