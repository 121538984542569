import {makeAutoObservable} from "mobx"
import _, {isEqual} from "lodash";
import citationsStore from "./citationsStore";
import mentionsStore from "./mentionsStore";

const emptyFilters = [{key: "research_published_on", value: null}, {
    key: "policy_published_on", value: null
}, {key: "type", value: null}]

const createDataStore = (policyDocuments,
                         research,
                         initialised,
                         updateData,
                         filters,
                         sortValue,
                         currentData,
                         updatedData,
                         metaData) => {

    return makeAutoObservable({
        policyDocuments,
        research,
        initialised,
        updateData,
        filters,
        sortValue,
        currentData,
        updatedData,
        metaData,

        setHasUpdatedData(data) {
            this.updatedData = data;
            this.updateData = true
        },

        setMetaData(data) {
            this.metaData = data
        },

        setPolicyDocuments(policyDocuments, initialised = true) {
            // uses Map setting to handle duplicates set by Citations/Mentions calls
            if (initialised) {
                for (let [key, value] of Object.entries(policyDocuments)) {
                    this.policyDocuments.set(key, value)
                }
            } else {
                this.policyDocuments = new Map()
            }
        },

        getCitingDocuments() {
            const docs = []
            this.policyDocuments.forEach(d => {
                const hasCitations = citationsStore.citationsByPolicyId.size && citationsStore.citationsByPolicyId.has(d.policy_document_id)
                const hasMentions = mentionsStore.mentionsByPolicyId.size && mentionsStore.mentionsByPolicyId.has(d.policy_document_id)
                d.type = ["citation"]
                if (hasCitations) {
                    docs.push(d)
                }
                if (hasMentions) {
                    if (hasCitations) {
                        d.type = ["mention", "citation"]
                    } else {
                        d.type = ["mention"]
                    }
                    docs.push(d)
                }
            })
            return new Map(docs.map(d => [d.policy_document_id, d]))
        },

        setResearch(research, unCitedResearch) {
            const newResearch = research && Object.entries(research) || [];
            const newUncitedResearch = unCitedResearch && Object.entries(unCitedResearch) || [];

            this.research = new Map([...newResearch, ...newUncitedResearch])
        },

        getResearchDoc(id) {
            return this.research.get(id);
        },

        getDocument(id) {
            return this.policyDocuments.get(id);
        },

        hasUpdatedData(newData) {
            return !isEqual(_.size(newData.articles), _.size(this.currentData.articles));
        },

        setFilters(filters) {
            this.filters = filters;
        },

        setSortValue(type, descending) {
            this.sortValue = {type, descending}
        },

        getFilterValue(key) {
            return this.filters.find(f => f.key === key).value
        },

        initialiseFilters() {
            this.filters = emptyFilters;
        },

        initialise(data, initialised = true) {
            this.setResearch(data.articles, data.articles_not_in_overton)
            this.setPolicyDocuments(data.documents, initialised)
            this.setMetaData(data.metadata)
            this.currentData = data
            this.initialised = initialised;
            this.updateData = false;
        }

    })
}

const dataStore = createDataStore(new Map(), new Map(), false, false, emptyFilters, {type:"date", descending: true}, {}, {}, {})

export default dataStore
