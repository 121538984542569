import React from "react";
import {Helmet} from "react-helmet";
import AppHeader from "../../components/AppHeader/AppHeader";
import UpperFooter from "../../components/AppFooter/UpperFooter";
import BasicFooter from "../../components/AppFooter/BasicFooter";
import {Box, Button} from "@mui/material";
import {useNavigate} from 'react-router-dom';
import "./ErrorPage.scss";
import userStore from "../../stores/userStore";
import {observer} from "mobx-react-lite";

const ErrorPage = observer(() => {
    const navigate = useNavigate();

    const link = userStore.user ? "/dashboard" : "/"
    return (
        <>
            <Helmet>
                <title>Error - 404</title>
                <meta charSet={"utf-8"}/>
            </Helmet>
            <AppHeader onCTAClick={() => navigate(link)} variant={"dark"}/>
            <main role="main">
                <Box component={"section"} className={"ErrorPageHero"}>
                    <Box component={"h1"} className={"text-displayLg"}>Page not found</Box>
                    <Box component={"p"} className={"ErrorPageIntro text-headlineMd"}>
                        404
                    </Box>
                    <Box component={"p"} className={"ErrorPageIntro text-headlineMd"}>
                        Sorry, the page you are looking for cannot be found.
                    </Box>
                    <Box component={"p"} className={"ErrorPageIntro text-bodyLg"}>
                        The page you are trying to reach does not exist or has been moved.
                    </Box>
                    <Button onClick={() => navigate(link)} variant={"contained"}>
                        {userStore.user ? "Go to my profile" : "Go to the homepage"}
                    </Button>
                </Box>
            </main>
            <footer role="contentinfo">
                <UpperFooter/>
                <BasicFooter/>
            </footer>
        </>

    )
})
export default ErrorPage
