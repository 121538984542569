import {Launch} from "@mui/icons-material";
import React from "react";
import ExternalLink from "./ExternalLink";

const PDFPageLink = ({pageNumber, document}) => {
    const component = <span>page {pageNumber}</span>

    if (document.dont_show_pdf === true) {
        return component
    }

    return <ExternalLink to={document.pdf_url + "#page=" + pageNumber}>{component} <Launch fontSize={"inherit"}/></ExternalLink>
}

export default PDFPageLink
