import React from "react"

import EmailCollectionForm from "./EmailCollectionForm"
import {Box} from "@mui/material";

const LoginEmailCollection = () => {
    return (
        <Box className={"LoginContainer"}>
            <Box className={"text-headlineLg"}> Please input your email address to log in or sign up for an account.</Box>
            <EmailCollectionForm headerClass={"text-headlineLg"} maxWidth={"480px"}/>
        </Box>
    )
}

export default LoginEmailCollection
