import React from "react";
import {Card, CardContent} from "@mui/material";
import "./MentionCard.scss"
import Typography from "@mui/material/Typography";
import userStore from "../../stores/userStore";
import {observer} from "mobx-react-lite";

const MentionCard = observer(({mention}) => {

    const user = userStore.publicUser ?  userStore.publicUser : userStore.user;
    const variants = [...user.name_variants, user.name] || [];

    const getHighlightedText = (highlights, textToHighlight) => {
        const regexPattern = new RegExp(`(${highlights.join('|')})`, 'gi');
        const parts = textToHighlight.split(regexPattern);

        return parts.map(part => {
            const isHighlight = variants.some(highlight => part.toLowerCase() === highlight.toLowerCase());
            return isHighlight ? <i className={"MentionHighlight"} key={part}>{part}</i> : <span key={part}>{part}</span>;
        });
    }

    return (
        <Card elevation={0} className={"MentionCard"}>
            <CardContent sx={{padding:0, display: "flex", flexDirection: "column", gap: 1}}>
                <Typography component={"span"}
                            className={"text-bodyMd"}>{getHighlightedText(variants, mention.text)}</Typography>
            </CardContent>
        </Card>
    )
})

export default MentionCard
