import React, {useState, forwardRef, useImperativeHandle} from "react";
import { Box, Popover, Button } from "@mui/material"
import "./InfoToolTip.scss"
import {HelpOutline} from "@mui/icons-material";

const InfoToolTip = forwardRef((props, refs) => {
    const [open, setOpen] = useState(false)
    const [anchor, setAnchor] = useState(null)

    const handleClick = (event) => {
        setAnchor(event.target)
        setOpen(true)
    }

    const closePopOver = () => {
        setOpen(false)
    }

    useImperativeHandle(refs, () => {
        return {
            closePopOver
        }
    })

    return (
        <>
            <Button size={"small"} className={"buttonWrapper"} onClick={handleClick}>
                <Box className={"ToolTipCTA"}>
                    <HelpOutline fontSize={"inherit"}/>
                    <div className={"ToolTipLabel"}>{props.label}</div>
                </Box>
            </Button>
            <Popover
                open={open}
                anchorEl={anchor}
                onClose={closePopOver}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                {props.children}
            </Popover>
        </>
    )
})

InfoToolTip.displayName = "InfoToolTip"
export default InfoToolTip
