import React, {forwardRef, useMemo, useState} from "react";
import {ComposableMap, Geographies, Geography, Marker, ZoomableGroup} from "react-simple-maps";
import world from '../../assets/world.json'
import {geoRobinson} from "d3-geo-projection";
import {scaleLinear} from "d3-scale";
import "./Map.scss";
import {Tooltip} from "@mui/material";
import countries from "../../assets/countries.json"
import {getCitationsCount} from "../../utils/utils";
import citationsStore from "../../stores/citationsStore";

const width = 1200;
const height = 600;

const Map = forwardRef(({mapData}, ref) => {
        const [maxValue] = useState(5);

        const range = useMemo(
            () => {
                const count = getCitationsCount(citationsStore.citations) + getCitationsCount(citationsStore.secondOrderCitations)

                if (count <= 100) {
                    return [5, 15]
                }
                if (count <= 500) {
                    return [5, 10]
                }
                if (count <= 1000) {
                    return [5, 8]
                }
                if (count <= 2500) {
                    return [3, 3.4]
                }
                if (count <= 5000) {
                    return [3, 3.25]
                }
                if (count <= 7000) {
                    return [3, 3.15]
                }
                if (count <= 8500) {
                    return [3, 3.1]
                } else {
                    return [3, 3.05]
                }
            }, []
        )

        const popScale = useMemo(
            () => scaleLinear().domain([0, maxValue]).range(range),
            [maxValue, range]
        );

        const customProjection = geoRobinson().translate([width / 2, height / 2]);

        const reduceByCountry = (array, initialValue = []) => {
            return array.reduce((accumulator, item) => {
                const country = item.country;
                const citations = item.citations;
                if (!accumulator.find(obj => obj.country === country)) {
                    accumulator.push({
                        country,
                        citations
                    });
                } else {
                    accumulator.find(obj => obj.country === country).citations += citations;
                }
                return accumulator;
            }, initialValue);
        };
        const countryMarker = () => {

            return reduceByCountry(mapData).map(entry => {
                if (!entry.country) {
                    return
                }
                const country = countries.find(c => c.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                    === entry.country)
                return (
                    <Tooltip classes={{tooltip: "MarkerTooltip"}}
                             title={`${country.name}: ${entry.citations.toLocaleString()} policy citations`}
                             placement={"top"}
                             key={entry.country}>
                        <Marker className={"Marker"} coordinates={[country.longitude, country.latitude]}>
                            <circle stroke="#FFF" r={popScale(entry.citations)}/>
                            <span>{entry.citations}</span>
                        </Marker>
                    </Tooltip>
                );
            })
        }

        return (
            <div tabIndex={-1} ref={ref} className={"Map"}>
                <ComposableMap projectionConfig={{
                    scale: 200,
                    rotation: [-41, 0, 0],
                }}
                               width={1200}
                               height={600}
                               projection={customProjection}
                >
                    <ZoomableGroup minZoom={1.25} maxZoom={1.25} center={[5, 12]} zoom={1.25}>
                        <Geographies tabIndex={-1} geography={world}>
                            {({geographies}) =>
                                geographies.map((geo) => (
                                    <Geography style={{
                                        default: {outline: "none"},
                                        hover: {outline: "none"},
                                        pressed: {outline: "none"}
                                    }} tabIndex={-1} key={geo.rsmKey} geography={geo}/>
                                ))
                            }
                        </Geographies>
                        {countryMarker()}
                    </ZoomableGroup>
                </ComposableMap>
            </div>
        );
    })
;

Map.displayName = 'Map';
export default Map;
