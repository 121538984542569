import React, {forwardRef} from "react";
import {Chip, Menu} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Close} from "@mui/icons-material";

const FilterChip = forwardRef(({label, children, active, clearFilter}, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <Chip ref={ref}
              onClick={handleClick}
              icon={active ? <CheckIcon/> : <div/>}
              variant={active ? "filled" : "outlined"}
              color={active ? "secondary" : "primary"}
              deleteIcon={active ? <Close/> : <KeyboardArrowDownIcon/> }
              onDelete={active ? clearFilter : handleClick}
              clickable type={'filter'} label={label}/>
        <Menu onClick={handleClose}
              anchorEl={anchorEl}
              onClose={handleClose}
              open={open}>
            {children}
        </Menu>
    </>
})

FilterChip.displayName = 'FilterChip';
export default FilterChip
