import ToolbarItem from "./ToolbarItem";
import React, {useRef, useState} from "react";
import {MenuItem} from "@mui/material"
import {FileDownloadOutlined} from "@mui/icons-material";
import {CSVDownloadLink} from "./CSVExports";
import {PPTXGenerator} from "../utils/PPTXGenerator";

const ExportMenu = ({title, headers, exportToPng, getCSVData, exportToCSV = true, exportToPPT = true}) => {
    const csvDownload = useRef(null);
    const [data, setData] = useState([])
    const handleSetData = (event) => {
        setData(getCSVData())
        if (event.key === "Enter") {
            csvDownload.current.link.click()
        }
    }


    const getPPTdata = () => {
        PPTXGenerator(getCSVData())
    }

    return <ToolbarItem title={title} icon={<FileDownloadOutlined/>}>
        <MenuItem disabled sx={{color: "black"}} className={"text-titleSm"}>Export data with filters</MenuItem>
        {exportToCSV && <MenuItem onKeyDown={handleSetData}>
            <CSVDownloadLink data={data} onClick={handleSetData} ref={csvDownload} getCSVData={getCSVData}
                             headers={headers}/>
        </MenuItem>}
        {exportToPng && <MenuItem onClick={exportToPng}>Download as PNG image (.png)</MenuItem>}
        {exportToPPT && <MenuItem onClick={getPPTdata}>Download as PowerPoint (.pptx)</MenuItem>}
    </ToolbarItem>
}

export default ExportMenu
