import CustomError from "./CustomError";

const validateDoiPubMed = (input) => {
    input = input.trim()

    // Check if PubMed ID
    const validPubmed = /^[1-9]\d{0,7}$/
    if (validPubmed.test(input)){
        return input
    }

    // Check if DOI
    const acceptablePrefixes = [
        "doi",
        "doi:",
        "https://doi.org/",
        "http://doi.org/",
        "dx.doi.org/",
        "www.doi.org/",
        "https://dx.doi.org/",
        "http://dx.doi.org/",
        "https://www.doi.org/",
        "http://www.doi.org/"
    ]
    // Remove any prefix
    for (let prefix of acceptablePrefixes){
        if (input.startsWith(prefix)){
            input = input.replace(prefix, "")
            break
        }
    }

    const validDoiPattern = /^10\.\d{4,7}\//
    if (validDoiPattern.test(input)){
        // If valid, return doi without prefix
        return input
    }

    return false;
}

const validateOrcid = (input) => {
    const orcidPattern = /^(?:(?:https?:\/\/)?orcid\.org\/)?(\b[X0-9]{4}-[X0-9]{4}-[X0-9]{4}-[X0-9]{4}\b)$/i;
    if (!orcidPattern.test(input)) {
        throw new CustomError("Your ORCID iD should be made up of 16 numbers in the format: XXXX-XXXX-XXXX-XXXX")
    }
    return input.match(/\b[xX0-9]{4}-[xX0-9]{4}-[xX0-9]{4}-[xX0-9]{4}\b/)[0]
}

export {
    validateDoiPubMed,
    validateOrcid,
}
