import React from "react"
import {Box} from "@mui/material";
import SSSArticle from "../SSSArticle/SSSArticle";
import {socialScienceSpaceLink} from "../../utils/staticLinks";
import ExternalLink from "../ExternalLink";
import sssLogo from "../../assets/s3_rgb.svg";
import "./SocialScienceSpaceBlock.scss"

export const SocialScienceSpaceBlurb = () => {
    return <Box className={"socialScienceBlurbContainer"} >
        <Box component={"p"} className={"socialScienceBlurb"}>
            Sage Policy Profiles is part of the <b><ExternalLink className={"BodyLink"}
                                                                 to={socialScienceSpaceLink}>
            Social Science Space</ExternalLink></b> network, bringing social scientists together to
            explore, share and shape the big issues in social science, from funding to impact.
        </Box>
        <ExternalLink className={"BodyLink"}
                      to={socialScienceSpaceLink}><img width={120} src={sssLogo}
                                                       alt={""}/></ExternalLink>
    </Box>
}

const SocialScienceSpaceBlock = ({location, articles}) => {
    const className = location === "ABOUT" ? "socialScienceBlockContainer" : ""

    return <Box component={"section"} className={"LandingPageSection2 " + className} sx={{bgcolor: "sagePale.main"}}>
        <Box className={"Frame1"} sx={{alignItems: "flex-start"}}>
            <Box component={"h2"} className={"text-displaySm"} sx={{color: "sageNavy.main"}}>
                Need some help getting cited in policy?
            </Box>
            <Box sx={{display: "flex", flexFlow: "row wrap", gap: 6, marginTop: 6, marginBottom: 6}}>
                {articles.map((a, idx) => <SSSArticle key={idx} title={a.title} url={a.url} borderColor={a.borderColor} snippet={a.snippet}/>)}
                <SocialScienceSpaceBlurb/>
            </Box>
        </Box>
    </Box>
}

export default SocialScienceSpaceBlock
