import React, {useEffect, useState} from "react"
import "./CookieBanner.scss"
import {Box, Button} from "@mui/material";
import {privacyPolicyLink} from "../../utils/staticLinks";
import {useCookies} from 'react-cookie';
import TagManager from 'react-gtm-module'

const tagManagerArgs = () => {
    if (import.meta.env.PROD) {
        return {
            gtmId: "GTM-WD4NLRXB"
        }
    }
    return {
        gtmId: "GTM-WD4NLRXB",
        auth: "ANINtWGCQQq3Yd1T7mgVHg",
        preview: "env-4"
    }
}

const CookieBanner = () => {
    const [cookies, setCookie, removeCookie] = useCookies()
    const [showBanner, setShowBanner] = useState(false)

    useEffect(() => {
        const consentCookie = cookies['sage_consent']
        handleConsent(consentCookie)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookies])

    const removeGAcookies = () => {
        Object.keys(cookies).forEach(c => {
            if (c.startsWith("_ga")) {
                removeCookie(c, {domain: `${window.location.host}`})
            }
        })
    }
    const handleConsent = (cookie) => {
        if (cookie === undefined) {
            // if there's no consent cookie, we display the banner
            setShowBanner(true)
            return
        }
        if (cookie === false) {
            removeGAcookies()
        }
        if (cookie === true) {
            TagManager.initialize(tagManagerArgs())
        }
        setShowBanner(false)
    }

    const handleSetConsent = (consent) => {
        // set the consent cookie to true or false dependent on the user action

        setCookie('sage_consent', consent, {expires: new Date(Date.now() + 15778476000)})
        handleConsent(cookies['sage_consent'])
    }

    if (!showBanner) {
        return
    }

    return (
        <Box open={true} className={"CookieBannerOverlay"}>
            <Box className={"CookieBanner"}>
                <span style={{flex: "1 1 100%"}}>To enhance your experience of Sage Policy Profiles, we&apos;d like to store some cookies on your computer. For more information, please read our <a
                    href={privacyPolicyLink} rel={"noreferrer"} target={"_blank"}>privacy policy</a>.</span>
                <Box sx={{maxWidth: 400}} className={"SetUpButtonContainer"}>
                    <Button onClick={() => handleSetConsent(true)} autoFocus
                            variant={"contained"}>Accept all</Button>
                    <Button onClick={() => handleSetConsent(false)} variant={"outlined"}>Accept necessary only</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default CookieBanner
