import React, {useEffect, useState} from "react"
import {getYearFromString, sortData, typeLabel} from "../utils/utils";
import {autorun} from "mobx";
import dataStore from "../stores/dataStore";
import PolicyListItem from "./PolicyListItem/PolicyListItem";
import AppToolbar from "./AppToolbar/AppToolbar";
import CustomPagination from "./CustomPagination/CustomPagination";
import {Box, Button, Container, Divider, List, Stack} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import SkeletonItem from "./Skeleton";
import {getPolicyCSVData, policyExportHeaders} from "./CSVExports";
import userStore from "../stores/userStore";
import citationsStore from "../stores/citationsStore";
import mentionsStore from "../stores/mentionsStore";
import FeatureBox from "./FeatureBox";
import {SocialScienceSpaceBlurb} from "./SocialScienceSpaceBlock/SocialScienceSpaceBlock";
import SSSArticle from "./SSSArticle/SSSArticle";
import Typography from "@mui/material/Typography";

const PolicyCitationsList = observer(({title, headingComponent, maxItems = null}) => {
    const [policyDocuments, setPolicyDocuments] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(20);
    const navigate = useNavigate();
    const isPublic = userStore.isPublic;
    const initialised = mentionsStore.initialised && citationsStore.initialised && dataStore.initialised

    useEffect(() => {
        dataStore.initialiseFilters()
        dataStore.setSortValue("date", true)
        autorun(() => {
            setFilteredData(dataStore.filters, true)
        })
    }, [])

    const handleChangePage = (event, newPage) => {
        //sets the scroll position to maintain the position sticky of the filtertoolbar on re-render, when moving between "pages"
        window.scrollTo(0, isPublic ? 600 : 64);
        setPage(newPage);
    };

    const setFilteredData = (filters) => {
        let filteredPolicyDocs = [...dataStore.getCitingDocuments().values()]
        filters.forEach(filter => {
            if (filter.value) {
                filteredPolicyDocs = filteredPolicyDocs.filter(policyDoc => {
                    if (filter.key === "policy_published_on") {
                        const year = getYearFromString(policyDoc.published_on)
                        return policyDoc.published_on && year >= filter.value[0] && year <= filter.value[1]
                    } else if (filter.key === "research_published_on") {
                        const citations = citationsStore.citationsByPolicyId.get(policyDoc.policy_document_id)
                        return citations && Object.values(citations).some(c => {
                            const year = getYearFromString(dataStore.getResearchDoc(c.doi).published_on)
                            return year >= filter.value[0] && year <= filter.value[1]
                        })
                    } else if (filter.key === "type") {
                        if (filter.value === "citation") {
                            return citationsStore.citationsByPolicyId.has(policyDoc.policy_document_id)
                        } else if (filter.value === "mention") {
                            return mentionsStore.mentionsByPolicyId.has(policyDoc.policy_document_id)
                        }
                    }
                })
            }
        })
        sortData(filteredPolicyDocs, dataStore.sortValue.descending, setPolicyDocuments, dataStore.sortValue.type)
        setPage(0);
    }

    const dataType = () => {
        const filterType = dataStore.getFilterValue("type")
        return filterType ? typeLabel(filterType).toLowerCase() : "policy citations or mentions"
    }

    const renderEmpty = () => {
        return <Stack padding={{xs: 2, sm: 3}} alignItems={"flex-start"} gap={4}>
                <span
                    className={"text-bodyLg"}>We haven’t found any {dataType()} for {!isPublic ? "the research you’ve added" : "this researcher"}.</span>
            {!isPublic &&
                <Button component={RouterLink} to={"/about-the-data"} variant={"outlined"}>Where does the data come
                    from?</Button>}
            <Divider width={"100%"}/>
            <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} gap={4}>
                <FeatureBox title={"Putting this into context"}>
                    <Typography color={"sageNavy.main"} className={"text-bodyMd"}>
                        Very broadly, around 5 percent of journal articles end up being cited in policy. The exact
                        percentage varies a lot between disciplines: drama and quantum physics are cited less
                        frequently,
                        economics and public health far more often.</Typography>
                    <Typography color={"sageNavy.main"} className={"text-bodyMd"}>The age of the article also plays
                        a role. Although it&apos;s
                        certainly
                        possible for
                        new research to be quickly cited in policy, it usually takes three to five years for the
                        first
                        policy citations to appear.</Typography>
                </FeatureBox>
                <SSSArticle borderColor={"#08C9C4"} title={"Need some help getting cited in policy?"}
                            snippet={"Even the best ideas need help in moving from the journal to the world at large. Here are proven methods and first-person narratives from scholars who have taken their work over the policy finish line."}
                            url={"https://www.socialsciencespace.com/help-policy-citations"}
                />
            </Box>
            <SocialScienceSpaceBlurb/>
        </Stack>
    }

    const citationsAndMentionsCount = () => {
        let count = 0
        for (let doc of policyDocuments) {
            if (citationsStore.citationsByPolicyId.has(doc.policy_document_id) && dataStore.getFilterValue("type") !== "mention") {
                count +=1
            }
            if (mentionsStore.mentionsByPolicyId.has(doc.policy_document_id) && dataStore.getFilterValue("type") !== "citation") {
                count +=1
            }
        }
        return count
    }

    const renderCitations = () => {
        const count = citationsAndMentionsCount()
        const pagedItems = maxItems ? policyDocuments.slice(0, maxItems) : policyDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        const showPagination = (!maxItems || maxItems > 10) && count > 0;
        const listItems = []

        pagedItems.map(doc => {
            if (citationsStore.citationsByPolicyId.has(doc.policy_document_id) && dataStore.getFilterValue("type") !== "mention") {
                listItems.push(<PolicyListItem setFilteredData={setFilteredData}
                                               key={doc.policy_document_id + "_citation"} type={"citation"}
                                               document={doc}/>)
            }
            if (mentionsStore.mentionsByPolicyId.has(doc.policy_document_id) && dataStore.getFilterValue("type") !== "citation") {
                listItems.push(<PolicyListItem setFilteredData={setFilteredData}
                                               key={doc.policy_document_id + "_mention"} type={"mention"}
                                               document={doc}/>)
            }
        })

        return (
            <>
                {showPagination &&
                    <CustomPagination onPageChange={handleChangePage} rowsPerPage={rowsPerPage} page={page}
                                      count={count}/>}
                <List sx={{padding: {xs: 2, sm: 3}}}>{listItems}</List>
                {showPagination &&
                    <CustomPagination onPageChange={handleChangePage} rowsPerPage={rowsPerPage} page={page}
                                      count={count}/>
                }
            </>
        )
    }

    if (!initialised) {
        return <List sx={{padding: {xs: 2, sm: 3}}}>
            <SkeletonItem showImage/>
            <SkeletonItem showImage/>
            <SkeletonItem showImage/>
            <SkeletonItem showImage/>
        </List>
    }

    return (
        <>
            <AppToolbar
                infoTipContent={"We look in the full text of each policy document and look for lines that seem to be references to scholarly articles. They might be in table captions, footnotes or in a more traditional bibliography. As a rule the reference needs to have enough information for a human to be able to use it to quickly find that document. "}
                infoTipTitle={"Where do the policy citations come from?"}
                allowExports={policyDocuments.length > 0}
                getCSVData={() => getPolicyCSVData(policyDocuments)}
                exportHeaders={policyExportHeaders}
                setFilteredData={!maxItems && setFilteredData}
                setSortedData={!maxItems && setPolicyDocuments}
                headingComponent={headingComponent}
                title={title}/>
            {policyDocuments.length > 0 ? renderCitations() : renderEmpty()}
            {maxItems && policyDocuments.length > 5 && <Container maxWidth={false} sx={{paddingBottom: "2rem"}}>
                <Button onClick={() => navigate('/policy-citations')} variant={"contained"}>View all policy
                    citations</Button>
            </Container>}
        </>
    )
})


export default PolicyCitationsList
