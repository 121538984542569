import React from 'react';
import { Box } from "@mui/material"
import "./PageIntro.scss"

const PageIntro = ({children}) => (
    <Box className={"IntroContainer"}>
        {children}
    </Box>
)

export default PageIntro