import React from "react"
import {Box, ListItem, Skeleton, Stack} from "@mui/material";
import defaultThumbnail from "../assets/default_thumbnail.png";
import "../components/PolicyListItem/PolicyListItem.scss"

const SkeletonItem = ({showImage, type = "DOCUMENT"}) => {

    if (type === "DOCUMENT") {
        return (
            <ListItem className={"PolicyListItem"}>
                {showImage && <Box className={"PolicyThumbnailContainer"}>
                    <Skeleton><img className={"PolicyThumbnail"} alt="" src={defaultThumbnail}/></Skeleton>
                    <Skeleton height={16} width={70}/>
                </Box>}
                <Box className={"PolicyContent"} width={"100%"}>
                    {showImage && <Box className={"PolicyThumbnailContainer PolicyThumbnailContainer-mobile"}>
                        <Skeleton><img className={"PolicyThumbnail"} alt="" src={defaultThumbnail}/></Skeleton>
                        <Skeleton height={16}/>
                    </Box>}
                    <Box flexDirection={"column"} display={"flex"} flexGrow={1} gap={2}>
                        <Skeleton width={"100%"} height={32}/>
                        <Skeleton><span className={"text-bodyMd"}>Authors</span></Skeleton>
                        <Skeleton width={"100%"} height={16}/>
                        <Skeleton width={"100%"} height={16}/>
                        <Skeleton width={"60%"} height={16}/>
                    </Box>
                </Box>
            </ListItem>
        )
    }
    if (type === "ALERT") {
        return <Stack gap={2}>
            <Skeleton height={32} width={"100%"}/>
            <Skeleton height={16} width={"80%"}/>
        </Stack>
    }
    if (type === "SETTING") {
        return <Stack gap={2}>
            <Skeleton height={32} width={"100%"}/>
            <Skeleton height={16} width={"80%"}/>
        </Stack>
    }
}

export default SkeletonItem
