import React, {useRef} from "react";
import {Box, Button, Card, CardContent} from "@mui/material";
import "./SummaryCard.scss"
import InfoToolTip from "../InfoToolTip/InfoToolTip";

const SummaryCard = ({title, content, tip}) => {
    const tipRef = useRef();

    return (
        <Card className={"SummaryCard"}>
            <CardContent>
                <h2 style={{color: "inherit"}} className={"text-displayLg"}>{title}</h2>
                <p className={"text-bodyLg"}>
                    {content}
                    {tip && <InfoToolTip ref={tipRef}>
                        <Box className={"InfoPopOver"}>
                            <Box className={"InfoPopOverCopy"}>
                                <Box component={"p"} className={"text-bodyMd"}>
                                    {tip}
                                </Box>
                                <Box className={"InfoPopOverActions"}>
                                    <Button onClick={() => tipRef.current.closePopOver()}>Ok, got it</Button>
                                </Box>
                            </Box>
                        </Box>
                    </InfoToolTip>}
                    </p>
            </CardContent>
        </Card>
    )
}

export default SummaryCard;
