import {enqueueSnackbar} from "notistack";

const dispatchError = (message) => {
    enqueueSnackbar(message, {
        variant: 'error'
    })
}
const dispatchSuccess = (message) => {
    enqueueSnackbar(message, {
        variant: 'success'
    })
}
const dispatchInfo = (message) => {
    enqueueSnackbar(message, {
        variant: 'info'
    })
}

export {dispatchError, dispatchSuccess, dispatchInfo}
