import React from 'react'
import {Helmet} from "react-helmet";
import dataStore from "../../stores/dataStore";
import {observer} from "mobx-react-lite";
import "./Dashboard.scss"
import {Box, Chip, Container, Stack, useMediaQuery} from "@mui/material";
import userStore from "../../stores/userStore";
import PolicyCitationsList from "../../components/PolicyCitationsList";
import Summaries from "../../components/Summaries";
import theme from "../../theme/theme";

const Dashboard = observer(() => {
    const isPublic = userStore?.isPublic;
    const user = isPublic ? userStore.publicUser : userStore.user;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Helmet>
                <title>Overview</title>
            </Helmet>
            <Box className={isPublic ? "PublicDashboard" : ""}>
                {user?.name && <Container maxWidth={false}>
                    <Chip className={"NameChip"} variant={"outlined"} label={user?.name}/>
                </Container>
                }
                <Summaries user={user}/>
                {(dataStore.getCitingDocuments().size < 100 || isPublic) &&
                    <Stack minHeight={"calc(100vh - 178px)"}>
                        <PolicyCitationsList user={user} title={`${isMobile ? "P" : "Recent p"}olicy citations`} headingComponent={"h2"} maxItems={!isPublic && 5}/>
                    </Stack>
                }
            </Box>
        </>
    )
})

export default Dashboard
