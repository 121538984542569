import {makeAutoObservable} from "mobx"

const createUserStore = (user, loading, alerts, publicUser, isPublic, blacklist, updatedBlackList) => {
    return makeAutoObservable({
        user,
        alerts,
        loading,
        publicUser,
        isPublic,
        blacklist,
        updatedBlackList,

        setUser(user) {
            this.user = user
            this.loading = false
        },

        setIsPublic(value) {
            this.isPublic = value
        },

        setPublicUser(user) {
            this.publicUser = user
            this.loading = false
        },

        setUserAlerts(alerts) {
            const reducedAlerts = []
            alerts.forEach(a => reducedAlerts.push({
                    id: a.id.toString(),
                    alert_type: a.alert_type,
                    frequency: a.frequency,
                    send_if_empty: a.send_if_empty
                })
            )
            if (!reducedAlerts.some(a => a.alert_type === "mention")) {
                reducedAlerts.push({
                    "alert_type": "mention",
                    "frequency": "never"
                })
            }
            if (!reducedAlerts.some(a => a.alert_type === "citation")) {
                reducedAlerts.push({
                    "alert_type": "citation",
                    "frequency": "never"
                })
            }
            this.alerts = reducedAlerts
        },

        setUpdatedBlacklist(blacklist) {
            this.updatedBlackList = blacklist
        },

        setBlacklist(blacklist) {
            this.blacklist = blacklist
        }
    })
}

const userStore = createUserStore(null, true, [], false, false, [], [])

export default userStore
