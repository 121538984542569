import React, {useEffect, useState} from "react"
import {Box, LinearProgress} from "@mui/material";
import OvertonLogo from "../assets/overton_logo_on_white.svg";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import {useFetchCitationData, useFetchMentionData} from "../hooks/dataHooks";
import {useFetchUser} from "../hooks/userHooks";

const SetupInterstitial = ({height = "100vh", forceUpdate = false, redirectTo = "/dashboard", fetchNewMentions = true}) => {
    const [view, setView] = useState("loading");
    const navigate = useNavigate();
    const fetchCitationData = useFetchCitationData();
    const fetchMentionData = useFetchMentionData();
    const fetchUser = useFetchUser();
    const controller = new AbortController();

    useEffect(() => {
        const loadDataAsync = async () => {
            setTimeout(() => {
                setView("setting")
            }, 8000)
            setTimeout(() => {
                setView("longer")
            }, 20000)

            await fetchUser(true)
            fetchNewMentions && await fetchMentionData(forceUpdate, controller.signal)
            await fetchCitationData(forceUpdate, controller.signal)
            navigate(redirectTo)
        }
        loadDataAsync()

        return () => {
            controller.abort()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loading = () => {
        return <>
            <Typography component={"h1"} color={"primary"} className={"text-titleLg"}>Loading your policy impact
                from</Typography>
            <Box sx={{height: "1.5rem"}} component={"img"} src={OvertonLogo} alt={"www.overton.io"}></Box>
        </>
    }
    const settingUp = () => {
        return <>
            <Typography color={"primary"} component={"h1"} className={"text-titleLg"}>Setting up your
                dashboard</Typography>
        </>
    }
    const takingLonger = () => {
        return <>
            <Typography color={"primary"} component={"h1"} className={"text-titleLg"}>This is taking a little longer
                than usual, please wait a moment</Typography>
        </>
    }

    const time = () => {
        return <Box>
            <p style={{maxWidth: 992}} className={"text-bodyLg"}>
                This might take a minute or two</p>
        </Box>
    }

    return (
        <Box backgroundColor={"#fafafa"} width={"auto"} display={"flex"} padding={"4rem 1rem"} textAlign={"center"} flexDirection={"column"}
             justifyContent={"center"} gap={2} alignItems={"center"} height={height}>
            {view === "loading" && loading()}
            {view === "setting" ? !redirectTo ? settingUp() : loading() : ''}
            {view === "longer" && takingLonger()}
            <br/>
            <LinearProgress sx={{width: "16rem"}} color={"primary"}/>
            {time()}
        </Box>
    )
}

export default SetupInterstitial
