import {action, makeAutoObservable} from "mobx";
import {groupBy} from "../utils/utils";
import _, {isEqual} from "lodash";

const createCitationsStore = (citations,
                              citationsByPolicyId,
                              secondOrderCitations,
                              secondOrderCitationsByPolicyId,
                              currentData,
                              updatedData,
                              initialised,
                              updateData) => {
    return makeAutoObservable({
        currentData,
        citations,
        citationsByPolicyId,
        initialised,
        secondOrderCitations,
        secondOrderCitationsByPolicyId,
        updatedData,
        updateData,


        setHasUpdatedData(data) {
            this.updatedData = data;
            this.updateData = true
        },

        hasUpdatedCitationData(newData) {
            return !(isEqual(_.size(newData.citations), _.size(this.currentData.citations)) &&
                isEqual(_.size(newData.second_order_citations), _.size(this.currentData.second_order_citations)));
        },

        async setCitations(citations) {
            const array = Object.values(citations).flat()
            const grouped = await groupBy(array, "policy_document_id")

            action(() => this.citations = citations && new Map(Object.entries(citations)))()
            action(() => this.citationsByPolicyId = citations && new Map(Object.entries(grouped)))()
        },

        async setSecondOrderCitations(secondOrderCitations) {
            const array = Object.values(secondOrderCitations).flat()
            const grouped = await groupBy(array, "second_order_policy_document_id")

            action(() => this.secondOrderCitations = secondOrderCitations && new Map(Object.entries(secondOrderCitations)))()
            action(() => this.secondOrderCitationsByPolicyId = secondOrderCitations && new Map(Object.entries(grouped)))()
        },

        getSecondOrderCitations(id) {
            return this.secondOrderCitations.get(id)
        },

        initialise(data, initialised = true) {
            this.setCitations(data.citations)
            this.setSecondOrderCitations(data.second_order_citations)
            this.currentData = data
            this.initialised = initialised
            this.updateData = false;
        }
    })
}

const citationsStore = createCitationsStore(new Map(), new Map(), new Map(), new Map(), {}, {}, false, false)
export default citationsStore
