import {useLocation} from "react-router";
import {useEffect} from "react";

const ScrollToTop = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scroll(0,0);
    }, [pathname])
}

export default ScrollToTop
