import React from "react"

import {TextField} from "@mui/material";
import {handleEnter} from "../../utils/utils";


const EmailField = ({setEmail, emailError, onEnterRef, emailInputRef}) => {

    return <TextField
        className={"StackStretch"}
        required
        error = {emailError}
        id={"emailInput"}
        type={"email"}
        label={"Email address"}
        onChange={event=>setEmail(event.target.value)}
        inputRef={emailInputRef}
        onKeyUp={event=> handleEnter(event, onEnterRef)}
        helperText={emailError ? "Please enter a valid email address" : null}
        />
}

export default EmailField
