import React from "react";
import {Box, Button, Dialog, DialogContent, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

const ConfirmationDialog = ({title, text, open, onClose, submit, children, submitText}) => {
    return <Dialog fullWidth={true} maxWidth={"sm"} open={open}
                   onClose={onClose}>
        <DialogContent sx={{padding: {sm: 3,}, display: "flex", flexDirection: "column"}}>
            <IconButton sx={{position: "relative", left: -16}} className={"AddResearchCloseModal"}
                        onClick={onClose}><Close
                color={"primary"}/></IconButton>
            {title && <h2 className={"text-headlineLg"}>{title}</h2>}
            {text && <p className={"text-bodyLg"}>{text}</p>}
            {children}
            <Box className={"SetUpButtonContainer"}>
                <Button onClick={submit} variant={"contained"}>{submitText ?? "Submit"}</Button>
                <Button onClick={onClose} variant={"outlined"}>Cancel</Button>
            </Box>
        </DialogContent>
    </Dialog>
}

export default ConfirmationDialog
