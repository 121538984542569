import {Helmet} from "react-helmet";
import React, {useEffect, useRef, useState} from "react";
import ResearchListItem from "../../components/ResearchListItem/ResearchListItem";
import dataStore from "../../stores/dataStore";
import {observer} from "mobx-react-lite";
import AppToolbar from "../../components/AppToolbar/AppToolbar";
import CustomPagination from "../../components/CustomPagination/CustomPagination";
import {Box, Button, Dialog, DialogContent, IconButton, List, Stack, useMediaQuery} from "@mui/material";
import {autorun} from "mobx";
import {getYearFromString, sortData} from "../../utils/utils";
import {Add, Close} from "@mui/icons-material";
import userStore from "../../stores/userStore";
import SkeletonItem from "../../components/Skeleton";
import {Outlet, useNavigate} from "react-router";
import "./Research.scss";
import theme from "../../theme/theme";
import InfoToolTip from "../../components/InfoToolTip/InfoToolTip";
import {researchExportHeaders} from "../../components/CSVExports";
import {dispatchSuccess} from "../../services/Notifications";
import {useFetchCitationData} from "../../hooks/dataHooks";
import citationsStore from "../../stores/citationsStore";
import SetupInterstitial from "../../components/SetupInterstitial";

const Research = observer(() => {
    const ref = useRef()
    const [page, setPage] = useState(0);
    const [rowsPerPage] = useState(20);
    const [showAddResearchModal, setShowAddResearchModal] = useState(false)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const fetchData = useFetchCitationData();
    const [loadingData, setLoadingData] = useState(false)

    const [researchDocuments, setResearchDocuments] = useState([]);
    const showPagination = researchDocuments.length > 0;

    const navigate = useNavigate();

    useEffect(() => {
        dataStore.initialiseFilters()
        dataStore.setSortValue("date", true)
        autorun(() => {
            setFilteredData(dataStore.filters)
        })
    }, [])

    const getCSVData = () => {
        return researchDocuments.map(document => {
            const citing_document_urls = [];
            const citing_document_titles = [];
            let policy_citation_count = 0;
            const citations = citationsStore.citations.get(document.doi)
            citations && citations.forEach(c => {
                const document = dataStore.getDocument(c.policy_document_id)
                citing_document_urls.push(document.document_url);
                citing_document_titles.push(document.title);
                policy_citation_count += 1;
            })
            return {...document, citing_document_urls, citing_document_titles, policy_citation_count}
        })
    }

    const getInfoTipContent = () => {
        if (userStore.user?.orcid) {
            return "We fetch a list of DOIs from your ORCID record, and match them to DOIs cited in our policy database. If you think there's research missing, please update your ORCID record with the relevant DOIs."
        }
        return 'We use the list of DOIs you gave us when you set up your account. If you\'d like to add more research, you can add additional DOIs by clicking the "Add more research" button'
    }

    const handleChangePage = (event, newPage) => {
        window.scrollTo(0, 64);
        setPage(newPage);
    };

    const setFilteredData = (filters) => {
        let filteredResearchDocs = [...dataStore.research.values()]
        filters.forEach(filter => {
            if (filter.value) {
                filteredResearchDocs = filteredResearchDocs.filter(researchDoc => {
                    const citations = citationsStore.citations.get(researchDoc.doi)

                    if (filter.key === "research_published_on") {
                        const year = getYearFromString(researchDoc.published_on)
                        return researchDoc.published_on && year >= filter.value[0] && year <= filter.value[1]
                    } else if (filter.key === "policy_published_on") {
                        return citations && Object.values(citations).some(c => {
                            const year = getYearFromString(dataStore.policyDocuments.get(c.policy_document_id).published_on)
                            return year >= filter.value[0] && year <= filter.value[1]
                        })
                    }
                })
            }
        })
        sortData(filteredResearchDocs, dataStore.sortValue.descending, setResearchDocuments, dataStore.sortValue.type)
        setPage(0);
    }

    const addMoreResearch = () => {
        setShowAddResearchModal(true)
        navigate("/research/doi-lookup")
    }

    const researchList = () => {
        if (researchDocuments.length === 0) {
            return <Box>
                <p className={"text-bodyLg"}>No results found</p>
                <br/>
                {!userStore.user?.manual_dois && <>
                    <p>
                        We&apos;ve not found any records in your ORCID with DOIs, try adding some DOIs to your ORCID
                        record
                        find your policy impact.
                    </p>
                    <br/>
                    <InfoToolTip label={"What's a DOI"} ref={ref}>
                        <Box className={"InfoPopOver"}>
                            <Box className={"InfoPopOverCopy"}>
                                <Box className={"InfoPopoverTitle"}>
                                    What&apos;s a DOI?
                                </Box>
                                <Box component={"p"} className={"text-bodyMd"}>
                                    A DOI (Digital Object Identifier) is a unique and never-changing string assigned to
                                    online (journal) articles, books, and other works. DOIs make it easier to retrieve
                                    works, which is why citation styles, like APA and MLA Style, recommend including
                                    them in citations.
                                </Box>
                                <Box className={"InfoPopOverActions"}>
                                    <Button onClick={() => ref.current.closePopOver()}>Ok, got it</Button>
                                </Box>
                            </Box>
                        </Box>
                    </InfoToolTip>
                </>
                }
            </Box>
        }
        return researchDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(r => {
            return <ResearchListItem key={r.doi} research={r}/>
        })
    }

    if (!dataStore.initialised) {
        return <List sx={{padding: {xs: 2, sm: 3}}}>
            <SkeletonItem/>
            <SkeletonItem/>
            <SkeletonItem/>
            <SkeletonItem/>
        </List>
    }

    const updateResearch = async (update) => {
        setLoadingData(true)
        if (update) {
            await fetchData(true);
            dispatchSuccess("We've added your research")
        }
        setShowAddResearchModal(!showAddResearchModal)
        setLoadingData(false)
    }

    const paginationComponent = () => {
        return (
            <Box sx={{paddingLeft: {xs: 2, sm: 3}}} display={"flex"} alignItems={"center"} flexDirection={"row"}
                 flexWrap={"wrap"} justifyContent={"space-between"}>
                {userStore.user?.manual_dois ?
                    <Button size={isMobile ? "small" : "medium"} onClick={addMoreResearch} startIcon={<Add/>}
                            variant={"outlined"}>{isMobile ? "Add more" : "Add more research"}</Button>
                    :
                    <div/>
                }
                {showPagination &&
                    <CustomPagination showFirstLast={!isMobile} onPageChange={handleChangePage}
                                      rowsPerPage={rowsPerPage} page={page}
                                      count={researchDocuments.length}/>}
            </Box>
        )
    }

    const dialogContent = () => {
        if (loadingData) {
            return <SetupInterstitial fetchNewMentions={false} forceUpdate={true} redirectTo={"/research"}
                                      height={"auto"}/>
        }
        return <>
            <Box sx={{padding: {sm: 0, md: "1rem 1.5rem 0", position: "sticky", top: 0, background: "white"}}}>
                <IconButton className={"AddResearchCloseModal"}
                            onClick={() => setShowAddResearchModal(false)}><Close
                    color={"primary"}/></IconButton>
            </Box>
            <Outlet context={[showAddResearchModal, updateResearch]}/>
        </>
    }

    return (
        <>
            <Helmet>
                <title>Research</title>
            </Helmet>
            <Dialog fullScreen={isMobile} fullWidth={true} maxWidth={"lg"} open={showAddResearchModal}
                    onClose={() => setShowAddResearchModal(false)}>
                <DialogContent sx={{padding: {sm: 2, md: 0}, display: "flex", flexDirection: "column"}}>
                    {dialogContent()}
                </DialogContent>
            </Dialog>
            <Stack minHeight={"calc(100vh - 146px)"}>
                <AppToolbar infoTipContent={getInfoTipContent()}
                            infoTipTitle={"What research is covered?"}
                            getCSVData={getCSVData} exportHeaders={researchExportHeaders}
                            setFilteredData={setFilteredData}
                            setSortedData={setResearchDocuments}
                            showTypeFilter={false}
                            dataSet={[...dataStore.research.values()]}
                            headingComponent={"h1"}
                            title={"My research"}
                            sortByCited/>
                {paginationComponent()}
                <List sx={{padding: {xs: 2, sm: 3}}}>{researchList()}</List>
                {paginationComponent()}
            </Stack>
        </>
    )
})
export default Research
