import {Outlet} from "react-router";
import ResponsiveNavigationRail from "../components/ResponsiveNavigationRail/ResponsiveNavigationRail";
import {Helmet} from "react-helmet";
import {Box, Fab} from "@mui/material";
import "./UserApp.scss"
import React, {useEffect} from "react";
import ScrollToTop from "../utils/ScrollToTop";
import {observer} from "mobx-react-lite";
import UserAppFooter from "../components/AppFooter/UserAppFooter";
import dataStore from "../stores/dataStore";
import {RefreshOutlined} from "@mui/icons-material";
import SurveyCTA from "../components/SurveyCTA";
import {useFetchCitationData, useFetchMentionData} from "../hooks/dataHooks";
import {useFetchUser} from "../hooks/userHooks";
import userStore from "../stores/userStore";
import citationsStore from "../stores/citationsStore";
import mentionsStore from "../stores/mentionsStore";

const UserApp = observer(() => {
    const controller = new AbortController();
    const fetchCitationsData = useFetchCitationData()
    const fetchUser = useFetchUser()
    const fetchMentionsData = useFetchMentionData()

    const fetchCurrentUser = async () => {
        fetchUser(true)
    }

    useEffect(() => {
        const asyncDataFetch = async (forceUpdate) => {
            await fetchMentionsData(forceUpdate, controller.signal)
            await fetchCitationsData(forceUpdate, controller.signal)
        }

        document.addEventListener("visibilitychange", fetchCurrentUser)

        fetchUser(true).then(() => {
            if (userStore.user) {
                asyncDataFetch(false).then(() => asyncDataFetch(true))
            }
        })

        return () => {
            controller.abort()
            document.removeEventListener("visibilitychange", fetchCurrentUser)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const refreshData = () => {
        dataStore.updateData && dataStore.initialise(dataStore.updatedData);
        citationsStore.updateData && citationsStore.initialise(citationsStore.updatedData);
        mentionsStore.updateData && mentionsStore.initialise(mentionsStore.updatedData);
    }

    return (
        <>
            <ScrollToTop/>
            <Helmet>
                <meta name={"theme-color"} content={"#ffffff"} data-react-helmet="true"/>
            </Helmet>
            <Box className={"UserApp"}>
                <SurveyCTA/>
                <ResponsiveNavigationRail/>
                {(dataStore.updateData || mentionsStore.updateData || citationsStore.updateData) &&
                    <Fab sx={{position: "fixed", top: "1rem", left: "calc(50% - 147px)"}} onClick={refreshData}
                         variant={"extended"}>
                        New data available, click to refresh <RefreshOutlined fontSize={"inherit"}/>
                    </Fab>}
                <Outlet/>
                <UserAppFooter/>
            </Box>
        </>
    )
})
export default UserApp
