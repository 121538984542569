import React, {useEffect, useState} from 'react';
import {Box, Button, RadioGroup} from "@mui/material"
import PageIntro from '../../components/PageIntro/PageIntro';
import StackBox from '../../components/StackBox/StackBox';
import {useLocation, useNavigate} from 'react-router';
import ButtonContent from "../../components/ButtonContent";
import {Link} from "react-router-dom";
import OpenAlexAuthor from "../../components/OpenAlexAuthor/OpenAlexAuthor";
import {dispatchError} from "../../services/Notifications";
import {useFetchOpenAlexArticles} from "../../hooks/dataHooks";

const ConfirmOAProfile = () => {
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [selectedAuthorID, setSelectedAuthorID] = useState(null)
    const fetchOpenAlexArticles = useFetchOpenAlexArticles()

    const name = location.state?.name
    const authors = location.state?.authors

    useEffect(() => {
        if (authors === undefined) {
            dispatchError("Please add your name to get started")
            navigate("/account-setup/orcid-lookup")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authors])

    if (authors === undefined) {
        return
    }
    
    const confirmAuthor = async () => {
        const selectedAuthor = location.state.authors.find(a => a.id === selectedAuthorID)
        setLoading(true)
        try {
            const articles = await fetchOpenAlexArticles(selectedAuthorID)
            navigate("/account-setup/confirm-articles", {
                state: {
                    articles,
                    name: selectedAuthor.display_name || "",
                    name_variants: selectedAuthor.display_name_alternative || "",
                    affiliations: selectedAuthor.last_known_institution.display_name ? [selectedAuthor.last_known_institution.display_name] : []
                }
            })
        } catch (err) {
            dispatchError(err.errors)
        }
        setLoading(false)
    }

    const handleChange = (e) => {
        setSelectedAuthorID(e.target.value)
    }

    const introCopy = () => {
        if (authors.length) {
            return <p className={"IntroCopy"}>Please confirm which is correct one. If more than one result looks right,
                choose the one with the highest document count.</p>
        }
        return <p className={"IntroCopy"}>Try searching again, or using your ORCID iD, if you have one. </p>
    }

    return (
        <Box className={"SetupContent"}>
            <Box className={"SetUpFrame SetupIntroContainer"}>
                <PageIntro>
                    <h1 className={"IntroHeading"}>We found {authors.length} authors matching &quot;{name}&quot;</h1>
                    {introCopy()}
                    <br/>
                    <p className={"IntroCopy"}>
                        <span>Alternatively, you can find your research by <Link
                            style={{textDecoration: "underline", textUnderlineOffset: 2}}
                            to={"/account-setup/doi-lookup"} state={{name}}>adding a list of DOIs or PubMed IDs.</Link></span>
                    </p>
                    <br/>
                </PageIntro>
            </Box>
            <Box className={"SetUpFrame"}>
                <StackBox sx={{paddingBottom: 4}}>
                    <RadioGroup value={selectedAuthorID} onChange={(e) => handleChange(e)} sx={{width: "100%"}}>
                        {authors.map(a => <OpenAlexAuthor key={a.id} author={a}/>)}
                    </RadioGroup>
                </StackBox>
            </Box>
            <Box className={"SetUpButtonContainer OverflowFrameButtons"}>
                <Button id={"confirm-articles-continue"} className={"StackStretch"} variant={"contained"}
                        onClick={confirmAuthor}>
                    <ButtonContent isLoading={loading} title={"Continue"}/>
                </Button>
                <Button className={"StackStretch"} variant={"outlined"}
                        onClick={() => navigate(-1)}>Back</Button>
            </Box>
        </Box>
    )
}

export default ConfirmOAProfile
