import React, {forwardRef, useState} from "react";
import {Chip, Menu, MenuItem} from "@mui/material";
import {Close} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DateRangePicker from "./DateRangePicker";
import CustomMenuItem from "./CustomMenuItem";
import {getCurrentYear} from "../utils/utils";

const DateFilterMenu = forwardRef(({filter, label, updateFilters, clearFilter, filterValue, prefix}, ref) => {
    const currentYear = getCurrentYear()
    const previousYear = (new Date().getFullYear() - 1).toString()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [showDatePicker, setShowDatePicker] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const applyDateRange = (range) => {
        if (range) {
            updateFilters(range, filter)
        } else {
            setShowDatePicker(false)
        }
        handleClose()
    }

    const setFilters = (value) => {
        updateFilters(value, filter)
        handleClose()
    }

    const menuItems = () => {
        return <Menu anchorEl={anchorEl}
                     onClose={handleClose}
                     open={open}>
            <CustomMenuItem autoFocus label={`${prefix ? prefix + " t" : "T"}his year (${currentYear})`}
                            onClick={() => setFilters([currentYear, currentYear])}
                            selected={filterValue && filterValue[0] === currentYear && filterValue[1] === currentYear}/>
            <CustomMenuItem label={`${prefix ? prefix + " l" : "L"}ast year (${previousYear})`}
                            onClick={() => setFilters([previousYear, previousYear])}
                            selected={filterValue && filterValue[0] === previousYear && filterValue[1] === previousYear}/>
            <MenuItem onClick={() => setShowDatePicker(true)}>Custom date range</MenuItem>
        </Menu>
    }

    const getLabel = () => {
        if (!filterValue) {
            return label
        }
        if (filterValue[0] === filterValue[1]) {
            return filterValue[0]
        }
        return filterValue.join(" - ")
    }

    return (
        <>
            <Chip key={filterValue}
                  ref={ref}
                  onClick={handleClick}
                  icon={filterValue ? <CheckIcon/> : <div/>}
                  variant={filterValue ? "filled" : "outlined"}
                  color={filterValue ? "secondary" : "primary"}
                  deleteIcon={filterValue ? <Close/> : <KeyboardArrowDownIcon/>}
                  onDelete={filterValue ? clearFilter : handleClick}
                  clickable type={'filter'}
                  label={getLabel()}/>
            {showDatePicker && <DateRangePicker setShowDatePicker={setShowDatePicker}
                                                applyDateRange={applyDateRange}
                                                open={open}
                                                anchorEl={anchorEl}/>
            }
            {!showDatePicker && menuItems()}
        </>
    )
})
DateFilterMenu.displayName = 'DateFilterMenu';

export default DateFilterMenu
